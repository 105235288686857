<template>
	<!-- 支持项 -->
	<div class="supportTerm flex_box_cen">
		<!-- 参数1 -->
		<div class="wrap flex_box_column_cen" v-if='!sid'>
			<div class="title wow fadeInUp">Supported Battery And Charging Voltage</div>
			<div class="box flex_box_column_cen">
				<ul>
					<li class="flex_box_bet">
						<div class="tit">Description</div>
						<div class="tit">Fast Mode / VDC</div>
						<div class="tit">Float Mode/VDC</div>
					</li>
					<li class="flex_box_bet" v-for="(item,index) in termList" :key="index">
						<div class="text">{{item.title}}</div>
						<div class="text" v-if="item.fast!=''&&item.float!=''">{{item.fast}}</div>
						<div class="text" v-if="item.fast!=''&&item.float!=''">{{item.float}}</div>
						<div class="text2" v-if="item.fast==''||item.float==''">{{item.fast}}</div>
					</li>
					<li class="btm">
						<span v-if="tobel_bom&&tobel_bom!=''">{{tobel_bom}}</span>
						<span v-else>For 12Vdc Mode Series("2 for 24Vdc Mode/4 for 48Vdc Mode/8 for 96Vdc Mode)</span>
						
					</li>
				</ul>
			</div>
		</div>
		
		<!-- 参数2 -->
		<div class="wrap flex_box_column_cen" v-if='sid==2'>
			<div class="title wow fadeInUp">Supported Battery And Charging Voltage</div>
			<div class="box flex_box_column_cen">
				<ul>
					<li class="flex_box_bet">
						<div class="tit">Battery Type</div>
						<div class="tit">Boost/Vdc</div>
						<div class="tit">Float/Vdc </div>
					</li>
					<li class="flex_box_bet" v-for="(item,index) in termList" :key="index">
						<div class="text">{{item.title}}</div>
						<div class="text" v-if="item.fast!=''&&item.float!=''">{{item.fast}}</div>
						<div class="text" v-if="item.fast!=''&&item.float!=''">{{item.float}}</div>
						<div class="text2" v-if="item.fast==''||item.float==''">{{item.fast}}</div>
					</li>
					<li class="btm2" v-if="tobel_bom == 1"></li>
					<li class="btm" v-else>
						<span v-if="tobel_bom&&tobel_bom!=''">{{tobel_bom}}</span>
						<span v-else>For 12Vdc Mode Series("2 for 24Vdc Mode/4 for 48Vdc Mode/8 for 96Vdc Mode)</span>
						
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from 'wowjs/dist/wow'
	export default{
		props: {
			termList: {
				type: Array,
				default(){
					return []
				}
			},
			tobel_bom:{
				type: String,
			},
			sid:{
				type: String,
			}
			
		},
		data() {
			return{
				
			}
		},
		mounted() {
			this.$nextTick(()=>{
				var wow = new WOW.WOW({
					boxClass:'wow',
					animateClass:'animated',
					offset:0,
					mobile:true,
					live :false
				})
				wow.init()
			})
		},
	}
</script>

<style lang="scss" scoped>
	.supportTerm{
		width: 100%;
		// background-color: blue;
		.wrap{
			width: 15.37rem;
			margin-top: 0.93rem;
			.title{
				width: 100%;
				font-size: 0.36rem;
				font-family: Arial;
				font-weight: bold;
				color: #5C6079;
			}
			.box{
				width: 14.21rem;
				
				margin-top: 0.58rem;
				ul{
					width: 100%;
					li{
						width: 13.8rem;
						padding-left: 0.4rem;
						height: 0.59rem;
						border-bottom: 0.01rem solid #CCCCCC;
						.tit{
							width: 2.2rem;
							font-size: 0.24rem;
							font-family: Arial;
							font-weight: bold;
							color: #333333;
							line-height: 0.59rem;
						}
						.text{
							width: 2.2rem;
							font-size: 0.16rem;
							font-family: Arial;
							font-weight: 400;
							color: #4D4D4D;
							line-height: 0.59rem;
						}
						.text2{
							width: 8.2rem;
							font-size: 0.16rem;
							font-family: Arial;
							font-weight: 400;
							color: #4D4D4D;;
							line-height: 0.59rem;
						}
					}
					.btm{
						width: 13.8rem;
						height: 0.59rem;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #4D4D4D;
						line-height: 0.59rem;
						text-align: center;
					}
					.btm2{
						border: none;
					}
				}
			}
		}
	}
	
	// 移动端 样式
		@media (max-width:1024px){
			.supportTerm{
				width: 100%;
				// background-color: blue;
				.wrap{
					width: 5.71rem;
					margin-top: 0.93rem;
					.title{
						width: 100%;
						font-size: 0.36rem;
						font-family: Arial;
						font-weight: bold;
						color: #5C6079;
					}
					.box{
						width: 5.71rem;
						
						margin-top: 0.58rem;
						ul{
							width: 5.71rem;
							li{
								width: 5.79rem;
								padding-left: 0rem;
								height: 0.59rem;
								border-bottom: 0.01rem solid #CCCCCC;
								.tit{
									width: 2rem;
									font-size: 0.24rem;
									font-family: Arial;
									font-weight: bold;
									color: #333333;
									line-height: 0.59rem;
								}
								.tit:nth-child(1){
									width: 1.5rem;
								}
								.text{
									width: 2.2rem;
									font-size: 0.16rem;
									font-family: Arial;
									font-weight: 400;
									color: #4D4D4D;;
									line-height: 0.59rem;
								}
							}
							.btm{
								width: 5.71rem;
								height: 0.59rem;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #4D4D4D;
								line-height: 0.59rem;
								text-align: center;
							}
							.btm2{
								border: none;
							}
						}
					}
				}
			}
		}
</style>