<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- <diy-option></diy-option> -->
	<!-- 底部 -->
	<new-footer style='margin-top:1.43rem;'></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product2/product.vue'										//产品介绍组件
	import newFooter from '@/components/footer/footer.vue'											//网页底部
	// import diyOption from '@/components/diyOption'													//DIY系统方案 组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'LCD Remote control panel',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			newFooter,
			// diyOption,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/LCD/product.png'),
					title:'LCD Remote control panel',
					info: `
						Designed for remote operation and monitoring of inverter equipment. 
						Through the LCD remote control screen, users can conveniently control 
						the inverter equipment remotely and realise the adjustment and setting of 
						parameters such as operating mode, output voltage and frequency. At the 
						same time, the screen also has a real-time monitoring function that can 
						display important data such as input voltage, output voltage and load 
						condition of the inverter in real time.
					`
				},
				productInfo:[
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							},{
								info:"Recreation vehicles",
								icon:require('../../public/images/cart.png'),
							},
							{
								info:"Heavy duty truck",
								icon:require('../../public/images/bigCart.png'),
							},
							{
								info: "Yacht",
								icon:require('../../public/images/ship.png'),
							},
							{
								info:"Engineering vehicle",
								icon:require('../../public/images/evCart.png'),
							}
						]
					},
					{
						title:'Compatible with',
						list:[
							{
								info:"HP Series",
								icon:'',
							},{
								info:'APP Series',
								icon:'',
							},{
								info:'APC Series',
								icon:'',
							},{
								info:'APS Series',
								icon:'',
							},{
								info:'TPP Series',
								icon:'',
							},{
								info:'PSW7 Series ',
								icon:'',
							},{
								info:'HPV Series ',
								icon:'',
							},{
								info:'APV Series',
								icon:'',
							}
						]
					}
				
				],
				otherList:[
					{
						img:require('../../public/otherImg/40A-MPPT.png'),
						url:'/40A-MPPT'
					},
				]
			}
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
