<template>
	<div class="header flex_box_cen" :class = "navShow ? 'navOn' : 'navOff'">
		<div class="header_wrap flex_box_bet">
			<div class="logo" @click="go('/')"></div>
			<div class="cn_en flex_box_bet">
				<!-- <div>CN</div>
				<div>EN</div> -->
			</div>
			<div class="header_list flex_box_align_cen">
				<ul class="header_ul flex_box_bet">
					<li v-for="(item,index) in navigationList" :key='index' @click.stop="go(item.url)">
						{{item.title}}
						<div class="hover_box">
							<div class="hover_box_top"></div>
							<div class="hover_box_wrap">
								<div class="left">
									<div class="tit" v-for="(itemson,indexson) in item.listGo" :key="indexson">
										<span @click.stop="go(itemson.url)">{{itemson.title}}</span>
										<img src="../../../public/smallImg/redjt.png">
										<div class="right" v-if="itemson.cont">
											<div class="cont" v-for=" (k,i) in itemson.cont" :key="i" @click.stop="go(k.url)">
												{{k.name}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="header_rig flex_box_align_cen">
				<div class="search"></div>
				<div class="open"></div>
			</div>
		</div>
		
	</div>
</template>

<script>
	
export default {
	name: 'yiyHeader',
	props: {
		msg: String
	},
	data() {
		return{
			navigationList:[
				{
					title:' INVERTER CHARGER',
					url:'/InverterCharger/0',
					listGo:[
						{
							title:'12VDC Inverter Charger',
							url:'/InverterCharger/0',
							cont:[{
								name:'12V-HP Series Low Frequency Pure Sine Wave Inverter/Charger',
								url:'/12V-HP',
							},{
								name:'12V-APC mini Series Low Frequency Pure Sine Wave Inverter/Charger ',
								url:'/12V-APCMini',
							},{
								name:'12V-AP Series Low Frequency Pure Sine Wave Inverter/Charger',
								url:'/12V-AP',
							},{
								name:'12V-OPS Series Low Frequency Pure Sine Wave Inverter/Charger/AVR',
								url:'/12V-OPS',
							}]
						},{
							title:'24VDC Inverter Charger',
							url:'/InverterCharger/1',
							cont:[{
								name:'24V-HP Series Low Frequency Pure Sine Wave Inverter/Charger',
								url:'/24V-HP',
							},{
								name:'24V-APC mini Series Low Frequency Pure Sine Wave Inverter/Charger ',
								url:'/24V-APCMini',
							},{
								name:'24V-AP Series Low Frequency Pure Sine Wave Inverter/Charger',
								url:'/24V-AP',
							},{
								name:'24V-OPS Series Low Frequency Pure Sine Wave Inverter/Charger/AVR',
								url:'/24V-OPS',
							}]
						},{
							title:'48VDC Inverter Charger',
							url:'/InverterCharger/1',
							cont:[{
								name:'48V-HP Series Low Frequency Pure Sine Wave Inverter/Charger',
								url:'/48V-HP',
							},{
								name:'48V-AP Series Low Frequency Pure Sine Wave Inverter/Charger',
								url:'/48V-AP',
							},{
								name:'48V-TPP Series Low Frequency Three-phase Pure Sine Wave Inverter/Charger',
								url:'/48V-TPP',
							}]
						},{
							title:'96VDC Inverter Charger',
							url:'/InverterCharger/3',
							cont:[{
								name:'96V-HP Series Low Frequency Pure Sine Wave Inverter/Charger',
								url:'/96V-HP',
							}]
						},
					]
				},
				{
					title:'SOLAR INVERTER',
					url:'/SolarInverter/0',
					listGo:[
						{
							title:'12VDC Solar Inverter',
							url:'/SolarInverter/0',
							cont:[{
								name:'12V-HPV Series Low Frequency Pure Sine Wave Inverter/Charger+Controller',
								url:'/12V-HPV',
							},{
								name:'12V-SMP Series High Frequency Solar Inverter',
								url:'/12V-SMP',
							}]
						},{
							title:'24VDC Solar Inverter',
							url:'/SolarInverter/1',
							cont:[{
								name:'24V-HPV Series Low Frequency Pure Sine Wave Inverter/Charger+Controller',
								url:'/24V-HPV',
							},{
								name:'24V-SMP Series High Frequency Solar Inverter',
								url:'/24V-SMP',
							}]
						},{
							title:'48VDC Solar Inverter',
							url:'/SolarInverter/2',
							cont:[{
								name:'48V-HPV Series Low Frequency Pure Sine Wave Inverter/Charger+Controller',
								url:'/48V-HPV',
							},{
								name:'48V-SMP Series High Frequency Solar Inverter',
								url:'/48V-SMP',
							},{
								name:'48V-TPPV Series Low Frequency Three-phase Pure Sine Wave Inverter/Charger+Controller',
								url:'/48V-TPPV',
							}]
						}
					]
				},
				{
					title:'HYBIRD INVERTER',
					url:'/HybirdInverter/0',
					listGo:[
						{
							title:'48VDC Hybird Inverter',
							url:'/HybirdInverter/0',
							cont:[{
								name:'48V-UP Series Bi-directional Power Inverter/Charger',
								url:'/48V-UP',
							},{
								name:'48V-UPV Series Hybrid Energy Storage Inverter',
								url:'/48V-UPV',
							}]
						}
					]
				},
				{
					title:'POWER CONVERSION SYSTEM',
					url:'/PowerConversionSystem/0',
					listGo:[
						{
							title:'Power Conversion System',
							url:'/PowerConversionSystem/0',
							cont:[{
								name:'1000V-UP-S',
								url:'/1000V-UP-S',
							},{
								name:'1000V-UPV-S',
								url:'/1000V-UPV-S',
							},{
								name:'1000V-UP-M',
								url:'/1000V-UP-M',
							}]
						}
					]
				},
				{
					title:' OTHERS',
					url:'/Others/0',
					listGo:[
						{
							title:'Charger',
							url:'/Others/0',
							cont:[{
								name:'CSB Series AC Converter / Battery Charger',
								url:'/CSB',
							}]
						},{
							title:'MPPT Charge Controller',
							url:'/Others/1',
							cont:[{
								name:'40A-MPPT Solar Charge & Discharge Controller',
								url:'/40A-MPPT',
							},{
								name:'60A-MPPT Solar Charge & Discharge Controller',
								url:'/60A-MPPT',
							}]
						},{
							title:'Accessory',
							url:'/Others/2',
							cont:[{
								name:'LCD Remote control panel',
								url:'/LCD',
							},{
								name:'LED Remote control panel',
								url:'/LED',
							},{
								name:'APP/WIFI Module',
								url:'/APP-WIFI',
							},{
								name:'SNMP',
								url:'/SNMP',
							},{
								name:'CAN USB',
								url:'/CAN-USB',
							},{
								name:'DC cable',
								url:'',
							}]
						}
					]
				},
				{
					title:'CONTACTS',
					url:'/contact'
				}
			],
			top:'',
			navShow:true,
			url:'',
			
		}
	},
	mounted() {
		window.addEventListener('scroll', () => {
			this.top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
			if(this.top == 0){
				this.navShow = true
			}
		})
		this.url = window.location.href
		console.log(window.location.href)
	},
	watch:{
		top(newValue,oldValue){
			// 等新值大于100的时候再做变化（优化一下）
			if(newValue > 100){
				if(newValue > oldValue){
					this.navShow = false
					// console.log('向下滚动')
				}else{
					this.navShow = true
					// console.log('向上滚动')
				}
			}
		}
	},
	methods:{
		go(url){
			if(this.url.lastIndexOf(url) <= 0 ){
				if(url){
					this.$router.push(url)
					// document.documentElement.scrollTop = 0
				}
			}else if(url == '/'){
				if(this.url.substr(-1) != url){
					this.$router.push(url)
					// document.documentElement.scrollTop = 0
				}
			}
		},
	}
}
</script>

<style lang="scss" scoped>
	.navOn{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		transition: all 0.2s ease-in-out 0.2s;
		transform: translateZ(0);
	}
	.navOff{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		transition: all 0.2s ease-in-out 0.2s;
		transform: translate3d(0,-100%,0);
	}
	.header:hover{
		cursor:pointer;
	}
	.header{
		width: 100%;
		height: 1.37rem;
		background-color: #2A2C42;
		position:sticky;
		// top: 0px;
		z-index: 1111;
		
		.header_wrap{
			width: 88%;
			height: 1.37rem;
			.logo{
				width: 2.56rem;
				height:0.49rem;
				background: url('../../../public/images/logo.png') no-repeat center center;
				background-size:2.56rem 0.49rem;
				margin-top: 0.34rem;
			}
			.cn_en{
				width: 1rem;
				
				font-size: 0.18rem;
				font-family: Arial;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.08rem;
				opacity: 0.48;
				align-items: center;
				div{
					height: 0.13rem;
				}
			}
			
			.header_list{
				width: 70%;
				.header_ul{
					width: 100%;
					li{
						font-size: 0.18rem;
						font-family: Arial;
						font-weight: 400;
						color: #FFFFFF;
						.hover_box{
							width: 100%;
							height: 0rem;
							position: absolute;
							top: 0.79rem;
							left: 0;
							overflow: hidden;
							transition: height 0.2s;
							.hover_box_top{
								width: 100%;
								height: 0.58rem;
							}
							.hover_box_wrap{
								width: 100%;
								height: 4rem;
								background-color: #fff;
								overflow: hidden;
								.left{
									width: 4.84rem;
									margin-left: 5.15rem;
									margin-top: 0.61rem;
									padding-right: 2.64rem;
									.tit{
										display: flex;
										align-items: center;
										height: 0.54rem;
										color: #333333;
										font-size: 0.18rem;
										font-family: Arial;
										font-weight: 400;
										line-height: 0.54rem;
										// margin-bottom: 0.12rem;
										text-align: left;
										position: relative;
										img{
											display: none;
											margin-left: 0.81rem;
										}
										.right{
											overflow: hidden;
											position: absolute;
											width:0 ;
											height: 2.59rem;
											transition: width 0.2s;
											line-height: 0.38rem;
											right: -6rem;
											top: 0;
											font-size: 0.14rem;
											font-family: Arial;
											font-weight: 400;
											color: #666666;
											// padding-left: 0;
											.cont:hover{
												color:#910000;
											}
										}
										
									}
									.tit:nth-child(2){
										.right{
											top: -0.52rem;
										}
									}
									.tit:nth-child(3){
										.right{
											top: -1.04rem;
										}
									}
									.tit:nth-child(4){
										.right{
											top: -1.56rem;
										}
									}
									.tit:hover{
										color:#910000;
										img{
											width: 0.09rem;
											height: 0.13rem;
											display: block;
										}
										// background: url('../../../public/smallImg/redjt.png')no-repeat 2.49rem center;
										.right{
											position: absolute;
											width:5.48rem;
											padding-left: 0.81rem;
											border-left: 0.01rem solid #E5E5E5;
										}
									}
								}
							}
						}
					}
					li:hover{
						color: red;
					}
					li:nth-child(1):hover{
						
						.hover_box{
							height: 4.58rem;
							// display: block;
							
						}
					}
					li:nth-child(2):hover{
						
						.hover_box{
							height: 4.58rem;
							// display: block;
							
						}
					}
					li:nth-child(3):hover{
						
						.hover_box{
							height: 4.58rem;
							// display: block;
							
						}
					}
					li:nth-child(4):hover{
						
						.hover_box{
							height: 4.58rem;
							// display: block;
							
						}
					}
					li:nth-child(5):hover{
						
						.hover_box{
							height: 4.58rem;
							// display: block;
							
						}
					}
					
				}
			}
			.header_rig{
				width: 0.26rem;
				height: 100%;
				.search{
					width: 0.26rem;
					height: 0.26rem;
					background: url('../../../public/images/search.png');
					background-size:0.26rem 0.26rem;
				}
			}
			
		}
	}
	
	@media (max-width:1024px){
	html{
		.header{
			width: 100%;
			height: 1.37rem;
			background-color: #2A2C42;
			position:sticky;
			// top: 0px;
			z-index: 1111;
			
			.header_wrap{
				width: 80.5%;
				height: 1.37rem;
				.logo{
					width: 2.56rem;
					height:0.49rem;
					background: url('../../../public/images/logo.png') no-repeat center center;
					background-size:2.56rem 0.49rem;
					margin-top: 0.34rem;
				}
				.cn_en{
					width: 1rem;
					display: none;
					font-size: 0.18rem;
					font-family: Arial;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 0.08rem;
					opacity: 0.48;
					align-items: center;
					div{
						height: 0.13rem;
					}
				}
				
				.header_list{
					width: 67%;
					display: none;
					.header_ul{
						width: 100%;
						li{
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #FFFFFF;
						}
					}
				}
				.header_rig{
					width: 1rem;
					height: 100%;
					display: flex;
					justify-content: space-between;
					.search{
						width: 0.26rem;
						height: 0.26rem;
						background: url('../../../public/images/search.png') no-repeat center center;
						background-size:0.26rem 0.26rem;
					}
					.open{
						width: 0.38rem;
						height: 0.26rem;
						background: url('../../../public/smallImg/open.png') no-repeat center center;
						background-size:0.38rem 0.26rem;
					}
				}
				
			}
		}
	}
}
</style>