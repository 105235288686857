<template>
	<div class="productAppearance flex_box_column_cen">
		<div class="appearance_box flex_box_column_cen">
			<div class="appearance_title flex_box_align_cen wow fadeInUp">
				<div>Product Appearance</div>
				<!-- <div>*</div> -->
				<div> 
					<span>*</span>
					<span>Remote Control Seletable(RJ11 port/RJ45 port)</span>
				</div>
			</div>
			<div class="pic_box">
				<ul class="flex_box_wrap">
					<li class="flex_box_column_align_cen " v-for="(item,index) in appearanceList" :key='index'>
						<div class="pic wow fadeInRight ">
							<img :src="item.pic" >
						</div>
						<div class="text wow fadeInUp">{{item.text}}</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from 'wowjs/dist/wow'
	export default{
		props: {
			appearanceList: {
				type: Array,
				default(){
					return []
				}
			}
			
		},
		data() {
			return{
				
			}
		},
		mounted() {
			this.$nextTick(()=>{
				var wow = new WOW.WOW({
					boxClass:'wow',
					animateClass:'animated',
					offset:0,
					mobile:true,
					live :false
				})
				wow.init()
			})
		},
	}
</script>

<style lang="scss" scoped>
	.productAppearance{
		width: 100%;
		.appearance_box{
			width: 15.36rem;
			
			.appearance_title{
				width: 100%;
				font-size: 0.36rem;
				font-family: Arial;
				font-weight: bold;
				color: #5C6079;
				height: 0.33rem;
				div:nth-child(2){
					display: flex;
					align-items: center;
					margin-top: 0.09rem;
					margin-left: 0.74rem;
					
					span{
						height: 0.24rem;
						font-size: 0.36rem;
						font-family: Arial;
						font-weight: 400;
						color: #E40000;
						
					}
					span:nth-child(2){
						height: 0.24rem;
						font-size: 0.18rem;
						font-family: Arial;
						font-weight: 400;
						color: #333333;
						line-height: 0.24rem;
					}
					
				}
			}
			.pic_box{
				width: 13.96rem;
				margin-top: 0.2rem;
				ul{
					width: 100%;
					
					li{
						width: 6.88rem;
						margin-top: 0.79rem;
						
						.pic{
							width: 6.88rem;
							// height: 4.26rem;
							margin-bottom: 0.58rem;
							img{
								max-width: 6.88rem;
								// max-height: 4.26rem;
							}
						}
						
						.text{
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #333333;
						}
					}
				}
			}
		}
	}
	
	// 移动端 样式
		@media (max-width:1024px){
			.productAppearance{
				width: 100%;
				.appearance_box{
					width: 5.71rem;
					
					.appearance_title{
						width: 5.71rem;
						font-size: 0.36rem;
						font-family: Arial;
						font-weight: bold;
						color: #5C6079;
						height: 0.33rem;
						flex-direction: column;
						align-items: flex-start;
					
						div:nth-child(2){
							display: flex;
							align-items: center;
							margin-top: 0.43rem;
							margin-left: 0rem;
							
							span{
								height: 0.24rem;
								font-size: 0.36rem;
								font-family: Arial;
								font-weight: 400;
								color: #E40000;
								
							}
							span:nth-child(2){
								height: 0.24rem;
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: 400;
								color: #333333;
								line-height: 0.18rem;
							}
							
						}
					}
					.pic_box{
						width: 5.71rem;
						margin-top: 0.57rem;
						flex-wrap: wrap;
						ul{
							width: 100%;
							
							li{
								width: 5.71rem;
								margin-top: 0.79rem;
								
								.pic{
									width: 5.71rem;
									// height: 4.26rem;
									margin-bottom: 0.58rem;
									img{
										max-width: 5.71rem;
										// max-height: 4.26rem;
									}
								}
								
								.text{
									font-size: 0.18rem;
									font-family: Arial;
									font-weight: 400;
									color: #333333;
								}
							}
						}
					}
				}
			}
		}
</style>