<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features :pplicableIofo='pplicableIofo' :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<product-appearance :appearanceList='appearanceList'></product-appearance>
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<support-term :termList='termList' :tobel_bom='tobel_bom'></support-term>
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'12V-HP',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/images/12V-APCMini.png'),
					title:'24V-APC mini Series Low Frequency Pure Sine  Wave Inverter/Charger ',
					info:'Pure sine wave output. The conventional conversion time is 8ms. It can hold 300% overload within 20s, providing necessary current for starting electrical appliances with high requirements. APC mini series can realize the function of starting/stopping the generator, monitoring the equipment operation status and battery temperature etc. by mobile through additional communication modules.',
				},
				productInfo:[
					{
						title:'Operating voltage',
						list:[
							{
								info:" single phase 100-110-120V/ 220-230-240V",
								icon:'',
							}
						]
					},
					{
						title:'Operating frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							},{
								info:"Recreation vehicles",
								icon:require('../../public/images/cart.png'),
							},{
								info: "Yacht",
								icon:require('../../public/images/ship.png'),
							}
						]
					},
					{
						title:'Range of power',
						list:[
							
							{
								info:"600KW",
								icon:'',
							},
							{
								info:"1000KW",
								icon:'',
							},
							{
								info:"1500KW",
								icon:'',
							},
							
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/shield.png'),
						text:'Multiple protection for safer use',
					},
					{
						icon:require('../../public/images/line.png'),
						text:'Real pure sine wave output to protect sensitive loads',
					},
					{
						icon:require('../../public/images/engine.png'),
						text:'Generator automatic start/stop function',
					},
					{
						icon:require('../../public/images/temperature.png'),
						text:'Battery temperature monitoring function',
					},
					{
						icon:require('../../public/images/charge.png'),
						text:'Adaptable to the charging voltage of different types of batteries',
					}
					
				],
				pplicableIofo:[
					{
						title:'',
						list:[
							{
								pic:require('../../public/smallImg/led.png'),
								text:'LED'
							},
							{
								pic:require('../../public/smallImg/fridge.png'),
								text:'Fridge'
							},
							{
								pic:require('../../public/smallImg/tv.png'),
								text:'TV'
							},
							{
								pic:require('../../public/smallImg/computer.png'),
								text:'computer'
							},
							{
								pic:require('../../public/smallImg/loudspeaker.png'),
								text:'Loudspeaker'
							}
						]
					},
				],
				appearanceList:[
					{
						pic:require('../../public/images/12V-APCMini1.png'),
						text:'12V-APC mini 600W/1000W/1500W'
					},
					{
						pic:require('../../public/images/12V-APCMini2.png'),
						text:'12V-APC mini 600W/1000W/1500W'
					}
				],
				priceInfo:[						//技术参数信息
					{
						name:'Inverter Output',
						list:[
							{
								parameterName:'Model',
								parameterList:['APC mini 600W','APC mini 1000W','APC mini 1500W']
							},
							{
								parameterName:'AC Output Operating Voltage Options',
								parameterList:['100-110-120Vac / 220-230-240Vac']
							},
							{
								parameterName:'Continuous Output Power',
								parameterList:['600W','1000W','1500W']
							},
							{
								parameterName:'Surge Rating (20s)',
								parameterList:['1800W','3000W','4500W']
							},
							{
								parameterName:'Output Frequency',
								parameterList:['50 / 60Hz ± 0.3Hz']
							},
							{
								parameterName:'Nominal Efficiency',
								parameterList:['80% (Peak)']
							},
							{
								parameterName:'Line Mode Efficiency',
								parameterList:['>95%']
							},
							{
								parameterName:'Power Factor',
								parameterList:['0.9-1.0']
							},
							{
								parameterName:'Output Voltage Regulation',
								parameterList:['±10% RMS']
							},
							{
								parameterName:'Typical transfer Time',
								parameterList:['10ms ( Max )']
							},
							{
								parameterName:'THD',
								parameterList:['Typically < 3% ( Rated battery level,rated full linear load )']
							}
							
						]
					},
					{
						name:'DC Input',
						list:[
							{
								parameterName:'Nominal Input Voltage',
								parameterList:['24.0Vdc']
							},
							{
								parameterName:'Minimum Start Voltage',
								parameterList:['20.0Vdc']
							},
							{
								parameterName:'Low Battery Voltage Recover',
								parameterList:['26.0Vdc']
							},
							{
								parameterName:'Idle Consumption-Search Mode',
								parameterList:['< 25W when Power Saver On']
							},
						]
					},
					{
						name:'Charger',
						list:[
							{
								parameterName:'Output Voltage',
								parameterList:['Depends on battery type']
							},
							{
								parameterName:'Max Charge Current',
								parameterList:['15A']
							}
						]
					},
					{
						name:'Bypass & Protection',
						list:[
							{
								parameterName:'Max Input AC Voltage',
								parameterList:['150Vac or  270Vac']
							},
							{
								parameterName:'Nominal Input Frequency',
								parameterList:['50Hz or 60Hz ( Auto detect )']
							},
							{
								parameterName:'Over load protection (SMPS Ioad)',
								parameterList:['Circuit Breaker']
							},
							{
								parameterName:'Output Short Circuit Protection',
								parameterList:['Circuit Breaker']
							},
							{
								parameterName:'Bypass Breaker Rating',
								parameterList:['7A','10A','15A']
							},
							{
								parameterName:'Bypass Without Battery Connected',
								parameterList:['Yes']
							},
							{
								parameterName:'Max Bypass Current',
								parameterList:['7Amp','10Amp','15Amp']
							}
						]
					},
					{
						name:'Mechanical Specifications',
						list:[
							{
								parameterName:'Mounting',
								parameterList:['Wall Mount']
							},
							{
								parameterName:"Dimensions (mm / inch)",
								parameterList:['416*177*141 / 16.4*7*5.6']
							},
							{
								parameterName:'Net Weight (kg)',
								parameterList:['7.5','11','12.1']
							},
							{
								parameterName:'Display',
								parameterList:['Status LEDs / Status LEDs+LCD']
							},
							
						]
					}
				],
				termList:[						//支持项数据
					{
						title:'Gel USA',
						fast:'14.0',
						float:'13.7'
					},
					{
						title:'AGM 1',
						fast:'14.1',
						float:'13.4'
					},
					{
						title:'Lithium',
						fast:'13.8',
						float:'13.6'
					},
					{
						title:'Sealed Lead Acid',
						fast:'14.4',
						float:'13.6'
					},
					{
						title:'Gel EURO',
						fast:'14.4',
						float:'13.8'
					},
					{
						title:'Open Lead Acid',
						fast:'14.0',
						float:'13.8'
					},
					{
						title:'LifePO4',
						fast:'15.5 (4 Hours then Off)',
						float:''
					},
					{
						title:'De-sulphation',
						fast:'13.6',
						float:'13.5'
					}
				],
				tobel_bom:'For 12Vdc Mode Series("2 for 24Vdc Mode/4 for 48Vdc Mode/8 for 96Vdc Mode)',
				otherList:[
					{
						img:require('../../public/otherImg/24V-HP.png'),
						url:'/24V-HP'
					},
					{
						img:require('../../public/otherImg/24V-AP.png'),
						url:'/24V-AP'
					},
					{
						img:require('../../public/otherImg/24V-OPS.png'),
						url:'/24V-OPS'
					}
				]
			}
			
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
