<template>
	<div class="product flex_box_column_cen">
		<div class="product_wrap">
			<div class="product_wrap_top flex_box_around">
				<div class="product_pic wow fadeInLeft">
					<img :src="productIntroduce.pic">
				</div>
				<div class="product_name">
					<div class="title  wow fadeInUp">
						{{productIntroduce.title}}
					</div>
					<div class="info wow fadeInUp">
						{{productIntroduce.info}}
					</div>
					<div class="btn wow fadeInLeft">
						READ MORE
					</div>
				</div>
			</div>
			<div class="product_wrap_cen flex_box_column_cen">
				<!-- 2条数据 -->
				<ul class="flex_box_bet " v-if="productInfo.length>=2">
					<li class="li1 flex_box_column_cen wow fadeInRight " v-for="(item,index) in productInfo" :key="index">
						<div class="top"></div>
						<div class="title wow fadeInUp">{{item.title}}</div>
						<div class="small_box" v-if="item.list.length<=7">
							<div class="num flex_box_column_cen wow fadeInUp" v-for="(itemson,indexson) in item.list" :key='indexson'>
								<p class="flex_box_align_cen wow fadeInUp" v-if="itemson.icon != ''">
									<img :src="itemson.icon" alt="" >
									{{itemson.info}}
								</p> 
								<p class="flex_box_cen_align_cen wow fadeInUp" v-else>
									{{itemson.info}}
								</p> 
							</div>
						</div>
						<div class="small_box2" v-else>
							<div>
								<div class="num1 wow fadeInUp" v-for="(itemson,indexson) in item.list.slice(0,4)" :key='indexson'>
									<p class="flex_box_column_align_cen wow fadeInUp">
										<img :src="itemson.icon" alt="" v-if="itemson.icon != ''">
										{{itemson.info}}
									</p> 
								</div>
							</div>
							<div>
								<div class="num1 wow fadeInUp" v-for="(itemson,indexson) in item.list.slice(4)" :key='indexson'>
									<p class="flex_box_column_align_cen wow fadeInUp">
										<img :src="itemson.icon" alt="" v-if="itemson.icon != ''">
										{{itemson.info}}
									</p> 
								</div>
							</div>
						</div>
					</li>
				</ul>
				
				<!-- 1条数据 -->
				<ul class="flex_box_bet ul2" v-else>
					<li class="li1 flex_box_column_cen wow fadeInRight " v-for="(item,index) in productInfo" :key="index">
						<div class="top"></div>
						<div class="title wow fadeInUp">{{item.title}}</div>
						<div class="small_box" v-if="item.list.length<=4">
							<div class="num flex_box_column_cen wow fadeInUp" v-for="(itemson,indexson) in item.list" :key='indexson'>
								<p class="flex_box_align_cen wow fadeInUp" v-if="itemson.icon != ''">
									<img :src="itemson.icon" alt="" >
									{{itemson.info}}
								</p> 
								<p class="flex_box_cen_align_cen wow fadeInUp" v-else>
									{{itemson.info}}
								</p> 
							</div>
						</div>
						<div class="small_box2" v-else>
							<div>
								<div class="num1 wow fadeInUp" v-for="(itemson,indexson) in item.list.slice(0,4)" :key='indexson'>
									<p class="flex_box_column_align_cen wow fadeInUp">
										<img :src="itemson.icon" alt="" v-if="itemson.icon != ''">
										{{itemson.info}}
									</p> 
								</div>
							</div>
							<div>
								<div class="num1 wow fadeInUp" v-for="(itemson,indexson) in item.list.slice(4)" :key='indexson'>
									<p class="flex_box_column_align_cen wow fadeInUp">
										<img :src="itemson.icon" alt="" v-if="itemson.icon != ''">
										{{itemson.info}}
									</p> 
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props: {
			productIntroduce: {
				type: Object,
				default(){}
			},
			productInfo:{
				type: Array,
				default(){
					return []
				}
			}
		},
		data() {
			return{
				
			}
		},
		created() {
			// console.log(this.productInfo)
		},
		mounted() {
			
			this.$nextTick(()=>{
				var wow = new WOW.WOW({
					boxClass:'wow',
					animateClass:'animated',
					offset:0,
					mobile:true,
					live :false
				})
				wow.init()
			})
		},
		// watch: {
		// 	productIntroduce:{
		// 		immediate:true,
		// 		handler(newValue,oldValue){
		// 			console.log(newValue,oldValue)
		// 		}
		// 	}
		// },
		mounted() {
			// console.log(this.productInfo)
		},
	}
</script>

<style lang="scss" scoped>
	.product{
		width: 100%;
		.product_wrap{
			width: 15.36rem;
			
			.product_wrap_top{
				height: 5.53rem;
				margin-top: 1.36rem;
				.product_pic{
					// width: 4.33rem;
					max-width: 7rem;
					max-height:7rem;
					img{
						max-width: 7rem;
						max-height:7rem;
					}
				}
				.product_name{
					width: 56%;
					.title{
						font-size: 0.36rem;
						font-family: Arial;
						font-weight: bold;
						color: #333333;
						margin-top: 0.68rem;
						margin-bottom: 0.47rem;
					}
					.info{
						width: 80%;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #333333;
						line-height: 0.3rem;
					}
					.btn{
						width: 1.73rem;
						height: 0.54rem;
						background: #5C6079;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 0.54rem;
						text-align: center;
						margin-top: 0.77rem;
					}
				}
			}
			.product_wrap_cen{
				height: 4.6rem;
				
				margin-top: 1.3rem;
				ul{
					width: 12.53rem;
					li{
						// width: 2.9rem;
						height: 4.6rem;
						background: #FFFFFF;
						border: 0.01rem solid #FFFFFF;
						box-shadow: 0rem 0.01rem 0.1rem 0rem rgba(0,0,0,0.08);
						.top{
							width: 100%;
							height: 0.06rem;
							background-color:#5C6079 ;
						}
						.title{
							width: 93%;
							text-align: center;
							padding-top: 0.45rem;
							padding-bottom: 0.25rem;
							
							font-size: 0.24rem;
							font-family: Arial;
							font-weight: bold;
							color: #5C6079;
						}
						
						.small_box{
							width: 93%;
							.num{
								width: 100%;
								padding-top: 0.1rem;
								padding-bottom: 0.1rem;
								border-top: 0.01rem solid #E0E0E0;
								
								p{
									width: 38%;
									text-align: center;
									font-size: 0.18rem;
									color: #333333;
									line-height: 0.3rem;
									img{
										width: 0.33rem;
										height: 0.24rem;
										margin-right: 0.19rem;
									}
								}
							}
						}
						.small_box2{
							width: 85%;
							display: flex;
							justify-content: space-between;
							.num1{
								width: 2.5rem;
								border-top: 0.01rem solid #E0E0E0;
								padding-top: 0.1rem;
								padding-bottom: 0.1rem;
								// padding-left: 0.35rem;
								// padding-right: 0.35rem;
								p{
									// width: 80%;
									text-align: center;
									font-size: 0.18rem;
									color: #333333;
									line-height: 0.3rem;
									
								}
							}
						}
						
					}
					.li1{
						width: 6.13rem;
					}
				}
				.ul2{
					width: 12.53rem;
					.li1{
						width: 12.53rem;
						.small_box2{
							width: 12.53rem;
							justify-content: space-around;
							.num1{
								width: 5.48rem;
								border-top: 0.01rem solid #E0E0E0;
							}
						}
						
					}
				}
			}
		}
	}
	
	// 移动端 样式
		@media (max-width:1024px){
			.product{
				width: 100%;
				.product_wrap{
					width: 5.85rem;
					
					.product_wrap_top{
						width: 5.85rem;
						height: 100%;
						margin-top: 0.13rem;
						flex-direction: column;
						.product_pic{
							width: 5.85rem;
							// height: 5.5rem;
							max-height:5.5rem;
							
							img{
								width: 5.85rem;
								// height: 5.5rem;
								max-height:5.5rem;
							}
						}
						.product_name{
							width: 5.8rem;
							.title{
								font-size: 0.31rem;
								line-height: 0.41rem;
								font-family: Arial;
								font-weight: bold;
								color: #333333;
								margin-top: 0.38rem;
								margin-bottom: 0.38rem;
							}
							.info{
								width: 5.85rem;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #333333;
								line-height: 0.3rem;
							}
							.btn{
								width: 1.73rem;
								height: 0.54rem;
								background: #5C6079;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 0.54rem;
								text-align: center;
								margin-top: 0.5rem;
							}
						}
					}
					.product_wrap_cen{
						height: 100%;
						margin-top: 0.24rem;
						ul{
							width: 100%;
							flex-wrap: wrap;
							li{
								width: 2.84rem;
								height: 4.6rem;
								background: #FFFFFF;
								border: 0.01rem solid #FFFFFF;
								box-shadow: 0rem 0.01rem 0.1rem 0rem rgba(0,0,0,0.08);
								margin-top: 0.31rem;
								.top{
									width: 100%;
									height: 0.06rem;
									background-color:#5C6079 ;
								}
								.title{
									width: 2.6rem;
									text-align: center;
									padding-top: 0.4rem;
									padding-bottom: 0.25rem;
									
									font-size: 0.24rem;
									font-family: Arial;
									font-weight: bold;
									color: #5C6079;
								}
								
								
								.small_box{
									width: 2.6rem;
									.num{
										width: 100%;
										padding-top: 0.1rem;
										padding-bottom: 0.1rem;
										border-top: 0.01rem solid #E0E0E0;
										
										p{
											width: 80%;
											text-align: center;
											font-size: 0.18rem;
											color: #333333;
											line-height: 0.3rem;
										}
									}
								}
								.small_box2{
									width: 85%;
									display: flex;
									justify-content: space-between;
									.num1{
										width: 1.15rem;
										border-top: 0.01rem solid #E0E0E0;
										padding-top: 0.1rem;
										padding-bottom: 0.1rem;
										// padding-left: 0.35rem;
										// padding-right: 0.35rem;
										p{
											// width: 80%;
											text-align: center;
											font-size: 0.18rem;
											color: #333333;
											line-height: 0.3rem;
										}
									}
								}
								
							}
							.li1{
								width: 2.84rem;
							}
						}
					}
					.ul2{
						width: 100% !important;
						.li1{
							width: 100% !important;
							.small_box2{
								width: 100% !important;
								justify-content: space-around;
								.num1{
									width: 2.48rem !important;
									border-top: 0.01rem solid #E0E0E0;
								}
							}
							
						}
					}
				}
			}
		}
</style>