<template>
	<div class="faq flex_box_bet">
		<div class="left">
			<div class="box" v-for="(item,index) in list" :key='index' @click="item.start = !item.start">
				<div class="title">{{item.title}}</div>
				<div class="text" v-show="item.start">
					{{item.cont}}
				</div>
				<img src="../../../public/smallImg/below.png" v-show="!item.start">
				<img src="../../../public/smallImg/upper.png" v-show="item.start">
			</div>
		</div>
		<div class="right">
			<div class="title">
				If you don't find relevant questions, you can leave a message and we will answer you as soon as possible
			</div>
			<div class="form">
				<input type="text" placeholder="name">
			</div>
			<div class="form">
				<input type="text" placeholder="e-mail">
			</div>
			<div class="form">
				<input type="text" placeholder="question">
			</div>
			
			<div class="btn">Submit</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return{
				list:[
					{
						id:1,
						title:'What Size Inverter Can I Use On My Lithium Battery?',
						cont:`
							This is a question we get asked all the time. Usually,
							it depends on the loads, the capacity of the inverter should be not less than the 
							appliances used on same time. Let’s say your largest load is a microwave. A typical 
							microwave will draw between 900-1200w. With this load you would install a 
							minimum of 1500w inverter. This size inverter will allow you to run microwave 
							and have a little leftover for running small items like a phone charger, fan, etc.
							`,
						start:false
					},
					{
						id:2,
						title:'What Size Inverter Can I Use On My Lithium Battery?',
						cont:`
							This is a question we get asked all the time. Usually,
							it depends on the loads, the capacity of the inverter should be not less than the 
							appliances used on same time. Let’s say your largest load is a microwave. A typical 
							microwave will draw between 900-1200w. With this load you would install a 
							minimum of 1500w inverter. This size inverter will allow you to run microwave 
							and have a little leftover for running small items like a phone charger, fan, etc.
							`,
						start:false
					},
					{
						id:3,
						title:'What Size Inverter Can I Use On My Lithium Battery?',
						cont:`
							This is a question we get asked all the time. Usually,
							it depends on the loads, the capacity of the inverter should be not less than the 
							appliances used on same time. Let’s say your largest load is a microwave. A typical 
							microwave will draw between 900-1200w. With this load you would install a 
							minimum of 1500w inverter. This size inverter will allow you to run microwave 
							and have a little leftover for running small items like a phone charger, fan, etc.
							`,
						start:false
					},
					{
						id:4,
						title:'What Size Inverter Can I Use On My Lithium Battery?',
						cont:`
							This is a question we get asked all the time. Usually,
							it depends on the loads, the capacity of the inverter should be not less than the 
							appliances used on same time. Let’s say your largest load is a microwave. A typical 
							microwave will draw between 900-1200w. With this load you would install a 
							minimum of 1500w inverter. This size inverter will allow you to run microwave 
							and have a little leftover for running small items like a phone charger, fan, etc.
							`,
						start:false
					},
					{
						id:5,
						title:'What Size Inverter Can I Use On My Lithium Battery?',
						cont:`
							This is a question we get asked all the time. Usually,
							it depends on the loads, the capacity of the inverter should be not less than the 
							appliances used on same time. Let’s say your largest load is a microwave. A typical 
							microwave will draw between 900-1200w. With this load you would install a 
							minimum of 1500w inverter. This size inverter will allow you to run microwave 
							and have a little leftover for running small items like a phone charger, fan, etc.
							`,
						start:false
					},
					{
						id:6,
						title:'What Size Inverter Can I Use On My Lithium Battery?',
						cont:`
							This is a question we get asked all the time. Usually,
							it depends on the loads, the capacity of the inverter should be not less than the 
							appliances used on same time. Let’s say your largest load is a microwave. A typical 
							microwave will draw between 900-1200w. With this load you would install a 
							minimum of 1500w inverter. This size inverter will allow you to run microwave 
							and have a little leftover for running small items like a phone charger, fan, etc.
							`,
						start:false
					},
					{
						id:7,
						title:'What Size Inverter Can I Use On My Lithium Battery?',
						cont:`
							This is a question we get asked all the time. Usually,
							it depends on the loads, the capacity of the inverter should be not less than the 
							appliances used on same time. Let’s say your largest load is a microwave. A typical 
							microwave will draw between 900-1200w. With this load you would install a 
							minimum of 1500w inverter. This size inverter will allow you to run microwave 
							and have a little leftover for running small items like a phone charger, fan, etc.
							`,
						start:false
					}
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
	.faq{
		width:100%;
		margin-bottom: 0.98rem;
		.left{
			width: 8.68rem;
			.box{
				padding-top: 0.21rem;
				padding-bottom: 0.21rem;
				border: 0.01rem solid #CCCCCC;
				border-radius: 0.1rem;
				position: relative;
				margin-bottom: 0.2rem;
				.title{
					margin-left: 0.22rem;
					font-size: 0.18rem;
					font-family: Arial;
					font-weight: 400;
					color: #666666;
				}
				.text{
					width: 7.7rem;
					margin-top: 0.36rem;
					margin-left: 0.22rem;
					font-size: 0.14rem;
					font-family: Arial;
					font-weight: 400;
					color: #808080;
					line-height: 0.24rem;
					margin-bottom: 0.5rem;
				}
				img{
					width:0.18rem;
					height: 0.25rem;
					position: absolute;
					right: 0.39rem;
					top: 0.18rem;
				}
			}
		}
		.right{
			width: 5.82rem;
			height: 5.52rem;
			background: #F5F5F5;
			border-radius: 0.1rem;
			.title{
				width: 5.05rem;
				font-size: 0.24rem;
				font-family: Arial;
				font-weight: bold;
				color: #4D4D4D;
				margin-top: 0.53rem;
				margin-left: 0.38rem;
			}
			.form{
				width: 4.19rem;
				height: 0.81rem;
				border-bottom: 0.01rem solid #999;
				margin-left: 0.38rem;
				input{
					padding-top: 0.2rem;
					padding-bottom: 0.2rem;
					font-size: 0.2rem;
					font-family: Arial;
					font-weight: 400;
					color: #999999;
					margin-top: 0.12rem;
					padding-left:0.04rem ;
					border: none;
					background: #F5F5F5;
				}
			}
			.btn{
				width: 1.5rem;
				height: 0.48rem;
				background: linear-gradient(266deg, #910000, #E51A1A);
				border-radius: 0.1rem;
				line-height: 0.48rem;
				text-align: center;
				font-size: 0.2rem;
				font-family: Arial;
				font-weight: 400;
				color: #F4F4F4;
				margin-left: 0.38rem;
				margin-top: 0.43rem;
			}
		}
	}
	
	// 移动端 样式
		@media (max-width:1024px){
			.faq{
				width:100%;
				margin-bottom: 0.98rem;
				align-items: center;
				flex-direction: column;
				.left{
					width: 5.81rem;
					.box{
						padding-top: 0.21rem;
						padding-bottom: 0.21rem;
						border: 0.01rem solid #CCCCCC;
						border-radius: 0.1rem;
						position: relative;
						margin-bottom: 0.2rem;
						.title{
							margin-left: 0.22rem;
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #666666;
						}
						.text{
							width: 5.19rem;
							margin-top: 0.36rem;
							margin-left: 0.22rem;
							font-size: 0.14rem;
							font-family: Arial;
							font-weight: 400;
							color: #808080;
							line-height: 0.24rem;
							margin-bottom: 0rem;
						}
						img{
							width:0.18rem;
							height: 0.25rem;
							position: absolute;
							right: 0.39rem;
							top: 0.18rem;
						}
					}
				}
				.right{
					width:100%;
					height: 5.52rem;
					background: #F5F5F5;
					border-radius: 0rem;
					margin-top: 0.8rem;
					display: flex;
					flex-direction: column;
					align-items: center;
					.title{
						width: 5.81rem;
						font-size: 0.3rem;
						font-family: Arial;
						font-weight: bold;
						color: #4D4D4D;
						margin-top: 0.53rem;
						margin-left: 0.38rem;
					}
					.form{
						width: 5.81rem;
						height: 0.81rem;
						border-bottom: 0.01rem solid #999;
						margin-left: 0.38rem;
						input{
							padding-top: 0.2rem;
							padding-bottom: 0.2rem;
							font-size: 0.2rem;
							font-family: Arial;
							font-weight: 400;
							color: #999999;
							margin-top: 0.12rem;
							padding-left:0.04rem ;
							border: none;
							background: #F5F5F5;
						}
					}
					.btn{
						width: 1.5rem;
						height: 0.48rem;
						background: linear-gradient(266deg, #910000, #E51A1A);
						border-radius: 0.1rem;
						line-height: 0.48rem;
						text-align: center;
						font-size: 0.2rem;
						font-family: Arial;
						font-weight: 400;
						color: #F4F4F4;
						margin-left: 0.38rem;
						margin-top: 0.43rem;
					}
				}
			}
		}
</style>