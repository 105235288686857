<template>
	<div class="otherProducts flex_box_cen">
		<div class="wrap">
			<div class="title wow fadeInUp">Other Products</div>
			<div class="product_list">
				<div class="swiper-container">
				  <div class="swiper-wrapper">
					  <div class="swiper-slide wow fadeInRight" v-for="(item,index) in otherList" :key='index'>
					  	<div class="pic" @click="go(item.url)">
					  		<img :src="item.img" alt="">
							<!-- <div class='text'>{{item.text}}</div> -->
					  	</div>
					  </div>
				   <!-- <div class="swiper-slide wow fadeInRight ">
						<div class="pic">
							<img src="../../../public/productImg/apc_mini.png" alt="">
							<div class='text'></div>
						</div>
					</div>
				    <div class="swiper-slide wow fadeInRight">
				    	<div class="pic">
							<img src="../../../public/productImg/apc.png" alt="">
						</div>
				    </div>
				    <div class="swiper-slide wow fadeInRight">
				    	<div class="pic">
				    		<img src="../../../public/productImg/app.png" alt="">
				    	</div>
				    </div>
					<div class="swiper-slide wow fadeInRight">
						<div class="pic">
							<img src="../../../public/productImg/apv.png" alt="">
						</div>
					</div> -->
					
				  </div>
					<div class="swiper-pagination"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from 'wowjs/dist/wow'
	export default{
		props: {
			otherList:{
				type: Array,
				default(){
					return []
				}
			}
		},
		data() {
			return{
				windowWidth: document.documentElement.clientWidth, //获取屏幕宽度
			}
		},
		watch:{
			windowWidth (val) {
				let that = this
				console.log(val)
				if(val<=1024){
					this.getSwiper(1)
				
				}else{
					this.getSwiper(4)
				}
			}
		},
		mounted() {
			
			this.getWindowWidth()
			this.getSwiper()
			this.$nextTick(()=>{
				var wow = new WOW.WOW({
					boxClass:'wow',
					animateClass:'animated',
					offset:0,
					mobile:true,
					live :false
				})
				wow.init()
			})
		},
		
		methods:{
			getSwiper(num){
				if(this.windowWidth<=1024){
					const mySwiper = new Swiper('.swiper-container', {
						// autoplay: 1000,//可选选项，自动滑动
						slidesPerView : num,
						pagination : '.swiper-pagination',
						paginationElement : 'li',
						paginationClickable :true,
						spaceBetween : 20,
					})
				}else{
					const mySwiper = new Swiper('.swiper-container', {
						// autoplay: 1000,//可选选项，自动滑动
						slidesPerView : 4,
						spaceBetween : 20,
					})
				}
				
			},
			getWindowWidth(){
				var that = this;
				window.onresize = () => {
					return (() => {
						window.fullHeight = document.documentElement.clientHeight;
						window.fullWidth = document.documentElement.clientWidth;
						that.windowHeight = window.fullHeight; //获取屏幕高度
						that.windowWidth = window.fullWidth; //获取屏幕宽度
					})()
				};
			},
			go(url){
				this.$router.push(url)
				document.documentElement.scrollTop = 0
			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.swiper-pagination-bullet-active{
		background-color: #910000;
		width: 0.18rem;
		height: 0.18rem;
	}
	/deep/.swiper-pagination-bullet{
		width: 0.18rem;
		height: 0.18rem;
	}
	.otherProducts{
		width: 100%;
		padding-bottom: 1.28rem;
		.wrap{
			width: 15.37rem;
			margin-top: 0.99rem;
			.title{
				font-size: 36px;
				font-family: Arial;
				font-weight: bold;
				color: #5C6079;
			}
			.product_list{
				margin-top: 0.52rem;
				height: 3rem;
				
				.pic{
					width: 3.7rem;
					height: 3rem;
					// border: 0.01rem solid #CBCBCB;
					position: relative;
					img{
						width: 3.7rem;
						height: 3rem;
						position: absolute;
						bottom: 0rem;
					}
					.text{
						width: 3.7rem;
						font-size: 0.18rem;
						font-family: Arial;
						font-weight: 400;
						color: #4D4D4D;
						text-align: center;
						position: absolute;
						bottom: 0.3rem;
					}
				}
				.pic:hover{
					cursor: pointer;
					
				}
			}
		}
	}
	// 移动端 样式
		@media (max-width:1024px){
			.otherProducts{
				width: 100%;
				padding-bottom: 1.28rem;
				
				.wrap{
					width: 5.71rem;
					margin-top: 0.99rem;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					.title{
						width: 5.71rem;
						font-size: 36px;
						font-family: Arial;
						font-weight: bold;
						color: #5C6079;
					}
					.product_list{
						width: 5.01rem;
						margin-top: 0.52rem;
						height: 4.06rem;
						
						.pic{
							width: 5.01rem;
							height: 4.63rem;
							img{
								width: 5.01rem;
								height: 4.06rem;
							}
						}
						.pic{
							width: 5.01rem;
							height: 4.63rem;
							// border: 0.01rem solid #CBCBCB;
							position: relative;
							img{
								width: 5.01rem;
								height: 4.06rem;
								position: absolute;
								bottom: 0rem;
							}
							.text{
								width: 5.01rem;
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: 400;
								color: #4D4D4D;
								text-align: center;
								position: absolute;
								bottom: 0.3rem;
							}
						}
					}
				}
			}
		}
</style>