<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features :pplicableIofo='pplicableIofo' :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<!-- <product-appearance :appearanceList='appearanceList'></product-appearance> -->
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<!-- <support-term :termList='termList' :sid='sid' :tobel_bom='tobel_bom'></support-term> -->
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'productInfo',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/1000V-UPV-S/product.png'),
					title:'1000V-UPV-S Three Phase Solar+Storage Hybrid Inverters',
					info: `
						UPV-S series Bi-directional hybrid storage inverter is mainly composed 
						of DC-AC inverter, DC-DC solar controller, AC power distribution, transformer, 
						monitoring system,etc. It can be used in both on-grid and off-grid modes. 
						It adopts modular design, high integration, convenient and flexible installation, 
						which allows modular splicing according to actual application scenarios, and can 
						efficiently use solar power to meet the application requirements of small and 
						medium-sized micro-grid and industrial and commercial buildings. 
					`,
					id:'0'
				},
				productInfo:[
					{
						title:'Operating Voltage',
						list:[
							{
								info:"DC 600~900V",
								icon:'',
							},
							{
								info:"  AC 380V（3P4W）",
								icon:'',
							}
						]
					},
					{
						title:'Operating Frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application Scenario',
						list:[
							{
								info:"Factory",
								icon:require('../../public/images/scale.png'),
							},
							{
								info:"Commercial building",
								icon:require('../../public/images/as2.png'),
							},
							{
								info:"Energy storage power station",
								icon:require('../../public/images/as3.png'),
							}
						]
					},
					{
						title:'Range Of Power',
						list:[
							{
								info:"50KW",
								icon:'',
							},
							{
								info:"100KW",
								icon:'',
							},
							{
								info:"150KW",
								icon:'',
							},
							{
								info:"200KW",
								icon:'',
							},
							{
								info:"250KW",
								icon:'',
							}
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/net.png'),
						text:'With MPPT function',
					},
					{
						icon:require('../../public/images/pf7.png'),
						text:'Support with BMS,EMS system local control',
					},
					{
						icon:require('../../public/images/pf8.png'),
						text:'Modular design with N+1 redundancy support',
					},
					{
						icon:require('../../public/images/pf9.png'),
						text:'Bidirectional power conversion, four-quadrant operation',
					},
					{
						icon:require('../../public/images/pf10.png'),
						text:'Seamless switching between on-grid and off-grid (optional STS)',
					},
					{
						icon:require('../../public/images/pf12.png'),
						text:'Self-contained solar storage operation strategy',
					},
					{
						icon:require('../../public/images/pf13.png'),
						text:'Support self-generation, micro-grid application ',
					}
				],
				
				priceInfo:[						//技术参数信息
					{
						name:'',
						list:[
							{
								parameterName:'Model',
								parameterList:['UPV-S 0.4-50kW','UPV-S 0.4-100kW','UPV-S 0.5-50kW','UPV-S 0.5-100kW']
							}
						]
					},
					{
						name:'Stand-alone Mode',
						list:[
							{
								parameterName:'AC output voltage',
								parameterList:['400V±10%(Controllable) ','480V±10%(Controllable)']
							},
							{
								parameterName:'AC output current',
								parameterList:['72A(Max 79A) ','144A(Max 159A) ','60A(Max 66A)','120A(Max 132A)']
							},
							{
								parameterName:'Nominal AC output power ',
								parameterList:['50kW','100kW','50kW ','100kW']
							},
							{
								parameterName:'AC Max Power',
								parameterList:['55kW ','110kW','55kW','110kW']
							},
							{
								parameterName:'Output THDu',
								parameterList:['≤3%（Linear load）']
							},
							{
								parameterName:'AC frequency',
								parameterList:['50/60Hz ','60Hz ']
							},
							{
								parameterName:'AC PF',
								parameterList:['Listed: 0.8~1 leading or lagging (Load dependent) Actual: 0.1~1']
							},
							{
								parameterName:'Overload Capability',
								parameterList:['120% 1min ']
							},
							{
								parameterName:'Battery voltage range',
								parameterList:[' 400~600V（Rated 512V']
							},
							{
								parameterName:'Battery DC Max Current',
								parameterList:[' 120A','240A','120A ','240A']
							},
							{
								parameterName:'PV Voltage Range  ',
								parameterList:[' 520~900V (MPPT 520V~800V) ']
							},
							{
								parameterName:'PV DC. Max Current',
								parameterList:[' 192A','384A',' 192A','384A ']
							}
						]
					},
					{
						name:'Utility grid-interactive Mode',
						list:[
							{
								parameterName:'AC voltage range',
								parameterList:[' 400V±15%  ','480V±15% ']
							},
							{
								parameterName:'AC rated current ',
								parameterList:['72A ','144A','60A','120A']
							},
							{
								parameterName:'Nominal AC output power ',
								parameterList:['50kW','100kW','50kW','100kW']
							},
							{
								parameterName:'AC frequency',
								parameterList:['50Hz / 60Hz±2.5Hz','60Hz±0.2%±2.5Hz']
							},
							{
								parameterName:'Output THDI',
								parameterList:['≤3%']
							},
							{
								parameterName:'AC PF',
								parameterList:['-1~1']
							},
							{
								parameterName:'Battery voltage range',
								parameterList:['400~600V（Rated 512V）']
							},
							{
								parameterName:'Batter DC Max Current ',
								parameterList:['120A','240A','120A','240A']
							},
							{
								parameterName:'PV Voltage Range  ',
								parameterList:['520~900V (MPPT 520V~800V)']
							},
							{
								parameterName:'PV DC. Max Current',
								parameterList:['192A','384A','192A','384A']
							}
						]
					},
					{
						name:'Other',
						list:[
							{
								parameterName:'Peak efficiency',
								parameterList:['≥96%']
							},
							{
								parameterName:'Protection',
								parameterList:['Overtemperature protection, AC over/under voltage protection, Over/under frequency protection,Emergency power off, AC phase reverse, Fan/relay failure, Over/under load protection, Ground faultcircuit Interrupter, Anti-islanding']
							},
							{
								parameterName:'Configurable protection limits',
								parameterList:['Upper/Lower AC Voltage/Frequency limit, Battery end of discharge voltage.']
							},
							{
								parameterName:'AC connection',
								parameterList:['3P4W']
							},
							{
								parameterName:'Display',
								parameterList:['7 "color touch screen']
							},
							{
								parameterName:'Communication',
								parameterList:['RS485,CAN,Ethernet']
							},
							{
								parameterName:'Isolation ',
								parameterList:['Built-in Transformer']
							}
						]
					},
					{
						name:'Physical ',
						list:[
							{
								parameterName:'Cooling',
								parameterList:['Forced air cooling']
							},
							{
								parameterName:"Noise",
								parameterList:['≤70dB']
							},
							{
								parameterName:'Enclosure',
								parameterList:['IP20/IP54']
							},
							{
								parameterName:'Max elevation',
								parameterList:['3000m/10000feet (>2000m/6500feet derating)']
							},
							{
								parameterName:'Operating temp',
								parameterList:['﹣20℃～50℃(＞45℃derating) ']
							},
							{
								parameterName:'Humidity',
								parameterList:['0~95% (No condensing)']
							},
							{
								parameterName:'Size (W×H×D)',
								parameterList:['1500*2200*1450mm']
							},
							{
								parameterName:'Weight',
								parameterList:['/','/','/','/','/','/']
							},
							{
								parameterName:'Installation ',
								parameterList:['Floor standing']
							}
						]
					}
				],
				otherList:[
					{
						img:require('../../public/otherImg/1000V-UP-S.png'),
						url:'/1000V-UP-S'
					},
					{
						img:require('../../public/otherImg/1000V-UP-M.png'),
						url:'/1000V-UP-M'
					}
				]
			}
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
