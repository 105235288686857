<template>
	<!-- 系统方案选项卡组件-->
	<div class='option flex_box_column_cen'>
		<!-- 系统方案1 -->
		<ul class="flex_box_bet">
			<li :class="i==index?'li1':''" v-for="(item,index) in titles" :key='index' @click="change(index)">{{item}}</li>
		</ul>
		<div class="wrap flex_box_column_cen" v-if="i==0">
			<div class="pic">
				<img src="../../../public/systemScheme/ss1.png">
			</div>
			<div class="box">
				<div class="tit">System configuration: Solar controller (2)+Inverter+Lithium battery pack(10MAX)+CIMC module+WIFI module</div>
				<div class='txt_box flex_box'>
					<div class="txt" v-for="(item,index) in list" :key='index'>
						{{item}}
					</div>
				</div>
			</div>
		</div>
		<!-- 系统方案2 -->
		<div class="wrap flex_box_column_cen" v-if="i==1">
			<div class="pic2" >
				<img src="../../../public/systemScheme/ss2.png">
			</div>
			<div class="box">
				<div class="tit">System configuration:Solar controller (4) +Inverter+Lithium battery pack(10MAX)+LCD module+WIFI module</div>
				<div class='txt_box flex_box'>
					<div class="txt" v-for="(item,index) in list2" :key='index'>
						{{item}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				i:'0',
				titles:['DIY System Scheme 1','DIY System Scheme 2'],
				list:[
					'1. Solar panel','2. AC load','3. Grid','4. Generator',
					'5. MPPT solar charger controller','6. Inverter','7. Lithium battery pack',
					'8. CIMC module','9. WIFI module','10. APP Interface','11. Cable for MPPT to CIMC module',
					'12. Cable for Inverter to CIMC module','13. Cables for battery parallel connection',
					'14. Cable for battery pack to CIMC module','15. Battery pack /CAN cable (PC)','16. Cable for CIMC module to WIFI module'
				],
				list2:[
					'1. Solar panel','2. AC load','3. Grid','4. Generator',
					'5. MPPT solar charger controller','6. Inverter','7. Lithium battery pack',
					'8. LCD module','9. WIFI module','10. APP display interface ','11. Cable for MPPT to LCD module',
					'12. Cable for Inverter to LCD module','13. Cables for battery parallel connection',
					'14. Cable for battery pack to CIMC module','15. Battery pack /CAN cable (PC)','16. Cable for CIMC module to WIFI module'
				]		//系统方案2
			}
		},
		methods:{
			change(index){
				this.i = index
			}
		}
	}
</script>

<style lang="scss" scoped>
	.option{
		width:100%;
		height:100%;
		margin-top:0.6rem;
		ul{
			width:100%;
			background-color:#EBEBEB;
			li{
				width: 9.58rem;
				height:0.68rem;
				line-height: 0.68rem;
				text-align: center;
				font-size: 0.3rem;
				font-family: Arial;
				font-weight: 400;
				color: #4D4D4D;
			}
			.li1{
				font-family: Arial;
				font-weight: bold;
				color: #333333;
				background: #E0E0E0;
			}
			li:hover{
				cursor: pointer;
			}
		}
		.wrap{
			width:84%;
			height:100%;
			margin-bottom: 0.52rem;
			.pic{
				width:9.06rem;
				height:6.56rem;
				margin-top: 0.84rem;
				margin-bottom:0.81rem ;
				img{
					width:9.06rem;
					height:6.56rem;
				}
			}
			.pic2{
				width:13.01rem;
				height:6.32rem;
				margin-top: 0.84rem;
				margin-bottom:0.81rem ;
				img{
					width:13.01rem;
					height:6.32rem;
				}
			}
			.box{
				width:100%;
				font-size: 0.18rem;
				font-family: Arial;
				font-weight: bold;
				color: #4C4C4C;
			}
			.txt_box{
				width:100%;
				flex-wrap: wrap;
				.txt{
					font-size: 0.18rem;
					font-family: Arial;
					font-weight: 400;
					color: #4C4C4C;
					margin-right: 0.6rem;
					margin-top: 0.24rem;
				}
			}
		}
	}
</style>