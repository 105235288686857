<template>
	<div class="productFeatures flex_box_column_cen">
		<div class="features">
			<div class="title wow fadeInUp">
				Product Features
			</div>
			<div class="feat_list flex_box_column_cen">
				<ul class="flex_box flex_box_cen">
					<li class="flex_box_column_cen wow fadeInRight" v-for="(item,index) in pplicableIcons" :key="index">
						<div class="pic ">
							<img :src="item.icon" >
						</div>
						<div class="cont flex_box_column_align_cen ">
							{{item.text}}
						</div>
					</li>
				</ul>
			</div>
			
			<div class="title wow fadeInUp" v-if="OPFeatures.length!=''">
				Optional Product Features
			</div>
			<div class="feat_list flex_box_column_cen" v-if="OPFeatures.length">
				<ul class="flex_box flex_box_cen">
					<li class="flex_box_column_cen wow fadeInRight" v-for="(item,index) in OPFeatures" :key="index">
						<div class="pic ">
							<img :src="item.icon" >
						</div>
						<div class="cont flex_box_column_align_cen ">
							{{item.text}}
						</div>
					</li>
				</ul>
			</div>
			
			<div class="applicable_box">
				<div class="tit wow fadeInUp" v-if="pplicableIofo.length">
					Applicable Products
				</div>
				<div  class="applicable flex_box_column_cen" v-for="(item,index) in pplicableIofo" :key='index'>
					<div class="applicable_list">
						<div class="list_top flex_box_cen" v-if="item.title !=''">
							<div class="list_title wow fadeInUp">
								{{item.title}}
							</div>
						</div>
						<ul class="flex_box_cen_align_cen" >
							<li class="flex_box_column_align_cen wow fadeInUp" v-for="(itemson,indexson) in item.list" :key='indexson'>
								<div class="pic flex_box_column_align_cen">
									<img :src="itemson.pic" >
								</div>
								<div class="text">{{itemson.text}}</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from 'wowjs/dist/wow'
	export default{
		props: {
			pplicableIofo: {
				type: Array,
				default(){
					return []
				}
			},
			pplicableIcons: {
				type: Array,
				default(){
					return []
				}
			},
			OPFeatures: {
				type: Array,
				default(){
					return []
				}
			},
		},
		data() {
			return{
				
			}
		},
		created() {
			
			
		},
		mounted() {
			this.$nextTick(()=>{
				var wow = new WOW.WOW({
					boxClass:'wow',
					animateClass:'animated',
					offset:0,
					mobile:true,
					live :false
				})
				wow.init()
			})
		},
	}
</script>

<style lang="scss" scoped>
	.productFeatures{
		width: 100%;
		.features{
			width: 15.36rem;
			.title{
				font-size: 0.36rem;
				font-family: Arial;
				font-weight: bold;
				color: #5C6079;
				margin-top: 0.82rem;
				margin-bottom: 0.9rem;
			}
			.feat_list{
				height: 100%;

				ul{
					width: 95%;
	
					li{
						width: 12%;
						margin-right: 3%;
						.pic{
							max-width: 0.59rem;
							height: 0.54rem;
							margin-bottom: 0.29rem;
							img{
								max-width: 0.59rem;
								height: 0.54rem;
							}
						
						}
						.cont{
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #4D4D4D;
							text-align: center;
							line-height: 0.22rem;
						}
					}
					li:last-child{
						margin-right: 0;
					}
				}
			}
			.applicable_box{
				width: 100%;
				margin-top: 0.83rem;
				.tit{
					font-size: 0.36rem;
					font-family: Arial;
					font-weight: bold;
					color: #5C6079;
					margin-bottom: 0.77rem;
				}
				.applicable{
					width: 100%;
					.applicable_list{
						width: 83%;
						// width: 13.72rem;
						margin-bottom: 0.93rem;
						
						.list_top{
							width: 100%;
							border-bottom:0.01rem solid #E0E0E0;
							.list_title{
								min-width: 2.88rem;
								font-size: 0.24rem;
								font-family: Arial;
								font-weight: 400;
								color: #333333;
								padding-bottom: 0.15rem;
								text-align: center;
								border-bottom: 0.04rem solid #5C6079;
							}
						}
						
						ul{
							
							margin-top: 0.41rem;
							li{
								// margin-right: 1.05rem;
								margin-right: 0.5rem;
								// padding-left: 0.5rem;
								// margin-right: 0.5rem;
								.pic{
									// width: 0.62rem;
									height: 0.59rem;
									img{
										height: 0.59rem;
									}
								}
								.text{
									font-size: 0.18rem;
									font-family: Arial;
									font-weight: 400;
									color: #4D4D4D;
									margin-top: 0.34rem;
								}
							}
							li:last-child{
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}
	
	// 移动端 样式
		@media (max-width:1024px){
			.productFeatures{
				width: 100%;
				.features{
					width: 5.84rem;
					.title{
						font-size: 0.36rem;
						font-family: Arial;
						font-weight: bold;
						color: #5C6079;
						margin-top: 0.8rem;
						margin-bottom: 0.5rem;
					}
					.feat_list{
						width: 5.84rem;
			
						ul{
							width: 5.84rem;
							flex-wrap: wrap;
			
							li{
								width: 5.84rem;
								margin-right: 3%;
								border-bottom: 0.01rem solid #CCCCCC;
								flex-direction: row;
								align-items: center;
								padding-bottom: 0.3rem;
								padding-top: 0.3rem;
								.pic{
									min-width: 0.59rem;
									height: 0.54rem;
									margin-bottom: 0rem;
									margin-right: 0.29rem;
									display: flex;
									justify-content: center;
									img{
										max-width: 0.59rem;
										height: 0.54rem;
									}
								
								}
								.cont{
									font-size: 0.18rem;
									font-family: Arial;
									font-weight: 400;
									color: #4D4D4D;
									text-align: left;
									line-height: 0.22rem;
								}
							}
							li:last-child{
								margin-right: 0;
								border-bottom: none;
							}
						}
					}
					.applicable_box{
						width: 5.84rem;
						margin-top: 0.6rem;
						.tit{
							font-size: 0.36rem;
							font-family: Arial;
							font-weight: bold;
							color: #5C6079;
							margin-bottom: 0.77rem;
						}
						.applicable{
							width: 5.84rem;
							.applicable_list{
								width: 5.84rem;
								// width: 13.72rem;
								margin-bottom: 0.53rem;
								
								.list_top{
									width: 5.84rem;
									border-bottom:0.01rem solid #E0E0E0;
									.list_title{
										min-width: 2.88rem;
										font-size: 0.24rem;
										font-family: Arial;
										font-weight: 400;
										color: #333333;
										padding-bottom: 0.15rem;
										text-align: center;
										border-bottom: 0.04rem solid #5C6079;
									}
								}
								
								ul{
									width: 5.84rem;
									margin-top: 0.41rem;
									flex-wrap: wrap;
									align-items: flex-start;
									justify-content: flex-start;
									li{
										width: 1.16rem;
										margin-right: 0rem;
										margin-bottom: 0.4rem;
										.pic{
											// width: 0.62rem;
											height: 0.59rem;
											img{
												height: 0.59rem;
											}
										}
										.text{
											font-size: 0.18rem;
											font-family: Arial;
											font-weight: 400;
											color: #4D4D4D;
											margin-top: 0.34rem;
											text-align: center;
										}
									}
									li:last-child{
										margin-right: 0;
									}
								}
							}
						}
					}
				}
			}
		}
</style>