<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- <diy-option></diy-option> -->
	<!-- 底部 -->
	<new-footer style='margin-top:1.43rem;'></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product2/product.vue'										//产品介绍组件
	import newFooter from '@/components/footer/footer.vue'											//网页底部
	// import diyOption from '@/components/diyOption'													//DIY系统方案 组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'LED Remote control panel',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			newFooter,
			// diyOption,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/LED/product.png'),
					title:'LED Remote control panel',
					info: `
						Designed for remote start/stop and monitoring of inverter equipment. 
						Through the LED remote control screen, users can easily start and stop 
						the inverter equipment remotely. At the same time, the screen can also 
						monitor the inverter's operating status in real time.
					`
				},
				productInfo:[
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							},{
								info:"Recreation vehicles",
								icon:require('../../public/images/cart.png'),
							},
							{
								info:"Heavy duty truck",
								icon:require('../../public/images/bigCart.png'),
							},
							{
								info: "Yacht",
								icon:require('../../public/images/ship.png'),
							},
							{
								info:"Engineering vehicle",
								icon:require('../../public/images/evCart.png'),
							}
						]
					},
					{
						title:'Compatible with',
						list:[
							{
								info:"HP Series",
								icon:'',
							},{
								info:'APP Series',
								icon:'',
							},{
								info:'APC Series',
								icon:'',
							},{
								info:'APS Series',
								icon:'',
							},{
								info:'TPP Series',
								icon:'',
							},{
								info:'PSW7 Series ',
								icon:'',
							},{
								info:'HPV Series ',
								icon:'',
							},{
								info:'APV Series',
								icon:'',
							}
						]
					}
				
				],
				
			}
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
