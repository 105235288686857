import { render, staticRenderFns } from "./productAppearance.vue?vue&type=template&id=99cb053c&scoped=true&"
import script from "./productAppearance.vue?vue&type=script&lang=js&"
export * from "./productAppearance.vue?vue&type=script&lang=js&"
import style0 from "./productAppearance.vue?vue&type=style&index=0&id=99cb053c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99cb053c",
  null
  
)

export default component.exports