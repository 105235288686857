<template>
	<!-- 下载页 -->
	<div class='download'>
		<ul class="flex_box_wrap">
			<li v-for="(item,index) in list" :key='item.id'>
				<div class="tit">{{item.title}}</div>
				<div class="btn">
					<span>Download</span>
					<div class="increase"></div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				list:[
					{
						id:1,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:2,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:3,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:4,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:5,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:6,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:7,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:8,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:9,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:10,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:11,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:12,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:13,
						title:'Energy storage microgrid.pdf'
					},
					{
						id:14,
						title:'Energy storage microgrid.pdf'
					},{
						id:15,
						title:'Energy storage microgrid.pdf'
					},
					
					{
						id:16,
						title:'Energy storage microgrid.pdf'
					}
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
	.download{
		width:100%;
		height: 7.6rem;
		border: 0.01rem solid #CCCCCC;
		border-radius: 0.1rem;
		margin-bottom: 0.98rem;
		ul{
			width: 13.99rem;
			margin-left: 0.72rem;
			margin-top: 0.12rem;
			li{
				width: 6.59rem;
				height: 0.88rem;
				border-bottom: 0.01rem solid #E0D6CC;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.tit{
					font-size: 0.18rem;
					font-family: Arial;
					font-weight: 400;
					color: #4D4D4D;
				}
				.btn{
					width: 1.4rem;
					height: 0.31rem;
					border: 0.01rem solid #CCCCCC;
					border-radius: 0.16rem;
					position: relative;
					span{
						font-size: 0.16rem;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #6E6E6E;
						float: left;
						margin-top: 0.07rem;
						margin-left: 0.18rem;
					}
					.increase{
						width: 0.3rem;
						height: 0.3rem;
						background: #910000 url('../../../public/smallImg/download.png') no-repeat center center;
						background-size:0.15rem 0.17rem;
						// border: 0.01rem solid #CCCCCC;
						border-radius: 50%;
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						
					}
				}
			}
			li:last-child{
				border:none;
			}
			li:nth-last-child(2){
				border:none;
			}
		}
	}
	
	// 移动端 样式
		@media (max-width:1024px){
			.download{
				width:5.81rem;
				height:100%;
				border: none;
				border-radius: 0.1rem;
				margin-bottom: 0.98rem;
				ul{
					width:5.81rem;
					margin-left: 0rem;
					margin-top: 0.12rem;
					li{
						width:5.81rem;
						height: 0.88rem;
						border-bottom: 0.01rem solid #E0D6CC;
						display: flex;
						justify-content: space-between;
						align-items: center;
						.tit{
							font-size: 0.24rem;
							font-family: Arial;
							font-weight: 400;
							color: #4D4D4D;
						}
						.btn{
							width: 2.08rem;
							height: 0.4rem;
							border: 0.01rem solid #CCCCCC;
							border-radius: 0.2rem;
							position: relative;
							span{
								font-size: 0.24rem;
								font-family: Microsoft YaHei;
								font-weight: bold;
								color: #6E6E6E;
								float: left;
								margin-top: 0.07rem;
								margin-left: 0.18rem;
							}
							.increase{
								width: 0.4rem;
								height: 0.4rem;
								background: #910000 url('../../../public/smallImg/download.png') no-repeat center center;
								background-size:0.15rem 0.17rem;
								// border: 0.01rem solid #CCCCCC;
								border-radius: 50%;
								position: absolute;
								right: 0;
								top: 0;
								bottom: 0;
								
							}
						}
					}
					li:last-child{
						border-bottom: 0.01rem solid #E0D6CC;
					}
					li:nth-last-child(2){
						border-bottom: 0.01rem solid #E0D6CC;
					}
				}
			}
		}
</style>