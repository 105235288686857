import { render, staticRenderFns } from "./giveServiceTo.vue?vue&type=template&id=4f834707&scoped=true&"
import script from "./giveServiceTo.vue?vue&type=script&lang=js&"
export * from "./giveServiceTo.vue?vue&type=script&lang=js&"
import style0 from "./giveServiceTo.vue?vue&type=style&index=0&id=4f834707&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f834707",
  null
  
)

export default component.exports