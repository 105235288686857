<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<div class="screen flex_box_cen">
		<div class="wrap">
			<div class="box">
				<div class="box_list" v-for="(item,index) in labelMsg" :key='index'>
					<div class="box_title">{{item.title}}:</div>
					<div class="box_items">
						<div class="item" v-for="(itemson,indexson) in item.sons" :key='indexson' :class="chosedVal.indexOf(itemson.val)!=-1?'item1':'item'" @click="choseLabel(itemson.val)">
							<div class="state" :class="chosedVal.indexOf(itemson.val)!=-1?'state2':'state'"></div>
							{{itemson.name}}
						</div>
					</div>
				</div>
			</div>
			<div class="product_list">
				<div class="product_box" v-for="(item,index) in showProducts" :key='index'>
					<div class="pic">
						<img :src="item.img">
					</div>
					<div class="txt">{{item.title}}</div>
					<div class="btn" @click="go(item.link)">view more</div>
				</div>
			</div>
		</div>
	</div>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'screen',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			newFooter,
			rightFrame
		},
		data() {
			return{
				labelMsg: [
				    {
				        title: 'DC Voltage Class',
				        sons: [
				            {
				                name: '12V',
				                val: 'dc1'
				            },
				            {
				                name: '24V',
				                val: 'dc2'
				            },
				            {
				                name: '48V',
				                val: 'dc3'
				            },
							{
							    name: '96V',
							    val: 'dc4'
							},
							{
							    name: '1000V',
							    val: 'dc5'
							},
				        ]
				    },
				    {
				        title: ' AC Voltage Class',
				        sons: [
				            {
				                name: 'Single phase 110V',
				                val: 'ac1'
				            },
				            {
				                name: 'Single phase 115V',
				                val: 'ac2'
				            },
				            {
				                name: 'Single phase 120V',
				                val: 'ac3'
				            },
				            {
				                name: 'Single phase 220V',
				                val: 'ac4'
				            },
				            {
				                name: 'Single phase 230V',
				                val: 'ac5'
				            },
				            {
				                name: 'Single phase 240V',
				                val: 'ac6'
				            },
							{
							    name: 'Split phase 120V',
							    val: 'ac7'
							},
							{
							    name: 'Split phase 240V',
							    val: 'ac8'
							},
							{
							    name: 'Three phase 220V',
							    val: 'ac9'
							},
							{
							    name: 'Three phase 380V',
							    val: 'ac10'
							},
							{
							    name: 'Three phase 400V',
							    val: 'ac11'
							},
							{
							    name: 'Three phase 415V',
							    val: 'ac12'
							},
							{
							    name: 'Three phase 480V',
							    val: 'ac13'
							},
				        ]
				    },
				    {
				        title: 'Power Of Load',
				        sons: [
				            {
				                name: '1-6KW',
				                val: 'po1'
				            },
				            {
				                name: '6-18KW',
				                val: 'po2'
				            },
				            {
				                name: 'Above 18KW',
				                val: 'po3'
				            },
				        ]
				    },
				    {
				        title: 'ON&OFF Grid',
				        sons: [
				            {
				                name: 'OFF Grid',
				                val: 'on1'
				            },
				            {
				                name: 'ON Grid',
				                val: 'on2'
				            },
				            {
				                name:'ON&OFF Grid (Hybrid)',
				                val: 'on3'
				            }
				        ]
				    }, 
				],
				productMsg: [
				    {
						img:require('../../public/productImg/12v_hp.png'),
						title: '12V-HP Series Low Frequency Pure Sine Wave Inverter/Charger',
						link: '/12V-HP',
						label: ['dc1','ac1','ac2','ac3','ac4','ac5','ac6', 'ac7','ac8', 'po1','on1']
				    },
				    {
						img:require('../../public/productImg/12v_apc_mini.png'),
						title: '12V-APC mini Series Low Frequency Pure Sine Wave Inverter/Charger',
						link: '/12V-APCMini',
						label: ['dc1','ac1','ac2','ac3','ac4','ac5','ac6', 'po1','on1']
				    },
				    {
				        img: require('../../public/productImg/product1.png'),
				        title: '12V-AP Series Low Frequency Pure Sine Wave Inverter/Charg',
				        link: '/12V-AP',
						label: ['dc1','ac1','ac2','ac3','ac4','ac5','ac6', 'ac7','ac8', 'po1','on1']
				    },
					{
						title: '12V-OPS Series High Frequency Pure Sine Wave Inverter',
						img: require('../../public/productImg/ops.png'),
						link: '/12V-OPS',
						label: ['dc1','ac1','ac2','ac3','ac4','ac5','ac6', 'po1','on1']
				    },
					{
						title: '24V-HP Series Low Frequency Pure Sine Wave Inverter/Charger',
						img: require('../../public/productImg/24v_hp.png'),
						link: '/24V-HP',
						label: ['dc2','ac1','ac2','ac3','ac4','ac5','ac6', 'ac7','ac8', 'po1', 'po2','on1']
				    },
					{
						title: '24V-APC mini Series Low Frequency Pure Sine Wave Inverter/Charger',
						img: require('../../public/productImg/12v_apc_mini.png'),
						link: '/24V-APCMini',
						label: ['dc2','ac1','ac2','ac3','ac4','ac5','ac6', 'po1','on1']
				    },
					{
						title: '24V-AP Series Low Frequency Pure Sine Wave Inverter/Charg',
						img: require('../../public/productImg/product1.png'),
						link: '/24V-AP',
						label: ['dc2','ac1','ac2','ac3','ac4','ac5','ac6', 'ac7','ac8', 'po1','on1']
					},
					{
						title: '24V-OPS Series High Frequency Pure Sine Wave Inverter',
						img: require('../../public/productImg/ops.png'),
						link: '/24V-OPS',
						label: ['dc2','ac1','ac2','ac3','ac4','ac5','ac6', 'po1', 'po2','on1']
					},
					{
						title: '48V-HP Series Low Frequency Pure Sine Wave Inverter/Charger',
						img: require('../../public/productImg/48v_hp.png'),
						link: '/48V-HP',
						label: ['dc3','ac1','ac2','ac3','ac4','ac5','ac6', 'ac7','ac8', 'po1', 'po2','on1']
				    },
					{
						title: '48V-AP Series Low Frequency Pure Sine Wave Inverter/Charger',
						img: require('../../public/productImg/product1.png'),
						link: '/48V-AP',
						label: ['dc3','ac1','ac2','ac3','ac4','ac5','ac6', 'ac7','ac8', 'po1','on1']
				    },
				    {
						title: '48V-TPP Series Low Frequency Three-phase Pure Sine Wave Inverter/Charger',
						img: require('../../public/productImg/48v_tpp.png'),
						link: '/48V-TPP',
						label: ['dc3','ac9','ac10','ac11','ac12', 'po1', 'po2','po3','on1']
				    },
				    {
						title: '96V-HP Series Low Frequency Pure Sine Wave Inverter/Charger',
						img: require('../../public/productImg/96v_hp.png'),
						link: '/96V-HP',
						label: ['dc4','ac1','ac2','ac3','ac4','ac5','ac6', 'ac7','ac8', 'po2','on1']
				    },
					{
						title: '12V-HPV Series Low Frequency Pure Sine Wave Inverter/Charger+Controller',
						img: require('../../public/productImg/product5.png'),
						link: '/12V-HPV',
						label: ['dc1','ac1','ac2','ac3','ac4','ac5','ac6', 'ac7','ac8', 'po1','on1']
				    },
					{
						title: '12V-SMP Series High Frequency Solar Inverter',
						img: require('../../public/productImg/smp.png'),
						link: '/12V-SMP',
						label: ['dc1','ac4','ac5','ac6', 'po1','on1']
				    },
				    {
						title: '24V-HPV Series Low Frequency Pure Sine Wave Inverter/Charger+Controller',
						img: require('../../public/productImg/product5.png'),
						link: '/24V-HPV',
						label: ['dc2','ac1','ac2','ac3','ac4','ac5','ac6', 'ac7','ac8', 'po1','po2','on1']
				    },
				    {
						title: '24V-SMP Series High Frequency Solar Inverte',
						img: require('../../public/productImg/smp.png'),
						link: '/24V-SMP',
						label: ['dc2','ac4','ac5','ac6', 'po1','on1']
				    }, 
					{
						title: '48V-HPV Series Low Frequency Pure Sine Wave Inverter/Charger+Controller',
						img: require('../../public/productImg/48v_hpv.png'),
						link: '/48V-HPV',
						label: ['dc3','ac1','ac2','ac3','ac4','ac5','ac6', 'ac7','ac8', 'po1','po2','on1']
				    }, 
					{
						title: '48V-SMP Series High Frequency Solar Inverter',
						img: require('../../public/productImg/product6.png'),
						link: '/48V-SMP',
						label: ['dc3','ac4','ac5','ac6', 'po1','on1']
				    },
					{
						title: '48V-TPPV Series Low Frequency Three-phase Pure Sine Wave Inverter/Charger+Controller',
						img: require('../../public/productImg/48v_tpp.png'),
						link: '/48V-TPPV',
						label: ['dc3','ac9','ac10','ac11','ac12', 'po2','po3','on1']
				    },
					{
						title: '48V-UP Series Bi-directional Power Inverter/Charger',
						img: require('../../public/productImg/product7.png'),
						link: '/48V-UP',
						label: ['dc3','ac4','ac5','ac6', 'po1','on1','on3']
				    },
					{
						title: '48V-UPV Series Hybrid Energy Storage Inverter',
						img: require('../../public/productImg/product8.png'),
						link: '/48V-UPV',
						label: ['dc3','ac4','ac5','ac6', 'po1','on1','on3']
				    },
					{
						title: '1000V-UP-S SeriesThree Phase Power Conversion System',
						img: require('../../public/productImg/product9.png'),
						link: '1000V-UP-S',
						label: ['dc5','ac10','ac11','ac12', 'po3','on1','on3']
				    },
					{
						title: '1000V-UPV-S SeriesThree Phase Solar+Storage Hybrid Inverters',
						img: require('../../public/productImg/product10.png'),
						link: '/1000V-UPV-S',
						label: ['dc5','ac10','ac11','ac12','ac13', 'po3','on1','on3']
					},
					{
						title: '1000V-UP-M Series Power Conversion Module',
						img: require('../../public/productImg/product11.png'),
						link: '/1000V-UP-M',
						label: ['dc5','ac10','ac11','ac12', 'po3','on1','on3']
					}
					
				],
				chosedVal: [],
				showProducts: []
			}
		},
		watch: {
		    chosedVal(newV) {
		        const that = this;
		        that.showProducts=[];
		        that.productMsg.forEach((item,index)=>{
		            let flag=that.chosedVal.every((item2,index2)=>{
		                return item.label.indexOf(item2)!=-1;
		            });
		            if(flag==true){
		                that.showProducts.push(item);
		            };
					// console.log(that.showProducts)
		            // console.log(flag);
		        })
		    }
		},
		mounted() {
			const that=this;
			that.showProducts=that.productMsg;
		},
		methods: {
		    choseLabel(currentVal) {
		        const that = this;
		        if (that.chosedVal.indexOf(currentVal) == -1) {
		            that.chosedVal.push(currentVal);
		        }
		        else {
		            let valIndex = that.chosedVal.indexOf(currentVal);
		            that.chosedVal.splice(valIndex, 1);
		        }
		    },
			go(url){
				this.$router.push(url)
				document.documentElement.scrollTop = 0
			}
		},
	}
</script>
<style lang="scss" scoped>
	.screen{
		width: 100%;
		height: 100%;
		margin-bottom: 1.2rem;
		.wrap{
			width: 15.6rem;
			height: 100%;
			.box{
				width: 15.36rem;
				// height: 4.21rem;
				border: 0.01rem solid #E6E6E6;
				margin-top: 0.8rem;
				background-color: #E6E6E6;
				
				.box_list{
					display: flex;
					min-height: 0.68rem;
					.box_title{
						width: 1.89rem;
						font-size: 0.2rem;
						font-family: Arial;
						font-weight: 400;
						color: #999999;
						line-height: 0.68rem;
						margin-left: 0.4rem;
						border-bottom: 0.01rem dashed #CCCCCC;
					}
					.box_items{
						width: 13.06rem;
						background-color: #fff;
						border-bottom: 0.01rem dashed #CCCCCC;
						display: flex;
						flex-wrap: wrap;
						.item{
							padding: 20px 60px 20px 38px;
							font-size: 20px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #323232;
							cursor: pointer;
							transition: all ease 0.3s;
							display: flex;
							align-items: center;
							.state{
								width: 0.18rem;
								height: 0.18rem;
								background: url('../../public/screen/unselected.png') no-repeat center center;
								background-size: 0.18rem 0.18rem;
								margin-right: 0.08rem;
							}
							.state2{
								background: url('../../public/screen/selected.png') no-repeat center center;
								background-size: 0.18rem 0.18rem;
							}
						}
						.item1{
							color:#b00;
						}
					}
				}
			}
			.product_list{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				margin-top:0.2rem ;
				.product_box{
					width: 3.5rem;
					margin-top:0.6rem ;
					margin-right:0.4rem;
					.pic{
						width: 3.5rem;
						height: 3.5rem;
						background-color:#F4F4F4 ;
						position:relative;
						img{
							position:absolute;
							bottom: 0;
							width: 3.5rem;
							// height: 3.5rem;
						}
					}
					
					.txt{
						width: 3.31rem;
						margin-top: 0.3rem;
						margin-bottom: 0.3rem;
						font-size: 0.18rem;
						font-family: Arial;
						font-weight: 400;
						color: #808080;
						line-height: 0.3rem;
					}
					.btn{
						width: 1.6rem;
						height: 0.44rem;
						border: 0.01rem solid #C0C2C4;
						border-radius: 0.08rem;
						font-size: 0.2rem;
						font-family: Arial;
						font-weight: bold;
						color: #808080;
						line-height:0.44rem;
						text-align: center;
					}
					.btn:hover{
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
