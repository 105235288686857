<template>
	<div class='home flex_box_column_cen'>
		<yiy-header></yiy-header>
		<div class="banner_box">
			<div class="swiper-container">
			  <div class="swiper-wrapper">
			    <div class="swiper-slide">
					<div class="banner">
						<div class="title">PV STORAGE HYBRID INVERTER</div>
						<div class="btn">UPV Series</div>
						<div class="text">ON&OFF-Grid Operation Hybrid Power Supply</div>
					</div>
				</div>
			    <div class="swiper-slide">
					<div class="banner banner2">
						<div class="title">LOW FREQUENCY INVERTER/CHARGER</div>
						<div class="text1">High Power <span>1-18KW</span> </div>
						<div class="btn">HP Series</div>
						<div class="text">THD< 3%</div>
					</div>
				</div>
				<div class="swiper-slide">
					<div class="banner banner3">
						<div class="title">LOW FREQUENCY PURE SINE WAVE INVERTER/CHARGER</div>
						
						<div class="text">A Variety Of Styles <p>A Variety Of Functions To Meet Your Needs</p></div>
					</div>
				</div>
			   
			  </div>
			  <div class="swiper-pagination"></div><div class="swiper-pagination"></div>
			</div>
		</div>
		
		<div class="wrap flex_box_column_cen ">
			<div class="box"  @mouseenter="isBoxHover=true" @mouseleave="isBoxHover=false">
				<div class="title wow fadeInUp">Hybird Inverter </div>
				<div class="text animated" :class="isBoxHover?' fadeInUp':'fadeOutDown'">
					Bi-directional Power Inverter/Charger Hybrid Energy Storage Inverter
				</div>
				<div class="btn animated" :class="isBoxHover?' fadeInLeft':'fadeOutLeft '" @click="go('/HybirdInverter/0')">VIEW MORE</div>  
			</div>
			<div class="list_box">
				<ul class="flex_box_wrap">
					<li>
						<div class="tit">Inverter Charger </div>
						<div class="hover_box flex_box_column_cen">
							<span class="text">
								Low Frequency Pure Sine Wave Inverter/Charger 
								High Frequency Pure Sine Wave Inverter
								Low Frequency Three-phase Pure Sine Wave Inverter/Charger
							</span>
							<div class="btn animated"  @click="go('/InverterCharger/0')">VIEW MORE</div>  
						</div>
					</li>
					<li>
						<div class="tit">Solar Inverter</div>
						<div class="hover_box flex_box_column_cen">
							<span class="text">
								Low Frequency Pure Sine Wave Inverter/Charger+Controller
								High Frequency Solar Inverter
								Low Frequency Three-phase Pure Sine Wave Inverter/Charger+Controller
							</span>
							<div class="btn animated"  @click="go('/SolarInverter/0')">VIEW MORE</div>  
						</div>
					</li>
					<li>
						<div class="tit">Power Conversion System</div>
						<div class="hover_box flex_box_column_cen">
							<span class="text">
								Three Phase Power Conversion System
								Three Phase Solar+Storage Hybrid Inverters
								Power Conversion Module
							</span>
							<div class="btn animated"  @click="go('/PowerConversionSystem/0')">VIEW MORE</div>  
						</div>
					</li>
					<li>
						<div class="tit">Others</div>
						<div class="hover_box flex_box_column_cen">
							<span class="text">
								AC Converter / Battery Charger
								MPPT Charge Controller
								Accessory
							</span>
							<div class="btn animated"  @click="go('/Others/0')">VIEW MORE</div>  
						</div>
					</li>
					
				</ul>
			</div>
			<div class="base flex_box_column_cen">
				<!-- <div class="title wow fadeInUp">ABOUT YIY</div> -->
				<div class="base_top flex_box_bet">
					<div class="right2 wow fadeInUp">
						<span>Focus on power quality overall solutions</span>
						<span>Based on clean energy, focus on scientific and technological innovation</span>
					</div>
					<div class="left flex_box_align_cen wow fadeInUp">
						<!-- <span>Learn more</span> -->
						<!-- <img src="../../public/smallImg/arrow2.png" alt=""> -->
					</div>
					<!-- <div class="right wow fadeInUp">ABOUT YIY</div> -->
				</div>
				<div class='base_box flex_box_bet'>
					<div class="base_left wow fadeInLeftBig"></div>
					<div class="base_right">
						<div class="right wow fadeInUp">ABOUT YIY</div>
						<div class="text wow fadeInUp">
							Energy Storage System And Power Quality System Provider
							<p></p>
						</div>
						<div class="txt">
							YIYEN HOLDING GROUP is a high-tech company focused on the research and production of 
							power electronics technology, integrating design, R&D, manufacturing, sales and service. 
							YIYEN is dedicated to reducing electricity costs, improving electricity efficiency, and
							providing core power equipment an system solutions for the Energy Internet of Things. 
							With electrochemical energy storage, inverter charger, solar inverter, hybird inverter, 
							and power conversion system as its core industries, YIYEN provides energy-saving services 
							for power systems, communication systems, financial systems, education systems, medical systems, 
							and large industrial and mining enterprises. 
							<p>
								YIYEN will continue to dedicate itself to the research, development and manufacturing of power
								electronics technology, including inverter chargers, solar inverters, hybird inverters and power 
								conversion systems, and will be committed to providing cutting-edge solutions that help customers 
								achieve their energy management goals while contributing to a more sustainable future for all. 
							</p>
							
						</div>
						
					</div>
					
				</div>
				<ul>
					<li class="flex_box_bet fadeInUp wow">
						<div class="ic1"></div>
						<div class="num num1 " >300+</div>
						<div class="name">Company Personnel</div>
					</li>
					<li class="flex_box_bet fadeInUp wow">
						<div class="ic2"></div>
						<div class="num">15+</div>
						<div class="name">Years Production Experience</div>
						<!-- <div class="treetop">+</div> -->
					</li>
					<li class="flex_box_bet fadeInUp wow">
						<div class="ic3"></div>
						<div class="num">30000m²+</div>
						<div class="name">Plant Area</div>
						<!-- <div class="treetop ">+</div> -->
					</li>
					<li class="flex_box_bet fadeInUp wow">
						<div class="ic4"></div>
						<div class="num num1 " >100,000+ </div>
						<div class="name">Unit Shipments</div>
					</li>
					<li class="flex_box_bet fadeInUp wow">
						<div class="ic5"></div>
						<div class="num num1 " >50+</div>
						<div class="name">R&D Personnel</div>
					</li>
					<li class="flex_box_bet fadeInUp wow">
						<div class="ic6"></div>
						<div class="num num1 " >130+ Countries</div>
						<div class="name">of production base</div>
					</li>
					<li class="flex_box_bet fadeInUp wow">
						<div class="ic7"></div>
						<div class="num num1 " >100+</div>
						<div class="name">Intellectual Property</div>
					</li>
				</ul>
			</div>
			<div class="form_box flex_box_bet">
				<div class="left">
					<div class="title  wow fadeInUp">Service</div>
					<div class="left_btm">
						<div class="small_box flex_box_align_cen" @click="go({url:'/giveServiceTo',id:2})">
							<img src="../../public/smallImg/add.png">
							<div class="text flex_box_column">
								<span>Download Center</span>
								<div class="line"><p></p><span>our service</span></div>
							</div>
						</div>
						<div class="small_box flex_box_align_cen" @click="go({url:'/giveServiceTo',id:1})">
							<img src="../../public/smallImg/video.png">
							<div class="text flex_box_column">
								<span>Product Video</span>
								<div class="line"><p></p><span>our service</span></div>
								
							</div>
						</div>
						<div class="small_box flex_box_align_cen" @click="go({url:'/giveServiceTo',id:3})">
							<img src="../../public/smallImg/fdj.png">
							<div class="text flex_box_column">
								<span>FAQ</span>
								<div class="line"><p></p><span>our service</span></div>
							</div>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="title  wow fadeInUp">Send Us A Message</div>
					<div class="form">
						<div class="name_phone flex_box_bet">
							<div class="input_box">
								<input type="text" placeholder="Name" v-model="name">
								<div id='nameTip3' class='animated ' :class="state?'fadeInDown':''"></div>
							</div>
							<div class="input_box">
								<input type="text" placeholder="Phone" v-model="phone">
								<div id='mobileTip3' class='animated ' :class="state?'fadeInDown':''"></div>
							</div>
						</div>
						<div class="name_phone flex_box_bet">
							<div class="input_box">
								<input type="text" placeholder="Products" v-model="products">
								<div id='productTip3' class='animated ' :class="state?'fadeInDown':''"></div>
							</div>
							<div class="input_box">
								<input type="text" placeholder="E-mail" v-model="email">
								<div id='emailTip1' class='animated ' :class="state?'fadeInDown':''"></div>
							</div>
						</div>
						<input class="ipt" type="text" placeholder="Address" v-model="address">
						<div class="input_box">
							<textarea placeholder="Message" v-model="message"></textarea>
							<div id='messageTip3' class='animated' :class="state?'fadeInDown':''"></div>
						</div>
					</div>
					<div class="btn" @click="add()" v-show="!change">
						<span>Submit</span>
					</div>
					<div class="btn1" v-show="change">
						<span>Success</span>
					</div>
				</div>
			</div>
		</div>
		<new-footer></new-footer>
		<right-frame></right-frame>
	</div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
import WOW from 'wowjs/dist/wow'
import yiyHeader from '@/components/header/header.vue'											//头部组件
import newFooter from '@/components/footer/footer.vue'											//头部组件
import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
import axios from 'axios';
export default {
	metaInfo:{
		title:'home',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
	components:{
		yiyHeader,
		newFooter,
		rightFrame
	},
	data() {
		return{
			change:false,
			isBoxHover:false,
			state:false,
			name:'',						//名字
			phone:'',						//电话
			products:'',					//产品
			email:'',						//邮件
			address:'',						//地址
			message:'',						//详情
			website:'',						//当前页面
			ip:'',							// 获取ip
			country:'',
		}
	},
	created() {
	
		// 获取ip
		fetch('https://api.ipify.org?format=json')
		  .then(response => response.json())
		  .then(data => {
			this.ip = data.ip
		    // console.log(data.ip);
		  });
	},
	mounted() {
		this.swiper()
		this.$nextTick(()=>{
			const wow = new WOW.WOW({
				boxClass:'wow',
				animateClass:'animated',
				offset:0,
				mobile:true,
				live :false
			})
			wow.init()
		})
	},
	methods:{
		add(){
			this.change = true
			let ok = true
			// name
			if (this.name == '') {
				document.getElementById('nameTip3').innerText = "*required";
				this.state = true;
				ok = false
			} else {
				
				setTimeout(() => {
					document.getElementById('nameTip3').innerText = "";
				}, 300)
			}
			// phone
			if (this.phone == '') {
				document.getElementById('mobileTip3').innerText = "*required";
				this.state = true;
				ok = false
			} else {
				
				setTimeout(() => {
					document.getElementById('mobileTip3').innerText = "";
				}, 300)
			}
			// product
			if (this.products == '') {
				document.getElementById('productTip3').innerText = "*required";
				this.state = true;
				ok = false
			} else {
				
				setTimeout(() => {
					document.getElementById('productTip3').innerText = "";
				}, 300)
			}
			// email
			let reg_mail =
				/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
			if (this.email == '') {
				this.state = true;
				document.getElementById('emailTip1').innerText = "*required";
				ok = false
			} else if (!reg_mail.test(this.email)) {
				this.state = true;
				ok = false
				// 检查格式
				document.getElementById('emailTip1').innerText = "*E-mail format is incorrect";
				console.log('请输入正确的邮箱')
			} else {
				console.log('输入正确')
				
				setTimeout(() => {
					document.getElementById('emailTip1').innerText = "";
				}, 300)
			}
			// message
			if (this.message == '') {
				document.getElementById('messageTip3').innerText = "*required";
				this.state = true;
				ok = false
			} else {
				setTimeout(() => {
					document.getElementById('messageTip3').innerText = "";
				}, 300)
			}
			
			if (ok == true) {
				
				this.setData()
				// console.log(1111111111)
			}else{
				this.change = false
			}

		},
		
		setData() {
			this.website = window.location.href
			let formData = new FormData();
			formData.append("name", this.name);
			formData.append("product", this.products);
			formData.append("email", this.email);
			formData.append("mobile", this.phone);
			formData.append("address", this.address);
			formData.append("message", this.message);
			formData.append("website", this.website);
			formData.append("ip", this.ip);
			formData.append("country", this.country);
		
			// that.sendState = false;
			
			this.submitMail(formData);
		},
		submitMail(formData){
			console.log()
			axios({
				url: 'https://yiyapi.sgrcr.com/EMAILQuery',
				method: 'POST',
				data: formData,
			}).then((res) => {
				console.log(res)
				this.name = '';
				this.products = '';
				this.email = '';
				this.phone = '';
				this.address = '';
				this.message = '';
				this.website = '';
				this.ip = '';
				this.country = '';
				this.change = false
			})
		},
		swiper(){
			var mySwiper = new Swiper('.swiper-container', {
				autoplay: 5000,//可选选项，自动滑动
				pagination : '.swiper-pagination',
				paginationClickable :true,
			})
		},
		go(item){
			if(typeof(item)=='object'){
				this.$router.push(item.url)
				localStorage.setItem('id',item.id)
				document.documentElement.scrollTop = 0
			}else{
				this.$router.push(item)
				document.documentElement.scrollTop = 0
			}
			
		},
	}
}
</script>

<style lang="scss" scoped>
	.home{
		width:100%;
		.banner_box{
			width:100%;
			height: 8.6rem;
			.banner{
				width:100%;
				height: 8.6rem;
				background: url('../../public/images/banner.png')no-repeat center center;
				background-size:100% 8.6rem ;
				overflow: hidden;
				.title{
					width: 6.8rem;
					// height: 1.4rem;
					margin-top: 2.85rem;
					margin-left: 2.14rem;
					font-size: 0.72rem;
					font-family: Arial;
					font-weight: bold;
					color: #333333;
					line-height: 0.8rem;
				}
				.btn{
					width: 2.02rem;
					height: 0.41rem;
					border: 0.01rem solid #FFFFFF;
					background: linear-gradient(90deg, #C7D2F0 0%, #A2AFD7 100%);
					border-radius: 0.04rem;
					margin-top: 0.45rem;
					margin-left: 2.14rem;
					font-size: 0.22rem;
					font-family: Arial;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 0.41rem;
					text-align: center;
				}
				.text{
					width: 2rem;
					height: 0.41rem;
					font-size: 0.18rem;
					font-family: Arial;
					font-weight: 400;
					color: #666666;
					line-height: 0.24rem;
					margin-top: 0.31rem;
					margin-left: 2.14rem;
				}
			}
			.banner2{
				width:100%;
				height: 8.6rem;
				background: url('../../public/images/banner2.png')no-repeat center center;
				background-size:100% 8.6rem ;
				overflow: hidden;
				.title{
					color: #FFFFFF;
				}
				.text1{
					font-size: 0.48rem;
					font-family: Arial;
					font-weight: bold;
					color: #FFFFFF;
					margin-top: 0.37rem;
					margin-left: 2.14rem;
					span{
						color: #C41010;
					}
				}
				.text{
					color: #FFFFFF;
				}
				.btn{
					width: 1.59rem;
					background: none;
					margin-top: 0.3rem;
					color: #FFFFFF;
				}
			}
			.banner3{
				width:100%;
				height: 8.6rem;
				background: url('../../public/images/banner3.png')no-repeat center center;
				background-size:100% 8.6rem ;
				overflow: hidden;
				.title{
					color: #FFFFFF;
				}
				.text1{
					font-size: 0.48rem;
					font-family: Arial;
					font-weight: bold;
					color: #FFFFFF;
					margin-top: 0.37rem;
					margin-left: 2.14rem;
					span{
						color: #C41010;
					}
				}
				.text{
					width: 4rem;
					color: #FFFFFF;
				}
				
			}
		}
		
		.wrap{
			width:100%;
			.box{
				width: 15.36rem;
				height: 6.7rem;
				margin-top: 0.53rem;
				background:#E1E1E1 url('../../public/images/product_pic.png')no-repeat 8.89rem center;
				background-size:3.08rem  5.02rem;
				transition: background-color 0.2s;
				.title{
					margin-top: 2.47rem;
					margin-left: 1.06rem;
					font-size: 0.32rem;
					font-family: Arial;
					font-weight: bold;
					color: #4D4D4D;
					line-height: 0.36rem;
				}
				.text{
					width: 4rem;
					height: 0.47rem;
					font-size: 0.18rem;
					font-family: Arial;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 0.3rem;
					margin-left: 1.06rem;
					margin-top: 0.35rem;
				}
				.btn{
					margin-top: 0.46rem;
					margin-left: 1.06rem;
					width: 1.39rem;
					height: 0.43rem;
					background: linear-gradient(266deg, #910000, #E51A1A);
					border-radius: 0.06rem;
					font-size: 0.18rem;
					font-family: Arial;
					font-weight: bold;
					color: #FFFFFF;
					text-align: center;
					line-height:0.43rem;
				}
				.btn:hover{
					 cursor:pointer;
				}
			}
			.box:hover{
				background-color:rgba(0, 0, 0, 0.2);
			}
			
			.list_box{
				width: 15.36rem;
				ul{
					li{
						width: 7.52rem;
						height: 6.7rem;
						background: #E1E1E1;
						margin-top: 0.34rem;
						
						.tit{
							width: 100%;
							text-align: center;
							font-size: 0.3rem;
							font-family: Arial;
							font-weight: bold;
							color: #4C4C4C;
							margin-top: 0.7rem;
						}
						.hover_box{
							width: 100%;
							height: 0rem;
							transition:height 0.3s;
							overflow: hidden;
							display: flex;
							flex-direction: column;
							margin-top: 0.4rem;
							.text{
								text-align: center;
								width: 4.35rem;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 0.3rem;
							}
							.btn{
								margin-top: 0.34rem;
								width: 1.39rem;
								height: 0.43rem;
								background: linear-gradient(266deg, #910000, #E51A1A);
								border-radius: 0.06rem;
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: bold;
								color: #FFFFFF;
								text-align: center;
								line-height:0.43rem;
							}
							.btn:hover{
								 cursor:pointer;
							}
							
						}
						
					}
					li:nth-child(1){
						background:#E1E1E1 url('../../public/productImg/product1.png') no-repeat center center;
						background-size:7.52rem  6.7rem;
						transition: background-color 0.2s;
					}
					li:nth-child(1):hover{
						background-color:rgba(0, 0, 0, 0.2);
						.hover_box{
							height: 2rem;
						}
						// opacity: 0.2;
					}
					li:nth-child(2){
						background:#E1E1E1 url('../../public/productImg/product2.png') no-repeat center center;
						background-size:7.52rem  6.7rem;
						transition: background-color 0.2s;
					}
					li:nth-child(2):hover{
						background-color:rgba(0, 0, 0, 0.2);
						.hover_box{
							height: 2rem;
						}
					}
					li:nth-child(3){
						background:#E1E1E1 url('../../public/productImg/product3.png') no-repeat center center;
						background-size:7.52rem  6.7rem;
						transition: background-color 0.2s;
					}
					li:nth-child(3):hover{
						background-color:rgba(0, 0, 0, 0.2);
						.hover_box{
							height: 2rem;
						}
					}
					li:nth-child(4){
						background:#E1E1E1 url('../../public/productImg/product4.png') no-repeat center center;
						background-size:7.52rem  6.7rem;
						transition: background-color 0.2s;
					}
					li:nth-child(4):hover{
						background-color:rgba(0, 0, 0, 0.2);
						.hover_box{
							height: 2rem;
						}
					}
				}
			}
			.base{
				// width: 15.36rem;
				width: 100%;
				background-color:#F5F5F5 ;
				// height: 5rem;
				margin-top: 0.84rem;
				padding-bottom: 1.33rem;
				position: relative;
				.title{
					margin-bottom: 0.5rem;
					width: 15.36rem;
					font-size: 0.4rem;
					font-family: Arial;
					font-weight: bold;
					color: #333333;
				}
				.base_top{
					width:15.36rem;
					margin-bottom: 0.33rem;
					.left{
						font-size: 0.24rem;
						font-family: Arial;
						font-weight: 400;
						color: #666666;
						img{
							margin-left: 0.2rem;
							
						}
					}
					.right{
						font-size: 0.36rem;
						font-family: Arial;
						font-weight: bold;
						color: #666666;
					}
					.right2{
						display: none;
					}
				}
				.base_box{
					width:15.36rem;
					
					.base_left{
						width:7.69rem;
						height: 6.71rem;
						background:url('../../public/images/base.png')no-repeat center center ;
						background-size:7.69rem 6.71rem;
						// margin-right: 57px;
					}
					.base_right{
						width: 7rem;
						height: 5rem;
						.right{
							margin-top: 0.31rem;
							font-size: 0.4rem;
							font-family: Arial;
							font-weight: bold;
							color: #333333;
						}
						.text{
							width: 7rem;
							font-size: 0.24rem;
							font-family: Arial;
							font-weight: bold;
							color: #333333;
							margin-top: 0.4rem;
							padding-bottom: 0.65rem;
							border-bottom: 0.01rem solid #F5F5F5;
							position: relative;
							p{
								position: absolute;
								left: 0;
								bottom: 0;
								width: 1.25rem;
								height: 0.04rem;
								background: #910000;
							}
						}
						.txt{
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #666666;
							line-height: 0.28rem;
							margin-top: 0.53rem;
							p{
								margin-top: 0.2rem;
							}
						}
						
					}
				}
				ul{
					width: 15.33rem;
					height: 2.05rem;
					background: #FFFFFF;
					box-shadow: 0px 0.01rem 0.1rem 0px rgba(4,0,0,0.08);
					position: absolute;
					bottom: -1.33rem;
					// margin-top: 0.41rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
					
					li{
						width: 2.34rem;
						height: 0.85rem;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						border-right: 0.01rem solid #8B8B8B;
						position: relative;
						// border-bottom: 0.01rem solid #E6E6E6;
						// position: relative;
						// padding-top: 0.5rem;
						.ic1{
							position: absolute;
							top: -0.27rem;
							width: 0.39rem;
							height: 0.38rem;
							background: url('../../public/smallImg/ic1.png') no-repeat center center;
							
						}
						.ic2{
							position: absolute;
							top: -0.27rem;
							width: 0.39rem;
							height: 0.38rem;
							background: url('../../public/smallImg/ic2.png') no-repeat center center;
							
						}
						.ic3{
							position: absolute;
							top: -0.27rem;
							width: 0.39rem;
							height: 0.38rem;
							background: url('../../public/smallImg/ic3.png') no-repeat center center;
							background-size:0.34rem 0.39rem;
						}
						.ic4{
							position: absolute;
							top: -0.27rem;
							width: 0.39rem;
							height: 0.38rem;
							background: url('../../public/smallImg/ic4.png') no-repeat center center;
							
						}
						.ic5{
							position: absolute;
							top: -0.27rem;
							width: 0.4rem;
							height: 0.41rem;
							background: url('../../public/smallImg/ic5.png') no-repeat center center;
							background-size:0.4rem 0.41rem;
							
						}
						.ic6{
							position: absolute;
							top: -0.27rem;
							width: 0.39rem;
							height: 0.38rem;
							background: url('../../public/smallImg/ic6.png') no-repeat center center;
							background-size:0.39rem 0.38rem;
						}
						.ic7{
							position: absolute;
							top: -0.27rem;
							width: 0.39rem;
							height: 0.38rem;
							background: url('../../public/smallImg/ic7.png') no-repeat center center;
							background-size:0.39rem 0.38rem;
						}
						.name{
							width: 1.64rem;
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #666666;
							margin-top: 0.16rem;
							text-align: center;
						}
						.num1{
							font-size: 0.3rem;
							font-family: Arial;
							font-weight: 400;
							color: #910000 !important;
							margin-top: 0.21rem;
						}
						.num{
							font-size: 0.3rem;
							font-family: Arial;
							font-weight: 400;
							color: #910000;
							margin-top: 0.4rem;
						}
						.treetop1{
							color: #910000 !important;
						}
						.treetop{
							position: absolute;
							right: 0;
							font-size: 0.36rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #999999;
							top: 0.3rem;
						}
					}
					li:last-child{
						border: none;
					}
				}
				// li{
				// 	width: 100%;
				// 	height: 1.21rem;
				// 	border-bottom: 0.01rem solid #E6E6E6;
				// 	position: relative;
				// 	padding-top: 0.5rem;
				// 	.name{
				// 		font-size: 0.24rem;
				// 		font-family: Arial;
				// 		font-weight: 400;
				// 		color: #666666;
				// 		margin-top: 0.83rem;
				// 	}
				// 	.num1{
				// 		font-size: 1rem;
				// 		font-family: Arial;
				// 		font-weight: 400;
				// 		color: #910000 !important;
				// 		margin-top: 0.21rem;
				// 	}
				// 	.num{
				// 		font-size: 1rem;
				// 		font-family: Arial;
				// 		font-weight: 400;
				// 		color: #999999;
				// 		margin-top: 0.21rem;
				// 	}
				// 	.treetop1{
				// 		color: #910000 !important;
				// 	}
				// 	.treetop{
				// 		position: absolute;
				// 		right: 0;
				// 		font-size: 0.36rem;
				// 		font-family: Microsoft YaHei;
				// 		font-weight: 400;
				// 		color: #999999;
				// 		top: 0.3rem;
				// 	}
				// }
			}
			.form_box{
				width: 15.36rem;
				margin-top: 2.57rem;
				margin-bottom: 1.41rem;
				.left{
					width:5.43rem;
					.title{
						font-size: 0.4rem;
						font-family: Arial;
						font-weight: bold;
						color: #333333;
						margin-bottom: 0.44rem;
						margin-left: 0.03rem;
					}
					.left_btm{
						width:5.43rem;
						.small_box{
							width:5.43rem;
							height: 1.63rem;
							border: 0.01rem solid #E0DEE3;
							margin-top: 0.07rem;
							img{
								width:0.64rem;
								height: 0.54rem;
								margin-left: 0.77rem;
								margin-right: 0.65rem;
							}
							.text{
								font-size: 0.3rem;
								font-family: Arial;
								font-weight: 400;
								color: #666666;
								.line{
									display: flex;
									p{
										width: 0.25rem;
										height: 0.01rem;
										background-color: #E0DEE3;
										margin-top: 0.23rem;
										margin-right: 0.09rem;
										
									}
									span:nth-child(2){
										font-size: 0.16rem;
										font-family: Arial;
										font-weight: 400;
										color: #666666;
										margin-top: 0.15rem;
									}
								}
								
							}
						}
						.small_box:nth-child(1){
							margin-top: 0;
							background-color: #2E3044;
							
							.text{
								color: #FFFFFF;
								.line{
									span{
										color: #FFFFFF;
									}
								}
								
							}
						}
					}
				}
				.right{
					width: 8.64rem;
					height: 1.35rem;
					.title{
						font-size: 0.36rem;
						font-family: Arial;
						font-weight: bold;
						color: #333333;
					}
					.form{
						width: 8.64rem;
						margin-top: 0.54rem;
						.name_phone{
							width: 8.64rem;
							margin-bottom: 0.24rem;
							
							.input_box{
								position: relative;
								
								input{
									width: 4.04rem;
									height: 0.56rem;
									background: #F8F8F8;
									border: 0.01rem solid #CCCCCC;
									padding-left: 0.16rem;
									font-size: 0.18rem;
									font-family: Microsoft YaHei;
									font-weight: 400;
									background-color: #F8F8F8;
									
								}
								#emailTip1{
									position: absolute;
									left: 0.18rem;
									bottom: -0.19rem;
									color: #910000;
									font-size: 0.16rem;
									text-align: left;
								}
								#mobileTip3{
									position: absolute;
									left: 0.18rem;
									bottom: -0.19rem;
									color: #910000;
									font-size: 0.16rem;
									text-align: left;
								}
								#productTip3{
									position: absolute;
									left: 0.18rem;
									bottom: -0.19rem;
									color: #910000;
									font-size: 0.16rem;
									text-align: left;
								}
								#nameTip3{
									position: absolute;
									left: 0.18rem;
									bottom: -0.19rem;
									color: #910000;
									font-size: 0.16rem;
									text-align: left;
								}
								
							}
							
						}
						.ipt{
							width:8.48rem;
							height: 0.56rem;
							background: #F8F8F8;
							border: 0.01rem solid #CCCCCC;
							padding-left: 0.16rem;
							font-size: 0.18rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							background-color: #F8F8F8;
						}
						input::-webkit-input-placeholder{
							color: #CCCCCC;
						}
						input::-moz-placeholder{
							color: #CCCCCC;
						}
						input:-moz-placeholder{
							color: #CCCCCC;
						}
						input:-ms-input-placeholder{
							color: #CCCCCC;
						}
						.input_box{
							position: relative;
							textarea{
								resize:none;
								width: 8.48rem;
								height: 1.12rem;
								background: #F8F8F8;
								border: 0.01rem solid #CCCCCC;
								margin-top:0.24rem ;
								padding-left: 0.16rem;
								padding-top:0.23rem ;
								font-size: 0.18rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #000;
							}
							#messageTip3{
								position: absolute;
								left: 0.18rem;
								bottom: -0.19rem;
								color: #910000;
								font-size: 0.16rem;
								text-align: left;
							}
						}
						
						textarea::-webkit-input-placeholder{
							color: #CCCCCC;
						}
						textarea::-moz-placeholder{
							color: #CCCCCC;
						}
						textarea:-moz-placeholder{
							color: #CCCCCC;
						}
						textarea:-ms-input-placeholder{
							color: #CCCCCC;
						}
						
					}
					.btn{
						width: 1.32rem;
						height: 0.56rem;
						margin-top: 0.51rem;
						background:#2E3044 url('../../public/smallImg/letter.png') no-repeat 1.22rem center;
						font-size: 0.2rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 0.56rem;
						padding-left: 0.35rem;
					}
					.btn:hover{
						 cursor:pointer;
					}
					.btn1{
						width: 1.32rem;
						height: 0.56rem;
						margin-top: 0.51rem;
						background:#2E3044 url('../../public/smallImg/ass.gif') no-repeat 1.22rem center;
						background-size: 0.5rem  0.5rem;
						font-size: 0.2rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 0.56rem;
						padding-left: 0.35rem;
					}
				}
			}
		}
	// 移动端 样式
		@media (max-width:1024px){
			.banner_box{
				width:100%;
				height: 10.85rem;
				.banner{
					width:100%;
					height: 10.85rem;
					background: url('../../public/images/mobileBanner.png')no-repeat center center;
					background-size:100% 10.85rem ;
					overflow: hidden;
					.title{
						width: 5.81rem;
						// height: 1.4rem;
						margin-top: 1.46rem;
						margin-left: 0.83rem;
						font-size: 0.61rem;
						font-family: Arial;
						font-weight: bold;
						color: #333333;
						line-height: 0.8rem;
					}
					.btn{
						width: 2.02rem;
						height: 0.41rem;
						border: 0.01rem solid #FFFFFF;
						background: linear-gradient(90deg, #C7D2F0 0%, #A2AFD7 100%);
						border-radius: 0.04rem;
						margin-top: 0.26rem;
						margin-left:0.83rem;
						font-size: 0.22rem;
						font-family: Arial;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 0.41rem;
						text-align: center;
					}
					.text{
						width: 2rem;
						height: 0.41rem;
						font-size: 0.18rem;
						font-family: Arial;
						font-weight: 400;
						color: #666666;
						line-height: 0.24rem;
						margin-top: 0.21rem;
						margin-left:0.83rem;
					}
				}
				.banner2{
					background: url('../../public/images/mobileBanner2.png')no-repeat center center;
					background-size:100% 10.85rem ;
					overflow: hidden;
					.title{
						color: #FFFFFF;
					}
					.text1{
						font-size: 0.48rem;
						font-family: Arial;
						font-weight: bold;
						color: #FFFFFF;
						margin-top: 0.3rem;
						margin-left:0.83rem;
						span{
							color: #C41010;
						}
					}
					.text{
						color: #FFFFFF;
					}
					.btn{
						width: 1.59rem;
						background: none;
						margin-top: 0.37rem;
						color: #FFFFFF;
					}
				}
				.banner3{
					background: url('../../public/images/mobileBanner3.png')no-repeat center center;
					background-size:100% 10.85rem ;
					overflow: hidden;
					.title{
						color: #FFFFFF;
					}
					.text1{
						font-size: 0.48rem;
						font-family: Arial;
						font-weight: bold;
						color: #FFFFFF;
						margin-top: 0.37rem;
						margin-left: 2.14rem;
						span{
							color: #C41010;
						}
					}
					.text{
						width: 4rem;
						color: #FFFFFF;
					}
					
				}
			}
			
			
			.wrap{
				width:100%;
				.box{
					width: 5.8rem;
					height: 2.53rem;
					margin-top: 0.8rem;
					background:#E1E1E1 url('../../public/images/product_pic.png')no-repeat 3.89rem center;
					background-size:1.08rem  2.02rem;
					transition: background-color 0.2s;
					.title{
						margin-top: 0.41rem;
						margin-left: 0.4rem;
						font-size: 0.32rem;
						font-family: Arial;
						font-weight: bold;
						color: #4D4D4D;
						line-height: 0.36rem;
					}
					.text{
						width: 4rem;
						height: 0.47rem;
						font-size: 0.18rem;
						font-family: Arial;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 0.3rem;
						margin-left: 0.4rem;
						margin-top: 0.26rem;
					}
					.btn{
						margin-top: 0.3rem;
						margin-left: 0.4rem;
						width: 1.39rem;
						height: 0.43rem;
						background: linear-gradient(266deg, #910000, #E51A1A);
						border-radius: 0.06rem;
						font-size: 0.18rem;
						font-family: Arial;
						font-weight: bold;
						color: #FFFFFF;
						text-align: center;
						line-height:0.43rem;
					}
				}
				.box:hover{
					background-color:rgba(0, 0, 0, 0.2);
				}
				
				.list_box{
					width: 5.8rem;
					ul{
						li{
							width: 2.85rem;
							height: 2.71rem;
							background: #E1E1E1;
							margin-top: 0.14rem;
							
							.tit{
								width: 100%;
								text-align: center;
								font-size: 0.23rem;
								font-family: Arial;
								font-weight: bold;
								color: #4C4C4C;
								margin-top: 0.31rem;
							}
							.hover_box{
								width: 100%;
								height: 2rem;
								transition:height 0.3s;
								overflow: block;
								display: flex;
								flex-direction: column;
								margin-top: 0.4rem;
								.text{
									text-align: center;
									width: 4.35rem;
									font-size: 0.16rem;
									font-family: Arial;
									font-weight: 400;
									color: #FFFFFF;
									line-height: 0.3rem;
								}
								.btn{
									margin-top: 0.34rem;
									width: 1.39rem;
									height: 0.43rem;
									background: linear-gradient(266deg, #910000, #E51A1A);
									border-radius: 0.06rem;
									font-size: 0.18rem;
									font-family: Arial;
									font-weight: bold;
									color: #FFFFFF;
									text-align: center;
									line-height:0.43rem;
								}
							}
						}
						li:nth-child(1){
							background:#E1E1E1 url('../../public/productImg/product1.png') no-repeat center center;
							background-size:2.85rem  2.71rem;
							transition: background-color 0.2s;
						}
						li:nth-child(1):hover{
							background-color:rgba(0, 0, 0, 0.2);
						}
						li:nth-child(2){
							background:#E1E1E1 url('../../public/productImg/product2.png') no-repeat center center;
							background-size:2.85rem  2.71rem;
							transition: background-color 0.2s;
						}
						li:nth-child(2):hover{
							background-color:rgba(0, 0, 0, 0.2);
						}
						li:nth-child(3){
							background:#E1E1E1 url('../../public/productImg/product3.png') no-repeat center center;
							background-size:2.85rem  2.71rem;
							transition: background-color 0.2s;
						}
						li:nth-child(3):hover{
							background-color:rgba(0, 0, 0, 0.2);
						}
						li:nth-child(4){
							background:#E1E1E1 url('../../public/productImg/product4.png') no-repeat center center;
							background-size:2.85rem  2.71rem;
							transition: background-color 0.2s;
						}
						li:nth-child(4):hover{
							background-color:rgba(0, 0, 0, 0.2);
						}
					}
				}
				.base{
					width: 5.8rem;
					// height: 5rem;
					margin-top: 0.8rem;
					.title{
						margin-bottom: 0.54rem;
						width: 5.8rem;
						font-size: 0.4rem;
						font-family: Arial;
						font-weight: bold;
						color: #333333;
					}
					.base_top{
						width: 5.8rem;
						margin-bottom: 0.33rem;
						flex-direction: column;
						.left{
							font-size: 0.24rem;
							font-family: Arial;
							font-weight: 400;
							color: #666666;
							img{
								margin-left: 0.2rem;
								
							}
						}
						.right{
							display: none;
						}
						.right2{
							font-size: 0.29rem;
							font-family: Arial;
							font-weight: bold;
							color: #666666;
							margin-bottom: 0.2rem;
							display: flex;
							flex-direction: column;
							
							span:nth-child(2){
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: 400;
								color: #666666;
								margin-top: 0.2rem;
							}
						}
					}
					.base_box{
						width: 5.8rem;
						flex-direction: column;
						
						.base_left{
							width:5.8rem;
							height: 4.44rem;
							background:url('../../public/images/base.png')no-repeat center center ;
							background-size: 8.04rem 6.11rem;
							// margin-right: 57px;
						}
						.base_right{
							width:5.8rem;
							height: 100%;
							.right{
								margin-top: 0.31rem;
								font-size: 0.4rem;
								font-family: Arial;
								font-weight: bold;
								color: #333333;
							}
							.text{
								width:5.8rem;
								text-align: right;
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: 400;
								color: #666666;
								margin-bottom: 0.65rem;
								display: none;
							}
							li{
								width: 100%;
								height: 1.21rem;
								border-bottom: 0.01rem solid #E6E6E6;
								position: relative;
								padding-top: 0.5rem;
								.name{
									font-size: 0.24rem;
									font-family: Arial;
									font-weight: 400;
									color: #666666;
									margin-top: 0.83rem;
								}
								.num1{
									font-size: 1rem;
									font-family: Arial;
									font-weight: 400;
									color: #910000 !important;
									margin-top: 0.21rem;
								}
								.num{
									font-size: 1rem;
									font-family: Arial;
									font-weight: 400;
									color: #999999;
									margin-top: 0.21rem;
								}
								.treetop1{
									color: #910000 !important;
								}
								.treetop{
									position: absolute;
									right: 0;
									font-size: 0.36rem;
									font-family: Microsoft YaHei;
									font-weight: 400;
									color: #999999;
									top: 0.3rem;
								}
							}
						}
					}
					ul{
						width: 5.77rem;
						height: 7.05rem;
						background: #FFFFFF;
						box-shadow: 0px 0.01rem 0.1rem 0px rgba(4,0,0,0.08);
						position: absolute;
						bottom: -6.33rem;
						// margin-top: 0.41rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex-wrap: wrap;
						
						li{
							width: 2.34rem;
							height: 0.85rem;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							border-right: 0.01rem solid #8B8B8B;
							position: relative;
							// border-bottom: 0.01rem solid #E6E6E6;
							// position: relative;
							// padding-top: 0.5rem;
							.ic1{
								position: absolute;
								top: -0.27rem;
								width: 0.39rem;
								height: 0.38rem;
								background: url('../../public/smallImg/ic1.png') no-repeat center center;
								
							}
							.ic2{
								position: absolute;
								top: -0.27rem;
								width: 0.39rem;
								height: 0.38rem;
								background: url('../../public/smallImg/ic2.png') no-repeat center center;
								
							}
							.ic3{
								position: absolute;
								top: -0.27rem;
								width: 0.39rem;
								height: 0.38rem;
								background: url('../../public/smallImg/ic3.png') no-repeat center center;
								background-size:0.34rem 0.39rem;
							}
							.ic4{
								position: absolute;
								top: -0.27rem;
								width: 0.39rem;
								height: 0.38rem;
								background: url('../../public/smallImg/ic4.png') no-repeat center center;
								
							}
							.ic5{
								position: absolute;
								top: -0.27rem;
								width: 0.4rem;
								height: 0.41rem;
								background: url('../../public/smallImg/ic5.png') no-repeat center center;
								background-size:0.4rem 0.41rem;
								
							}
							.ic6{
								position: absolute;
								top: -0.27rem;
								width: 0.39rem;
								height: 0.38rem;
								background: url('../../public/smallImg/ic6.png') no-repeat center center;
								background-size:0.39rem 0.38rem;
							}
							.ic7{
								position: absolute;
								top: -0.27rem;
								width: 0.39rem;
								height: 0.38rem;
								background: url('../../public/smallImg/ic7.png') no-repeat center center;
								background-size:0.39rem 0.38rem;
							}
							.name{
								width: 1.64rem;
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: 400;
								color: #666666;
								margin-top: 0.16rem;
								text-align: center;
							}
							.num1{
								font-size: 0.3rem;
								font-family: Arial;
								font-weight: 400;
								color: #910000 !important;
								margin-top: 0.21rem;
							}
							.num{
								font-size: 0.3rem;
								font-family: Arial;
								font-weight: 400;
								color: #910000;
								margin-top: 0.4rem;
							}
							.treetop1{
								color: #910000 !important;
							}
							.treetop{
								position: absolute;
								right: 0;
								font-size: 0.36rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #999999;
								top: 0.3rem;
							}
						}
						li:last-child{
							border: none;
						}
					}
				}
				.form_box{
					width:5.8rem;
					margin-top:6.63rem;
					margin-bottom: 1.41rem;
					flex-direction: column;
					.left{
						width:5.8rem;
						.title{
							font-size: 0.4rem;
							font-family: Arial;
							font-weight: bold;
							color: #333333;
							margin-bottom: 0.44rem;
							margin-left: 0.03rem;
						}
						.left_btm{
							width:5.8rem;
							.small_box{
								width:5.8rem;
								height: 1.63rem;
								border: 0.01rem solid #E0DEE3;
								margin-top: 0.07rem;
								img{
									width:0.64rem;
									height: 0.54rem;
									margin-left: 0.77rem;
									margin-right: 0.65rem;
								}
								.text{
									font-size: 0.3rem;
									font-family: Arial;
									font-weight: 400;
									color: #666666;
									.line{
										display: flex;
										p{
											width: 0.25rem;
											height: 0.01rem;
											background-color: #E0DEE3;
											margin-top: 0.23rem;
											margin-right: 0.09rem;
											
										}
										span:nth-child(2){
											font-size: 0.16rem;
											font-family: Arial;
											font-weight: 400;
											color: #666666;
											margin-top: 0.15rem;
										}
									}
									
								}
							}
							.small_box:nth-child(1){
								margin-top: 0;
								background-color: #2E3044;
								
								.text{
									color: #FFFFFF;
									.line{
										span{
											color: #FFFFFF;
										}
									}
									
								}
							}
						}
					}
					.right{
						margin-top: 0.8rem;
						width:5.8rem;
						height:100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						.title{
							width:5.8rem;
							font-size: 0.36rem;
							font-family: Arial;
							font-weight: bold;
							color: #333333;
						}
						.form{
							width:5.8rem;
							margin-top: 0.54rem;
							.name_phone{
								width:5.8rem;
								margin-bottom: 0rem;
								flex-direction: column;
								input{
									width:5.64rem !important;
									height: 0.56rem;
									background: #F8F8F8;
									border: 0.01rem solid #CCCCCC;
									padding-left: 0.16rem;
									font-size: 0.18rem;
									font-family: Microsoft YaHei;
									font-weight: 400;
									background-color: #F8F8F8;
									margin-bottom: 0.2rem;
								}
							}
							.ipt{
								width:5.64rem;
								height: 0.56rem;
								background: #F8F8F8;
								border: 0.01rem solid #CCCCCC;
								padding-left: 0.16rem;
								font-size: 0.18rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								background-color: #F8F8F8;
							}
							input::-webkit-input-placeholder{
								color: #CCCCCC;
							}
							input::-moz-placeholder{
								color: #CCCCCC;
							}
							input:-moz-placeholder{
								color: #CCCCCC;
							}
							input:-ms-input-placeholder{
								color: #CCCCCC;
							}
							textarea{
								resize:none;
								width:5.64rem !important;
								height: 1.12rem;
								background: #F8F8F8;
								border: 0.01rem solid #CCCCCC;
								margin-top:0.24rem ;
								padding-left: 0.16rem;
								padding-top:0.23rem ;
								font-size: 0.18rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #CCCCCC;
							}
							textarea::-webkit-input-placeholder{
								color: #CCCCCC;
							}
							textarea::-moz-placeholder{
								color: #CCCCCC;
							}
							textarea:-moz-placeholder{
								color: #CCCCCC;
							}
							textarea:-ms-input-placeholder{
								color: #CCCCCC;
							}
						}
						.btn{
							width: 1.32rem;
							height: 0.56rem;
							margin-top: 0.51rem;
							background:#2E3044 url('../../public/smallImg/letter.png') no-repeat 1.22rem center;
							background-size: 0.24rem 0.18rem; 
							font-size: 0.2rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 0.56rem;
							padding-left: 0.35rem;
						}
					}
				}
			}
		}
	
	
	}
</style>