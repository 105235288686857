<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features :pplicableIofo='pplicableIofo' :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<product-appearance :appearanceList='appearanceList'></product-appearance>
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<support-term :termList='termList' :tobel_bom='tobel_bom'></support-term>
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'12V-HP',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/images/96V-HP.png'),
					title:'96V-HP Series Low Frequency Pure Sine Wave Inverter/Charger+Controller',
					info:'Pure sine wave output. THD ＜3%. The conventional conversion time is 6ms. It can hold 300% overload within 20s, providing necessary current for starting electrical appliances with high requirements. HP series is capable of starting and stopping the generator, and realizing the function of monitoring the equipment operation status and battery temperature etc.by mobile through additional communication modules.',
					id:'1'
				},
				productInfo:[
					{
						title:'Operating voltage',
						list:[
							{
								info:" single phase 100-110-120V/  220-230-240V  ",
								icon:'',
							},{
								info:' split phase 120V/240V （Models above 3KW）',
								icon:'',
							}
						]
					},
					{
						title:'Operating frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							},{
								info:"Recreation vehicles",
								icon:require('../../public/images/cart.png'),
							},
							{
								info:"Heavy duty truck",
								icon:require('../../public/images/bigCart.png'),
							},
							{
								info: "Yacht",
								icon:require('../../public/images/ship.png'),
							},
							{
								info:"Engineering vehicle",
								icon:require('../../public/images/evCart.png'),
							}
						]
					},
					{
						title:'Range of power',
						list:[
							
							{
								info:"6.0KW",
								icon:'',
							},{
								info:"8.0KW",
								icon:'',
							},
							{
								info:"10.0KW",
								icon:'',
							},
							{
								info: "12.0KW",
								icon:'',
							},
							{
								info:"15.0KW",
								icon:'',
							},
							{
								info:"18.0KW",
								icon:'',
							},
							
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/shield.png'),
						text:'Multiple protection for safer use',
					},
					{
						icon:require('../../public/images/line.png'),
						text:'Real pure sine wave output to protect sensitive loads',
					},
					{
						icon:require('../../public/images/engine.png'),
						text:'Generator automatic start/stop function',
					},
					{
						icon:require('../../public/images/temperature.png'),
						text:'Battery temperature monitoring function',
					},
					{
						icon:require('../../public/images/charge.png'),
						text:'Adaptable to the charging voltage of different types of batteries',
					},
					{
						icon:require('../../public/images/line.png'),
						text:'THD<3% under standard load',
					}
					
				],
				pplicableIofo:[
					{
						title:'HP 2-18KW',
						list:[
							{
								pic:require('../../public/smallImg/fridge.png'),
								text:'Fridge'
							},
							{
								pic:require('../../public/smallImg/tv.png'),
								text:'TV'
							},
							{
								pic:require('../../public/smallImg/loudspeaker.png'),
								text:'Loudspeaker'
							},
							{
								pic:require('../../public/smallImg/coffee.png'),
								text:'Coffee machine'
							},
							{
								pic:require('../../public/smallImg/air.png'),
								text:'Air conditioner '
							},
							{
								pic:require('../../public/smallImg/washing.png'),
								text:'Washing machine'
							},
							{
								pic:require('../../public/smallImg/microwave.png'),
								text:'Microwave Oven'
							},
							{
								pic:require('../../public/smallImg/heater.png'),
								text:'heater'
							}
						]
					}
				],
				appearanceList:[
					
					{
						pic:require('../../public/images/48V-HP1.png'),
						text:'96V-HP 12.0KW'
					},
					{
						pic:require('../../public/images/48V-HP2.png'),
						text:'96V-HP 12.0KW'
					},
					{
						pic:require('../../public/images/48V-HP3.png'),
						text:'96V-HP 15.0KW/18.0KW'
					},
					{
						pic:require('../../public/images/48V-HP4.png'),
						text:'96V-HP 15.0KW/18.0KW'
					}
				],
				priceInfo:[						//技术参数信息
					{
						name:'Inverter Output',
						list:[
							{
								parameterName:'Model',
								parameterList:['HP 8.0KW','HP 10.0KW','HP 12.0KW','HP 15.0KW','HP 18.0KW']
							},
							{
								parameterName:'AC Output Operating Voltage Options',
								parameterList:['100-110-120Vac/ 220-230-240Vac']
							},
							{
								parameterName:'Continuous Output Power',
								parameterList:['8.0KW','10.0KW','12.0KW','15.0KW','18.0KW']
							},
							{
								parameterName:'Surge Rating (20s)',
								parameterList:['8.0KW','10.0KW','12.0KW','15.0KW','18.0KW']
							},
							{
								parameterName:'Output Frequency',
								parameterList:['24.0KW','30.0KW','36.0KW','45.0KW','54.0KW']
							},
							{
								parameterName:'Nominal Efficiency',
								parameterList:['>88% (Peak)']
							},
							{
								parameterName:'Line Mode Efficiency',
								parameterList:['>95%']
							},
							{
								parameterName:'Power Factor',
								parameterList:['0.9-1.0']
							},
							{
								parameterName:'Output Voltage Regulation',
								parameterList:['±10% RMS']
							},
							{
								parameterName:'Typical transfer Time',
								parameterList:['10ms ( Max )']
							},
							{
								parameterName:'THD',
								parameterList:['< 3% (Rated battery level, rated full linear load)']
							}
							
						]
					},
					{
						name:'DC Input',
						list:[
							{
								parameterName:'Nominal Input Voltage',
								parameterList:['96.0Vdc']
							},
							{
								parameterName:'Minimum Start Voltage',
								parameterList:['80Vdc/ 84.0Vdc']
							},
							{
								parameterName:'Low Battery Voltage Recover',
								parameterList:['84.0Vdc/ 88.0Vdc']
							},
							{
								parameterName:'Idle Consumption-Search Mode',
								parameterList:['< 25W When Power Saver On.']
							},
						]
					},
					{
						name:'Charger',
						list:[
							{
								parameterName:'Output Voltage',
								parameterList:['Depends on battery type']
							},
							{
								parameterName:'Max Charge Current',
								parameterList:['']
							}
						]
					},
					{
						name:'Bypass & Protection',
						list:[
							{
								parameterName:'Max Input AC Voltage',
								parameterList:['150Vac For 120Vac LV Mode； 300Vac For 230Vac HV Mode:']
							},
							{
								parameterName:'Nominal Input Frequency',
								parameterList:['50Hzor60Hz']
							},
							{
								parameterName:'Over load protection (SMPS Ioad)',
								parameterList:['Circuit Breaker']
							},
							{
								parameterName:'Output Short Circuit Protection',
								parameterList:['Circuit Breaker']
							},
							{
								parameterName:'Bypass Breaker Rating',
								parameterList:['50A','80A','80A','100A','125A']
							},
							{
								parameterName:'Bypass Without Battery Connected',
								parameterList:['80Amp for UL','100Amp for UL']
							},
							{
								parameterName:'Max Bypass Current',
								parameterList:['80Amp','80Amp']
							}
						]
					},
					{
						name:'Mechanical Specifications',
						list:[
							{
								parameterName:'Mounting',
								parameterList:['Wall Mount']
							},
							{
								parameterName:"Dimensions (mm / inch)",
								parameterList:['415*623*200 / 16.3*24.5*7.9','415*742*213 / 16.3*29.2*8.4']
							},
							{
								parameterName:'Net Weight (kg)',
								parameterList:['66','68','73','81.6','87.6']
							},
							{
								parameterName:'Display',
								parameterList:['Status LEDs / Status LEDs+LCD']
							},
							
						]
					}
				],
				termList:[						//支持项数据
					{
						title:'Gel USA',
						fast:'14.0',
						float:'13.7'
					},
					{
						title:'AGM 1',
						fast:'14.1',
						float:'13.4'
					},
					{
						title:'Lithium',
						fast:'13.8',
						float:'13.6'
					},
					{
						title:'Sealed Lead Acid',
						fast:'14.4',
						float:'13.6'
					},
					{
						title:'Gel EURO',
						fast:'14.4',
						float:'13.8'
					},
					{
						title:'Open Lead Acid',
						fast:'14.0',
						float:'13.8'
					},
					{
						title:'LifePO4',
						fast:'15.5 (4 Hours then Off)',
						float:''
					},
					{
						title:'De-sulphation',
						fast:'13.6',
						float:'13.5'
					}
				],
				tobel_bom:'For 12Vdc Mode Series(*2 for 24Vdc Mode /  *4 for 48Vdc Mode /  *8 for 96Vdc Mode)',
				otherList:[
					{
						img:require('../../public/otherImg/1000V-UP-S.png'),
						url:'/1000V-UP-S'
					},
					{
						img:require('../../public/otherImg/12V-HP.png'),
						url:'/12V-HP'
					},
					{
						img:require('../../public/otherImg/24V-HPV.png'),
						url:'/24V-HPV'
					},
					{
						img:require('../../public/otherImg/40A-MPPT.png'),
						url:'/40A-MPPT'
					}
				]
			}
			
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
