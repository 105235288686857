<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'productInfo',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/images/1000V-UP-M.png'),
					title:'1000V-UP-M Power Conversion Module',
					info:`
						UP-M series energy storage converter module adopts modular design, 
						which can be used by a single unit alone or multiple units 
						in parallel, with constant voltage, constant current and constant 
						power control modes, and can be applied in industrial and commercial
						energy storage systems, with bidirectional (rectifier and inverter) as 
						the basic feature, supporting grid-connected and off-grid operation
						, as well as reactive power compensation and harmonic compensation
						functions. It can meet the needs of different application scenarios
						and is flexible in terms of installation and use.
					`,
					id:'0'
				},
				productInfo:[
					{
						title:'Operating Voltage',
						list:[
							{
								info:" DC 600~900V",
								icon:'',
							},
							{
								info:"  AC 380V （3P4W）",
								icon:'',
							}
							
						]
					},
					{
						title:'Operating Frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application Scenario',
						list:[
							{
								info:"Factory",
								icon:require('../../public/images/scale.png'),
							},
							{
								info:"Commercial building",
								icon:require('../../public/images/as2.png'),
							}
						]
					},
					{
						title:'Range Of Power',
						list:[
							{
								info:"30KW",
								icon:'',
							},
							{
								info:"62.5KW",
								icon:'',
							},
							{
								info:"100KW",
								icon:'',
							}
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/pf5.png'),
						text:'Efficiency up to 97.5%',
					},
					{
						icon:require('../../public/images/pf7.png'),
						text:'Support with EMS system local control',
					},
					{
						icon:require('../../public/images/pf8.png'),
						text:'Modular design, easy to maintain and expand',
					},
					{
						icon:require('../../public/images/pf9.png'),
						text:'Bidirectional power conversion',
					}
				],
				priceInfo:[						//技术参数信息
					{
						name:'',
						list:[
							{
								parameterName:'Model',
								parameterList:['30KW','62.5KW','100KW ']
							}
						]
					},
					{
						name:'Utility-interactive Mode',
						list:[
							{
								parameterName:'Battery Voltage Range',
								parameterList:['600~900V']
							},
							{
								parameterName:'DC max current',
								parameterList:['50A','100A','170A']
							},
							{
								parameterName:'AC voltage',
								parameterList:['380V±15%']
							},
							{
								parameterName:'Max. AC current ',
								parameterList:['100A ','200A ','400A ']
							},
							{
								parameterName:'Nominal power',
								parameterList:['30KW  ','62.5KW ','100KW ']
							},
							{
								parameterName:'AC frequency',
								parameterList:['50Hz/60Hz±2.5Hz']
							},
							{
								parameterName:'THDi',
								parameterList:['≤3%']
							},
							{
								parameterName:'AC PF',
								parameterList:['-1~+1']
							}
						]
					},
					{
						name:'Stand-alone Mode',
						list:[
							{
								parameterName:'Battery voltage range',
								parameterList:['650~950V']
							},
							{
								parameterName:'DC Max Current',
								parameterList:['50A ','220A ','440A ']
							},
							{
								parameterName:'AC output voltage',
								parameterList:['380±1%']
							},
							{
								parameterName:'Max. AC output curren',
								parameterList:['50A','100A','170A']
							},
							{
								parameterName:'Nominal AC output power ',
								parameterList:['30KW  ','62.5KW ','100KW ']
							},
							{
								parameterName:'AC max power',
								parameterList:['33KW  ','68.75KW ','110KW ']
							},
							{
								parameterName:'Output THDu',
								parameterList:['< 3 % (Linear load)']
							},
							{
								parameterName:'AC Frequency ',
								parameterList:['50Hz/60Hz±0.2%']
							},
							{
								parameterName:'AC PF',
								parameterList:['-1~+1']
							},
							{
								parameterName:'Overload Capability',
								parameterList:['110%：10min ; 120%：1min']
							}
						]
					},
					{
						name:'Other',
						list:[
							{
								parameterName:'Peak efficiency',
								parameterList:['97.30% ']
							},
							{
								parameterName:'Protection',
								parameterList:['Overtemperature protection, AC over/under voltage protection, Over/under frequency protection,Emergency power off, AC phase reverse, Fan/relay failure, Over/under load protection, Ground faultcircuit Interrupter, Anti-islanding']
							},
							{
								parameterName:'AC connection',
								parameterList:['3P4W']
							},
							{
								parameterName:'Display',
								parameterList:['7 "color touch screen (optional)(External connection)']
							},
							{
								parameterName:'Communication',
								parameterList:['RS485/CAN/ModBusTCP/IP/CAN/LAN ']
							},
							{
								parameterName:'Isolation ',
								parameterList:['Built-in Transformer','Transformer']
							}
						]
					},
					{
						name:'Physical',
						list:[
							{
								parameterName:'Cooling',
								parameterList:['Forced air cooling']
							},
							{
								parameterName:'Noise',
								parameterList:['≤70dB']
							},
							{
								parameterName:'Enclosure',
								parameterList:['IP20']
							},
							{
								parameterName:'Max elevation',
								parameterList:['3000m/10000feet (>2000m/6500feet derating)']
							},
							{
								parameterName:'Operating ambient temperature',
								parameterList:['﹣20℃～50℃(＞45℃derating)']
							},
							{
								parameterName:'Humidity ',
								parameterList:['0～95%(No condensing)']
							},
							{
								parameterName:'Size (W×H×D) ',
								parameterList:['850*2400*1600mm']
							},
							{
								parameterName:'Weight',
								parameterList:['/','/','/']
							},
							{
								parameterName:'Low Voltage Disconnect',
								parameterList:['21.0-25.0Vdc']
							}
						]
					}
				],
				otherList:[
					{
						img:require('../../public/otherImg/1000V-UP-S.png'),
						url:'/1000V-UP-S'
					},
					{
						img:require('../../public/otherImg/1000V-UPV-S.png'),
						url:'/1000V-UPV-S'
					}
				]
			}
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
