<template>
	<!-- 服务页 -->
	<div class="giveServiceTo flex_box_column_cen">
		<!-- 头部 -->
		<yiy-header></yiy-header>
		<div class="banner">
			<div class="title">
				SERVE EVERY CUSTOMER ATTENTIVELY
			</div>
			<div class="cont">
				Some frequently asked questions and answers If you don't find relevant questions, you canleave a message and we will answer you as
			</div>
		</div>
		<div class="wrap">
			<ul class="flex_box">
				<li :class="item.id==id?'li1':''" v-for="(item,index) in list" :key='index' @click="id=item.id">{{item.title}}</li>
			</ul>
			<installation-video v-show='id==1'></installation-video>
			<download-document v-show="id==2"></download-document>
			<yiy-faq v-show='id==3'></yiy-faq>
		</div>
		<!-- 底部 -->
		<new-footer></new-footer>
		<right-frame></right-frame>
	</div>
</template>

<script>
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import InstallationVideo from '@/components/InstallationVideo/InstallationVideo.vue'			//视频组件
	import DownloadDocument from '@/components/DownloadDocument/DownloadDocument.vue'				//下载组件
	import yiyFaq from '@/components/FAQ/FAQ.vue'													
	
	
	export default {
		metaInfo:{
			title:'giveServiceTo',
			meta:[{
				name:'keyWords',
				content:'关键词1,关键词2'
			}]
		},
		components:{
			yiyHeader,
			newFooter,
			rightFrame,
			InstallationVideo,
			DownloadDocument,
			yiyFaq
		},
		data() {
			return{
				id:'',
				list:[
					{
						id:1,
						title:'Installation video'
					},
					{
						id:2,
						title:'Download Document'
					},
					{
						id:3,
						title:'FAQ'
					},
				]
			}
		},
		created() {
			
			this.id = localStorage.getItem('id')
			console.log(this.id)
			// localStorage.removeItem('id')
		}
	}
</script>

<style lang="scss" scoped>
	.giveServiceTo{
		width:100%;
		.banner{
			width:100%;
			height: 8.6rem;
			background: url('../../public/images/banner8.png')no-repeat center center;
			background-size: 100% 8.6rem;
			.title{
				width: 8.2rem;
				height: 1.25rem;
				font-size: 0.64rem;
				font-family: Arial;
				font-weight: bold;
				color: #333333;
				margin-top: 3.09rem;
				margin-left: 1.96rem;
			}
			.cont{
				width: 4.52rem;
				height: 0.75rem;
				font-size: 0.16rem;
				font-family: Arial;
				font-weight: 400;
				color: #333333;
				line-height: 0.3rem;
				margin-left: 1.96rem;
				margin-top: 0.65rem;
			}
		}
		.wrap{
			width: 15.36rem;
			ul{
				width: 15.36rem;
				border-bottom:0.01rem solid #E0D6CC;
				margin-top: 1.14rem;
				margin-bottom: 0.6rem;
				
				li{
					padding-top: 0.19rem;
					padding-bottom: 0.19rem;
					font-size: 0.24rem;
					font-family: Arial;
					font-weight: bold;
					color: #808080;
					margin-right: 0.84rem;
				}
				.li1{
					color: #1A1A1A;
					border-bottom: 0.03rem solid #910000;
				}
			}
		}
	}
	
	// 移动端 样式
		@media (max-width:1024px){
			.giveServiceTo{
				width:100%;
				.banner{
					width:100%;
					height: 10.85rem;
					background: url('../../public/images/banner10.png')no-repeat center center;
					background-size: 100% 10.85rem;
					.title{
						width: 4.54rem;
						height: 2rem;
						font-size: 0.64rem;
						font-family: Arial;
						font-weight: bold;
						color: #333333;
						margin-top: 2.33rem;
						margin-left: 0.83rem;
					}
					.cont{
						width: 3.38rem;
						height: 0.75rem;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #333333;
						line-height: 0.3rem;
						margin-left: 0.83rem;
						margin-top: 0.55rem;
					}
				}
				.wrap{
					width:100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					ul{
						width:5.81rem;
						border-bottom:none;
						margin-top: 0.7rem;
						margin-bottom: 0.6rem;
						flex-direction: column;
						
						
						li{
							width: 5.81rem;
							padding-top: 0.19rem;
							padding-bottom: 0.19rem;
							font-size: 0.3rem;
							font-family: Arial;
							font-weight: bold;
							color: #808080;
							margin-right: 0.84rem;
							border-bottom:0.01rem solid #E0D6CC;
						}
						.li1{
							color: #1A1A1A;
							border-bottom: 0.03rem solid #910000;
						}
					}
				}
			}
		}
</style>