<template>
	<!-- 支持项 -->
	<div class="supportTerm flex_box_cen">
		<div class="wrap flex_box_column_cen">
			<div class="title wow fadeInUp">Charge Voltage Select111</div>
			<div class="box flex_box_column_cen">
				<ul>
					<li class="flex_box_bet">
						<div class="tit">Battery Type</div>
						<div class="tit">12VDC Model</div>
						<div class="tit">24VDC Model</div>
						<div class="tit">36VDC Model</div>
						<div class="tit">48VDC Model</div>
					</li>
					<li class="li2 " >
						<div class="text ">
							<span>SW1</span> <span>SW2</span> 
						</div>
						<div class="text ">
							<span>Bulk</span> <span>Float</span> 
						</div>
						<div class="text ">
							<span>Bulk</span> <span>Float</span> 
						</div>
						<div class="text ">
							<span>Bulk</span> <span>Float</span> 
						</div>
						<div class="text ">
							<span>Bulk</span> <span>Float</span> 
						</div>
					</li>
					
					<li class="li2 " >
						<div class="text ">
							<span>0</span> <span>1</span> 
						</div>
						<div class="text ">
							<span>12.2</span> <span>12</span> 
						</div>
						<div class="text ">
							<span>24.4</span> <span>24</span> 
						</div>
						<div class="text ">
							<span>36.6</span> <span>36</span> 
						</div>
						<div class="text ">
							<span>48.8</span> <span>48</span> 
						</div>
					</li>
					
					<li class="li2 " >
						<div class="text ">
							<span>1</span> <span>0</span> 
						</div>
						<div class="text ">
							<span>13.8</span> <span>13.8</span> 
						</div>
						<div class="text ">
							<span>13.6</span> <span>27.2</span> 
						</div>
						<div class="text ">
							<span>41.4</span> <span>40.8</span> 
						</div>
						<div class="text ">
							<span>55.2</span> <span>54.4</span> 
						</div>
					</li>
					
					<li class="li2 " >
						<div class="text ">
							<span>1</span> <span>1</span> 
						</div>
						<div class="text ">
							<span>14.2</span> <span>13.6</span> 
						</div>
						<div class="text ">
							<span>28.4</span> <span>27.2</span> 
						</div>
						<div class="text ">
							<span>42.6</span> <span>40.8</span> 
						</div>
						<div class="text ">
							<span>56.8</span> <span>54.4</span> 
						</div>
					</li>
					<li class="li2 li3" >
						<div class="text ">
							<span>0</span> <span>0</span> 
						</div>
						<div class="text ">
							<span>14.4</span> <span>13.8</span> 
						</div>
						<div class="text ">
							<span>28.8</span> <span>27.6</span> 
						</div>
						<div class="text ">
							<span>43.2</span> <span>41.4</span> 
						</div>
						<div class="text ">
							<span>57.6</span> <span>55.2</span> 
						</div>
					</li>
					<li class="li_left">
						DIP Switch
					</li>
				</ul>
			</div>
			<!-- 移动端 -->
			<div class="box2 flex_box_column_cen">
				<ul>
					<li class="flex_box_bet">
						<div class="tit">Battery Type</div>
						<div class="tit">12VDC Model</div>
						<div class="tit">24VDC Model</div>
						
					</li>
					<li class="li2 " >
						<div class="text ">
							<span>SW1</span> <span>SW2</span> 
						</div>
						<div class="text ">
							<span>Bulk</span> <span>Float</span> 
						</div>
						<div class="text ">
							<span>Bulk</span> <span>Float</span> 
						</div>
					
					</li>
					
					<li class="li2 " >
						<div class="text ">
							<span>0</span> <span>1</span> 
						</div>
						<div class="text ">
							<span>12.2</span> <span>12</span> 
						</div>
						<div class="text ">
							<span>24.4</span> <span>24</span> 
						</div>
					
					</li>
					
					<li class="li2 " >
						<div class="text ">
							<span>1</span> <span>0</span> 
						</div>
						<div class="text ">
							<span>13.8</span> <span>13.8</span> 
						</div>
						<div class="text ">
							<span>13.6</span> <span>27.2</span> 
						</div>
					
					</li>
					
					<li class="li2 " >
						<div class="text ">
							<span>1</span> <span>1</span> 
						</div>
						<div class="text ">
							<span>14.2</span> <span>13.6</span> 
						</div>
						<div class="text ">
							<span>28.4</span> <span>27.2</span> 
						</div>
						
					</li>
					<li class="li2 li3" >
						<div class="text ">
							<span>0</span> <span>0</span> 
						</div>
						<div class="text ">
							<span>14.4</span> <span>13.8</span> 
						</div>
						<div class="text ">
							<span>28.8</span> <span>27.6</span> 
						</div>
						
					</li>
					<li class="li_left">
						DIP Switch
					</li>
				</ul>
				
				<ul style="align-items: flex-start; width: 5.71rem;">
					<li class="flex_box_bet" style="width: 5.22rem;padding-left: 0.5rem;">
						<div class="tit">36VDC Model</div>
						<div class="tit">48VDC Model</div>
					</li>
					<li class="li2 li4 " >
						
						<div class="text ">
							<span>Bulk</span> <span>Float</span> 
						</div>
						<div class="text ">
							<span>Bulk</span> <span>Float</span> 
						</div>
					</li>
					
					<li class="li2 li4" >
						
						<div class="text ">
							<span>36.6</span> <span>36</span> 
						</div>
						<div class="text ">
							<span>48.8</span> <span>48</span> 
						</div>
					</li>
					
					<li class="li2 li4" >
						
						<div class="text ">
							<span>41.4</span> <span>40.8</span> 
						</div>
						<div class="text ">
							<span>55.2</span> <span>54.4</span> 
						</div>
					</li>
					
					<li class="li2 li4" >
						<div class="text ">
							<span>42.6</span> <span>40.8</span> 
						</div>
						<div class="text ">
							<span>56.8</span> <span>54.4</span> 
						</div>
					</li>
					<li class="li2 li4" >
						<div class="text ">
							<span>43.2</span> <span>41.4</span> 
						</div>
						<div class="text ">
							<span>57.6</span> <span>55.2</span> 
						</div>
					</li>
					<!-- <li class="li_left">
						DIP Switch
					</li> -->
				</ul>
			</div>
		</div>
		
	</div>
</template>

<script>
	import WOW from 'wowjs/dist/wow'
	export default{
		props: {
			// termList: {
			// 	type: Array,
			// 	default(){
			// 		return []
			// 	}
			// },
			// tobel_bom:{
			// 	type: String,
			// }
			
		},
		data() {
			return{
				
			}
		},
		mounted() {
			this.$nextTick(()=>{
				var wow = new WOW.WOW({
					boxClass:'wow',
					animateClass:'animated',
					offset:0,
					mobile:true,
					live :false
				})
				wow.init()
			})
		},
	}
</script>

<style lang="scss" scoped>
	.supportTerm{
		width: 100%;
		// background-color: blue;
		.wrap{
			width: 15.37rem;
			margin-top: 0.93rem;
			.title{
				width: 100%;
				font-size: 0.36rem;
				font-family: Arial;
				font-weight: bold;
				color: #5C6079;
			}
			.box{
				width: 14.21rem;
				
				margin-top: 0.58rem;
				ul{
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items:flex-end;
					position: relative;
					li{
						width: 13.8rem;
						padding-left: 0.8rem;
						
						height: 0.59rem;
						border-bottom: 0.01rem solid #CCCCCC;
						.tit{
							width: 2.2rem;
							font-size: 0.24rem;
							font-family: Arial;
							font-weight: bold;
							color: #333333;
							line-height: 0.59rem;
						}
						.text{
							width: 2.2rem;
							font-size: 0.16rem;
							font-family: Arial;
							font-weight: 400;
							color: #4D4D4D;;
							line-height: 0.59rem;
						}
					}
					.li2{
						width: 13.33rem;
						padding-left: 0.4rem;
						display: flex;
						.text{
							margin-right: 1.4rem;
							width: 1.5rem;
							display: flex;
							justify-content: space-between;
							font-size: 0.16rem;
							font-family: Arial;
							font-weight: 400;
							color: #4D4D4D;
							text-align: left;
							span{
								min-width: 0.32rem;
							}
						}
						.text:nth-child(1){
							margin-right: 0.94rem;
						}
						.text:last-child{
							margin-right: 0rem;
						}
					}
					.li3{
						padding-left: 1.2rem;
						border-bottom: 0.01rem solid #ccc;
					}
					.li_left{
						width: 1rem;
						position: absolute;
						left: -1.2rem;
						top:2.01rem;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #4D4D4D;
						border: none;
					}
					.btm{
						width: 13.8rem;
						height: 0.59rem;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #4D4D4D;
						line-height: 0.59rem;
						text-align: center;
					}
				}
			}
			.box2{
				display: none;
			}
		}
	}
	// 移动端 样式
		@media (max-width:1024px){
			.supportTerm{
				width: 100%;
				// background-color: blue;
				.wrap{
					width: 5.71rem;
					margin-top: 0.93rem;
					.title{
						width: 100%;
						font-size: 0.36rem;
						font-family: Arial;
						font-weight: bold;
						color: #5C6079;
					}
					.box{
						display: none;
					}
					.box2{
						width: 5.71rem;
						display: block;
						margin-top: 0.58rem;
						ul{
							width: 100%;
							display: flex;
							flex-direction: column;
							align-items:flex-end;
							position: relative;
							li{
								width:5.1rem;
								padding-left: 0.5rem;
								height: 0.59rem;
								border-bottom: 0.01rem solid #CCCCCC;
								.tit{
									min-width: 0rem;
									font-size: 0.24rem;
									font-family: Arial;
									font-weight: bold;
									color: #333333;
									line-height: 0.59rem;
								}
								.text{
									width: 2.2rem;
									font-size: 0.16rem;
									font-family: Arial;
									font-weight: 400;
									color: #4D4D4D;;
									line-height: 0.59rem;
								}
							}
							.li2{
								width: 4.4rem;
								padding-left: 0.1rem;
								display: flex;
								.text{
									margin-right: 1.05rem;
									width: 0.9rem;
									display: flex;
									justify-content: space-between;
									font-size: 0.16rem;
									font-family: Arial;
									font-weight: 400;
									color: #4D4D4D;
									text-align: left;
									span{
										min-width: 0.32rem;
									}
								}
								.text:nth-child(1){
									margin-right: 0.4rem;
								}
								.text:last-child{
									margin-right: 0rem;
								}
							}
							.li3{
								padding-left: 1.3rem;
								border-bottom: 0.01rem solid #ccc;
							}
							.li4{
								width: 4.9rem;
								display: flex;
								justify-content: space-between;
								margin-left: 0px;
								padding-left: 0.7rem;
								padding-right: 0.14rem;
								// margin-right: 4.5rem;
								
							}
							.li_left{
								width: 1rem;
								position: absolute;
								left: -0.3rem;
								top:2.01rem;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #4D4D4D;
								border: none;
							}
							.btm{
								width: 13.8rem;
								height: 0.59rem;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #4D4D4D;
								line-height: 0.59rem;
								text-align: center;
							}
						}
					}
				}
			}
		}
</style>