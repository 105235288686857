<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features :pplicableIofo='pplicableIofo' :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<product-appearance :appearanceList='appearanceList'></product-appearance>
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<support-term :termList='termList' :tobel_bom='tobel_bom'></support-term>
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'48V-TPP',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/images/48V-TPP.png'),
					title:'48V-TPP Series Low Frequency Three-phase Pure Sine Wave Inverter/Charger',
					info:'Three phase pure sine wave output. The conventional conversion time is 8ms. Designed for harsh environment. It can hold 300% overload within 20s, providing necessary current for starting electrical appliances with high requirements. TPP series is capable of starting and stopping the generator, and realizing the function of monitoring the equipment operation status and battery temperature etc.by mobile through additional communication modules.',
				},
				productInfo:[
					{
						title:'Operating voltage',
						list:[
							{
								info:" 3AC/N  400V/207V  ",
								icon:'',
							},
						]
					},
					{
						title:'Output phase voltage',
						list:[
							{
								info:" 120/230VAC ",
								icon:'',
							},
						]
					},
					{
						title:'Operating frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							},{
								info:"Small scale factory",
								icon:require('../../public/images/scale.png'),
							},{
								info:"Recreation vehicles",
								icon:require('../../public/images/cart.png'),
							},
							{
								info:"Heavy duty truck",
								icon:require('../../public/images/bigCart.png'),
							},
							{
								info: "Yacht",
								icon:require('../../public/images/ship.png'),
							},
							{
								info:"Engineering vehicle",
								icon:require('../../public/images/evCart.png'),
							}
						]
					},
					{
						title:'Range of power',
						list:[
							{
								info:"3.0KW",
								icon:'',
							},{
								info:"4.5KW",
								icon:'',
							},
							{
								info:"6.0KW",
								icon:'',
							},
							{
								info: "9.0KW",
								icon:'',
							},
							{
								info:"12.0KW",
								icon:'',
							},
							{
								info: "15.0KW",
								icon:'',
							},
							{
								info:"18.0KW",
								icon:'',
							},
							{
								info: "24.0KW",
								icon:'',
							},
							{
								info:"30.0KW",
								icon:'',
							},
							{
								info: "36.0KW",
								icon:'',
							},
							{
								info:"45.0KW",
								icon:'',
							},
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/shield.png'),
						text:'Multiple protection for safer use',
					},
					{
						icon:require('../../public/images/line.png'),
						text:'Real pure sine wave output to protect sensitive loads',
					},
					{
						icon:require('../../public/images/engine.png'),
						text:'Generator automatic start/stop function',
					},
					{
						icon:require('../../public/images/temperature.png'),
						text:'Battery temperature monitoring function',
					},
					{
						icon:require('../../public/images/charge.png'),
						text:'Adaptable to the charging voltage of different types of batteries',
					},
					{
						icon:require('../../public/images/line2.png'),
						text:'THD<3% under standard load',
					},
					{
						icon:require('../../public/images/output.png'),
						text:'Three-phase input/output',
					}
					
				],
				pplicableIofo:[],
				appearanceList:[
					{
						pic:require('../../public/images/12V-TPP1.png'),
						text:'48V-3.0KW/4.5KW/6.0KW/9.0KW/12.0KW/TPP 15.0KW/18.0KW'
					},
					{
						pic:require('../../public/images/12V-TPP2.png'),
						text:'48V-3.0KW/4.5KW/6.0KW/9.0KW/12.0KW/TPP 15.0KW/18.0KW'
					},
					{
						pic:require('../../public/images/48V-TPP1.png'),
						text:'48V-18.0KW'
					},
					{
						pic:require('../../public/images/48V-TPP2.png'),
						text:'48V-18.0KW'
					},
					{
						pic:require('../../public/images/48V-TPP3.png'),
						text:'48V-24.0KW/30.0KW/36.0KW'
					},
					{
						pic:require('../../public/images/48V-TPP4.png'),
						text:'48V-24.0KW/30.0KW/36.0KW'
					},
					{
						pic:require('../../public/images/48V-TPP5.png'),
						text:'48V-45.0KW'
					},
					{
						pic:require('../../public/images/48V-TPP6.png'),
						text:'48V-45.0KW'
					}
				],
				priceInfo:[						//技术参数信息
					{
						name:'Inverter Output',
						list:[
							{
								parameterName:'Model',
								parameterList:['TPP3KW','TPP4.5KW','TPP6KW','TPP9KW','TPP12KW','TPP15KW','TPP18KW','TPP24KW','TPP30KW','TPP36KW','TPP45KW']
							},
							{
								parameterName:'Continuous Output Power',
								parameterList:['3000W','4500W','6000W','9000W','12000W','15000W','18000W','24000W','30000W','36000W','45000W']
							},
							{
								parameterName:'Surge Rating (20s)',
								parameterList:['9000W','13500W','18000W','27000W','36000W','45000W','54000W','72000W','90000W','108000W','135000W']
							},
							{
								parameterName:'Connection mode',
								parameterList:['3-phase 4-wire system+Gnd']
							},
							{
								parameterName:'Output voltage rating',
								parameterList:['3AC/N 400V/207V']
							},
							{
								parameterName:'Output phase voltage',
								parameterList:['120/230VAC','120/230VAC','120/230VAC','120/230VAC','120/230VAC','230VAC','230VAC','230VAC','230VAC']
							},
							{
								parameterName:'Unbalance Load Acceptable',
								parameterList:['100%']
							},
							{
								parameterName:'Output Frequency',
								parameterList:['50Hz ± 0.3Hz / 60Hz ± 0.3Hz']
							},
							{
								parameterName:'Nominal Efficiency',
								parameterList:['88% (Peak)']
							},
							{
								parameterName:'Line Mode Efficiency',
								parameterList:['>95%']
							},
							{
								parameterName:'Power Factor',
								parameterList:['0.9-1.0']
							},
							{
								parameterName:'Output Voltage Regulation',
								parameterList:['±10% RMS']
							},
							{
								parameterName:'Typical transfer Time',
								parameterList:['Typical 6-8ms,10ms(Max)']
							},
							{
								parameterName:'THD',
								parameterList:['<3% Linear Loac']
							}
							
						]
					},
					{
						name:'DC Input',
						list:[
							{
								parameterName:'Nominal Input Voltage',
								parameterList:['42.0Vdc/ 44.0Vdc']
							},
							{
								parameterName:'Minimum Start Voltage',
								parameterList:['42.0Vdc/ 44.0Vdc']
							},
							{
								parameterName:'Low Battery Voltage Recover',
								parameterList:['40.0Vdc/ 42.0Vdc']
							},
							{
								parameterName:'Idle Consumption-Search Mode',
								parameterList:['< 100W when Power Saver On']
							},
						]
					},
					{
						name:'Charger',
						list:[
							{
								parameterName:'Input Voltage Range',
								parameterList:['Narrow: 96-132VAC / 184~253VAC;Wide 70-135VAC70-135VAC/ 140-270VAC;']
							},
							{
								parameterName:'Output Voltage',
								parameterList:['Depends on battery type']
							},
							{
								parameterName:'Max Charge Current',
								parameterList:['10A*3','15A*3','20A*3','30A*3','40A*3','50A*3','60A*3','80A*3','100A*3','120A*3','150A*3']
							}
						]
					},
					{
						name:'Bypass & Protection',
						list:[
							{
								parameterName:'Max Input AC Voltage',
								parameterList:['150Vac For 120Vac LV Mode； 300Vac For 230Vac HV Mode']
							},
							{
								parameterName:'Nominal Input Frequency',
								parameterList:['50Hz or 60Hz ( Auto detect )']
							},
							{
								parameterName:'Over load protection (SMPS Ioad)',
								parameterList:['Circuit Breaker']
							},
							{
								parameterName:'Output Short Circuit Protection',
								parameterList:['Circuit Breaker']
							},
							{
								parameterName:'Bypass Breaker Rating(230Vac)',
								parameterList:['10A','10A','10A','20A','20A','30A','30A','40A','50A','60A','80A']
							},
							{
								parameterName:'Bypass Breaker Rating(120Vac)',
								parameterList:['10A','20A','20A','30A','40A','50A','60A']
							},
							{
								parameterName:'Bypass Without Battery Connected',
								parameterList:['Yes']
							}
						]
					},
					{
						name:'Mechanical Specifications',
						list:[
							{
								parameterName:'Mounting',
								parameterList:['Wall mount / Cabinet type','Cabinet type']
							},
							{
								parameterName:"Dimensions (mm / inch)",
								parameterList:['593*841*213 / 23.3*33.1*8.4 or 555*808*482 / 21.9*31.8*19','650.2*803.2*513 / 25.6*31.6*20.2','768.2*848.2*513 / 30.2*33.4*20.2']
							},
							{
								parameterName:'Communication methods',
								parameterList:['RS32/458/CAN']
							},
							{
								parameterName:'Display',
								parameterList:['Status LEDs / Status LEDs+LCD']
							},
						]
					}
				],
				termList:[						//支持项数据
					{
						title:'Gel USA',
						fast:'14.0',
						float:'13.7'
					},
					{
						title:'AGM 1',
						fast:'14.1',
						float:'13.4'
					},
					{
						title:'Lithium',
						fast:'13.8',
						float:'13.6'
					},
					{
						title:'Sealed Lead Acid',
						fast:'14.4',
						float:'13.6'
					},
					{
						title:'Gel EURO',
						fast:'14.4',
						float:'13.8'
					},
					{
						title:'Open Lead Acid',
						fast:'14.0',
						float:'13.8'
					},
					{
						title:'LifePO4',
						fast:'15.5 (4 Hours then Off)',
						float:''
					},
					{
						title:'De-sulphation',
						fast:'13.6',
						float:'13.5'
					}
				],
				tobel_bom:'For 12Vdc Mode Series("2 for 24Vdc Mode/4 for 48Vdc Mode/8 for 96Vdc Mode)',
				otherList:[
					{
						img:require('../../public/otherImg/48V-HP.png'),
						url:'/48V-HP'
					},
					{
						img:require('../../public/otherImg/48V-AP.png'),
						url:'/48V-AP'
					}
				]
			}
			
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
