<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features :pplicableIofo='pplicableIofo' :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<product-appearance :appearanceList='appearanceList'></product-appearance>
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<voltage-select></voltage-select>
	<!-- <support-term :termList='termList' :tobel_bom='tobel_bom'></support-term> -->
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import voltageSelect from '@/components/voltageSelect/voltageSelect.vue'						//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'CSB',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			voltageSelect,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					id:2,
					pic:require('../../public/images/CSB.png'),
					title:'CSB Series AC Converter / Battery Charger',
					info:'Three stage timer-based charging algorithm for battery life and safety Most suitable for fast battery charging.Operate with wider input voltage range,with PFC function',
				},
				productInfo:[
					{
						title:'Operating voltage',
						list:[
							{
								info:"single phase H : 90~286Vac / L :70~145Vac",
								icon:'',
							}
						]
					},
					{
						title:'Operating frequency',
						list:[
							{
								info:"40~70Hz",
								icon:'',
							}
						]
					},
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							},{
								info:"Recreation vehicles",
								icon:require('../../public/images/cart.png'),
							},
							{
								info:"Heavy duty truck",
								icon:require('../../public/images/bigCart.png'),
							},
							{
								info: "Yacht",
								icon:require('../../public/images/ship.png'),
							},
							{
								info:"Engineering vehicle",
								icon:require('../../public/images/evCart.png'),
							}
						]
					},
					{
						title:'Range of power',
						list:[
							{
								info:"500KW",
								icon:'',
							},{
								info:"1000KW",
								icon:'',
							},
							{
								info:"2000KW",
								icon:'',
							}
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/shield.png'),
						text:'Multiple protection for safer use',
					},
					{
						icon:require('../../public/images/technology.png'),
						text:'Three stage charging technology',
					},
					{
						icon:require('../../public/images/wide.png'),
						text:'Wide input voltage',
					}
				],
				pplicableIofo:[],
				appearanceList:[
					{
						pic:require('../../public/images/CSB1.png'),
						text:'CSB 2000W'
					},
					{
						pic:require('../../public/images/CSB2.png'),
						text:'CSB 2000W'
					},
					{
						pic:require('../../public/images/CSB3.png'),
						text:'CSB 500W/1000W'
					},
					{
						pic:require('../../public/images/CSB4.png'),
						text:'CSB 500W/1000W'
					}
				],
				priceInfo:[						//技术参数信息
					{
						name:'Inverter Output',
						list:[
							{
								parameterName:'Model',
								parameterList:['CSB05H / CSB05L','CSB10H / CSB10L','CSB20H / CSB20L']
							},
							{
								parameterName:'Rated Power (W)',
								parameterList:['500W','1000W','2000W']
							},
							{
								parameterName:'Battery Voltage',
								parameterList:['12/24Vdc','36/48Vdc','12/24Vdc','36/48Vdc','12/24Vdc','36/48Vdc']
							},
							{
								parameterName:'Output Current (A)',
								parameterList:['37.5/18.75A','12.5/9.375A','75/37.5A ','25/18.75A ','150/75A','50/37.5A']
							},
							{
								parameterName:'Display',
								parameterList:['LED Display / LCD Display (optional)']
							},
							{
								parameterName:'AC Input Voltage Range (Vac)',
								parameterList:['H : 90~286Vac / L :70~145Vac']
							},
							{
								parameterName:'Input Type',
								parameterList:['AC Plug Cord','AC Plug Cord','CSB20L: 3 PIN Terminal Block CSB20H : AC Plug Cord']
							},
							{
								parameterName:'AC Input Frequency',
								parameterList:['40~70Hz']
							},
							{
								parameterName:'Charging Efficiency',
								parameterList:['≥80%']
							},
							{
								parameterName:'Operation temperature',
								parameterList:['0°C ~ 50°C']
							},
							{
								parameterName:'Storage temperature',
								parameterList:['0°C ~ 105°C']
							},
							{
								parameterName:'Protection',
								parameterList:['Over/Under Voltage,Over Temperature,Over Current']
							},
							{
								parameterName:'Cooling',
								parameterList:['Smart fan control (Control by Heat Sink temperature,Charging Current )']
							},
							{
								parameterName:'Chasis Material',
								parameterList:['Iron Chasis / Alu. Chasis']
							},
							{
								parameterName:'Optional Accessories / Function',
								parameterList:['BTS ; Reverse Polarity Protection ; RS232 Comm Module ; Battery 0V Charging ; Rain Shield']
							},
							{
								parameterName:'Product Size (mm)(L x W x H)',
								parameterList:['259*134*72.5','259*134*72.5','315*170*83.5 ']
							},
							{
								parameterName:'Packing Size  (mm)(L x W x H)',
								parameterList:['346*191*122','346*191*122','415*245*152']
							},
							{
								parameterName:'Net Weight (KG)',
								parameterList:['2.50','2.50 ','3.50']
							},
							{
								parameterName:'Gross Weight (KG)',
								parameterList:['2.77','2.77 ','3.92']
							}
							
						]
					},
				],
				termList:[						//支持项数据
					{
						title:'Gel USA',
						fast:'14.0',
						float:'13.7'
					},
					{
						title:'AGM 1',
						fast:'14.1',
						float:'13.4'
					},
					{
						title:'Lithium',
						fast:'13.8',
						float:'13.6'
					},
					{
						title:'Sealed Lead Acid',
						fast:'14.4',
						float:'13.6'
					},
					{
						title:'Gel EURO',
						fast:'14.4',
						float:'13.8'
					},
					{
						title:'Open Lead Acid',
						fast:'14.0',
						float:'13.8'
					},
					{
						title:'LifePO4',
						fast:'15.5 (4 Hours then Off)',
						float:''
					},
					{
						title:'De-sulphation',
						fast:'13.6',
						float:'13.5'
					}
				],
				tobel_bom:'For 12Vdc Mode Series("2 for 24Vdc Mode/4 for 48Vdc Mode/8 for 96Vdc Mode)',
				otherList:[
					{
						img:require('../../public/otherImg/1000V-UP-S.png'),
						url:'/1000V-UP-S'
					},
					{
						img:require('../../public/otherImg/12V-HP.png'),
						url:'/12V-HP'
					},
					{
						img:require('../../public/otherImg/24V-HPV.png'),
						url:'/24V-HPV'
					},
					{
						img:require('../../public/otherImg/96V-HP.png'),
						url:'/96V-HP'
					}
				]
			}
			
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
