<template>
	<!-- 联系我们页 -->
	<div class="contact">
		<!-- 头部 -->
		<yiy-header></yiy-header>
		<div class="banner">
			<div class="title"> CONTACT US </div>
			<div class="text">If you have any need, please feel free to leave a  message or call us</div>
		</div>
		<!-- 地图板块 -->
		<div class="map_box flex_box_column_cen">
			<div class="top flex_box ">
				<div class="left flex_box_column">
					<!-- <div class='title'>CONTACT</div> -->
					<!-- <div class="phone flex_box_cen_align_cen">
						<p></p>
						<p>+86 577 2777 2199</p> 
					</div>
					<div class="fax flex_box_cen_align_cen">
						<p></p>
						<p>+86 577 2777 2199</p> 
					</div> -->
					<!-- <div class="cen flex_box_bet">
						<img src="../../public/images/st.png" alt="">
						<img src="../../public/images/fb.png" alt="">
						<img src="../../public/images/tw.png" alt="">
						<img src="../../public/images/in.png" alt="">
					</div> -->
					
					<div class="btm flex_box">
						<div class="phone_fax">
							<div class="phone flex_box_cen_align_cen">
								<p></p>
								<p>+86 577 2777 2199</p> 
							</div>
							<div class="fax flex_box_cen_align_cen">
								<p></p>
								<p>+86 577 2777 2199</p> 
							</div>
						</div>
						<div class="list flex_box" v-for="(item,index) in list" :key="index" @click="swch(item)">
							<div class="city_address">
								<div :class="item.id == id?'city':'city1' ">
									{{item.city}}
								</div>
								<div class="address flex_box_bet">
									<p>
										<img src="../../public/smallImg/address.png" alt="">
									</p>
									<p>
										{{item.address}}
									</p>
								</div>
							</div>
							<!-- <div class="line" v-if="item.id != 3"></div> -->
						</div>
					</div>
					
					<!-- <div class="qr_box flex_box_bet">
						<div class="qr flex_box_column_cen">
							<img src="../../public/images/qr.png" alt="">
							<span>Wechat official account</span>
						</div>
						<div class="qr flex_box_column_cen">
							<img src="../../public/images/qr1.png" alt="">
							<span>Mobile terminal</span>
						</div>
					</div> -->
				</div>
				<div class="map">
					<div id="container" style="width:100%; height:100%"></div>
				</div>
			</div>
			<div class="qr_box flex_box_bet">
				<div class="qr flex_box_column_cen">
					<img src="../../public/images/qr.png" alt="">
					<span>Wechat official account</span>
				</div>
				<!-- <div class="qr flex_box_column_cen">
					<img src="../../public/images/qr1.png" alt="">
					<span>Mobile terminal</span>
				</div> -->
			</div>
			<!-- <div class="btm flex_box">
				<div class="list flex_box" v-for="(item,index) in list" :key="index" @click="change(item)">
					<div class="city_address">
						<div :class="item.id == id?'city':'city1' ">
							{{item.city}}
						</div>
						<div class="address flex_box_bet">
							<p>
								<img src="../../public/smallImg/address.png" alt="">
							</p>
							<p>
								{{item.address}}
							</p>
						</div>
					</div>
					<div class="line" v-if="item.id != 3"></div>
				</div>
			</div> -->
		</div>
		<div class="wrap flex_box">
			<div class="left"></div>
			<div class="right">
				<div class="title">Do you Need Help Energy Storage Systems?</div>
				<div class="form">
					<div class="frame_box flex_box_bet">
						<div class="info flex_box">
							<span>*</span>
							<input type="text" placeholder="Your Name" v-model='name'>
							<div id='nameTip3' class='animated ' :class="state?'fadeInDown':''"></div>
						</div>
						<div class="info flex_box">
							<span>*</span>
							<input type="text" placeholder="Products " v-model='products'>
							<div id='productTip3' class='animated ' :class="state?'fadeInDown':''"></div>
						</div>
					</div>
					<div class="frame_box flex_box_bet">
						<div class="info flex_box">
							<span>*</span>
							<input type="text" placeholder="E-mail" v-model='email'>
							<div id='emailTip1' class='animated ' :class="state?'fadeInDown':''"></div>
						</div>
						<div class="info flex_box">
							<span>*</span>
							<input type="text" placeholder="Telephone " v-model='phone'>
							<div id='mobileTip3' class='animated ' :class="state?'fadeInDown':''"></div>
						</div>
					</div>
					<div class="address">
						<input type="text" placeholder="Address" v-model='address'>
					</div>
					<div class="message flex_box">
						<span>*</span>
						<textarea placeholder="Message" v-model='message'></textarea>
						<div id='messageTip3' class='animated' :class="state?'fadeInDown':''"></div>
					</div>
				</div>
				<div class="btn" @click="add()" v-show="!change">Submit</div>
				<div class="btn" v-show="change">Success</div>
			</div>
		</div>
		<!-- 底部 -->
		<new-footer></new-footer>
		<right-frame></right-frame>
	</div>
</template>

<script>
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import axios from 'axios';
export default {
	metaInfo:{
		title:'contact',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
	components:{
		yiyHeader,
		newFooter,
		rightFrame
	},
	data() {
		return{
			id:'1',
			change:false,
			state:false,
			name:'',						//名字
			phone:'',						//电话
			products:'',					//产品
			email:'',						//邮件
			address:'',						//地址
			message:'',						//详情
			website:'',						//当前页面
			ip:'',							// 获取ip
			country:'',
			list:[
				{	//一元电气科技有限公司
					id:1,
					city:'YIYEN ELECTRIC TECHNOLOGY CO.,LTD Zhejiang Branch',
					address:'Rm.1301.Building 3.Headquarters Economic Park .No.6688 Xuyang Road . Yueging City. 325600.Zhejiang',
					longitude: 121.008935,
					latitude: 28.100209,
				},
				{	//深圳市一元电气
					id:2,
					city:'SHENZHEN YIYUAN TECHNOLOGY CO,LTD Shenzhen Branch',
					address:'4th Floor.Building A.Guanghao Industrial Zone.Queshan Road Longhua Town Baoan District. Shenzhen 518109.China',
					longitude: 113.999184,
					latitude: 22.656333
				},
				{	//丽水一元电气
					id:3,
					city:'LISHUI YIYEN TECHNOLOGY COMPANY LIMITED',
					address:'No.77,Xiang Long Road,Lian Du Zone, Lishui City,Zhejiang Province, China',
					longitude: 119.87588,
					latitude: 28.370668
				},
			],
			position:null
		}
	},
	created() {
	
		// 获取ip
		fetch('https://api.ipify.org?format=json')
		  .then(response => response.json())
		  .then(data => {
			this.ip = data.ip
		    console.log(data.ip);
		  });
	},
	mounted() {
			this.position = new AMap.LngLat(121.008935, 28.100209);
			this.myMap()
			
	},
	methods:{
		add(){
			this.change = true
			let ok = true
			// name
			if (this.name == '') {
				document.getElementById('nameTip3').innerText = "*required";
				this.state = true;
				ok = false
			} else {
				
				setTimeout(() => {
					document.getElementById('nameTip3').innerText = "";
				}, 300)
			}
			// phone
			if (this.phone == '') {
				document.getElementById('mobileTip3').innerText = "*required";
				this.state = true;
				ok = false
			} else {
				
				setTimeout(() => {
					document.getElementById('mobileTip3').innerText = "";
				}, 300)
			}
			// product
			if (this.products == '') {
				document.getElementById('productTip3').innerText = "*required";
				this.state = true;
				ok = false
			} else {
				
				setTimeout(() => {
					document.getElementById('productTip3').innerText = "";
				}, 300)
			}
			// email
			let reg_mail =
				/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
			if (this.email == '') {
				this.state = true;
				document.getElementById('emailTip1').innerText = "*required";
				ok = false
			} else if (!reg_mail.test(this.email)) {
				this.state = true;
				ok = false
				// 检查格式
				document.getElementById('emailTip1').innerText = "*E-mail format is incorrect";
				console.log('请输入正确的邮箱')
			} else {
				console.log('输入正确')
				
				setTimeout(() => {
					document.getElementById('emailTip1').innerText = "";
				}, 300)
			}
			// message
			if (this.message == '') {
				document.getElementById('messageTip3').innerText = "*required";
				this.state = true;
				ok = false
			} else {
				setTimeout(() => {
					document.getElementById('messageTip3').innerText = "";
				}, 300)
			}
			
			if (ok == true) {
			
				this.setData()
				console.log(1111111111)
			}else{
				this.change = false
			}
		
		},
		setData() {
			this.website = window.location.href
			let formData = new FormData();
			formData.append("name", this.name);
			formData.append("product", this.products);
			formData.append("email", this.email);
			formData.append("mobile", this.phone);
			formData.append("address", this.address);
			formData.append("message", this.message);
			formData.append("website", this.website);
			formData.append("ip", this.ip);
			formData.append("country", this.country);
		
			// that.sendState = false;
			
			this.submitMail(formData);
		},
		submitMail(formData){
			console.log()
			axios({
				url: 'https://yiyapi.sgrcr.com/EMAILQuery',
				method: 'POST',
				data: formData,
			}).then((res) => {
				console.log(res)
				this.name = '';
				this.products = '';
				this.email = '';
				this.phone = '';
				this.address = '';
				this.message = '';
				this.website = '';
				this.ip = '';
				this.country = '';
				this.change = false
			})
		},
		myMap(){
			// 创建地图实例
			var map = new AMap.Map("container", {
			    zoom: 20,
			    center: this.position,
			    resizeEnable: true
			});
			this.myMarker(map)
		},
		myMarker(map){
			var marker = new AMap.Marker({
				icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png",
				offset: new AMap.Pixel(-13, -30)
			});
			marker.setMap(map);
		},
		swch(item){
			if(this.id != item.id){
				this.id = item.id
				this.position = new AMap.LngLat(item.longitude, item.latitude);
				this.myMap()
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	/deep/.amap-icon img{
	            width: 0.18rem;
	            height: 0.28rem;
			
		}
	.contact{
		width:100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		.banner{
			width: 100%;
			height: 8.6rem;
			background:url('../../public/images/banner5.png')no-repeat center center;
			background-size: 100% 8.6rem;
			.title{
				font-size: 0.64rem;
				font-family: Arial;
				font-weight: bold;
				color: #FFFFFF;
				margin-top: 2.42rem;
				margin-left: 1.9rem;
			}
			.text{
				margin-top: 0.54rem;
				width: 3.43rem;
				font-size: 0.16rem;
				font-family: Arial;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.3rem;
				margin-left: 1.9rem;
			}
		}
		.map_box{
			width: 15.38rem;
			height: 8.2rem;
			background-color: #fff;
			box-shadow: 0px 0px 0.08rem 0px rgba(10,10,10,0.15);
			position: absolute;
			top:6.8rem;
			.top{
				width: 100%;
				height: 6.18rem;
			}
			.left{
				// width: 3.58rem;
				height: 6.18rem;
				// background-color: red;
				margin-right: 1.82rem;
				margin-left: 0.85rem;
				.title{
					font-size: 0.36rem;
					font-family: Arial;
					font-weight: bold;
					color: #333333;
					margin-top: 0.91rem;
					margin-left: 0.12rem;
				}
				.phone_fax{
					position: absolute;
					top: 1.2rem;
				}
				.phone{
					width: 1.92rem;
					height: 0.26rem;
					// margin-top: 0.66rem;
					margin-bottom: 0.12rem;
					margin-left: -0.05rem;
					p{
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #333333;
					}
					p:nth-child(1){
						width: 0.26rem;
						height: 0.26rem;
						background: url('../../public/smallImg/phone.png') no-repeat center center;
						background-size: 0.26rem 0.26rem;
						margin-right: 0.2rem;
					}
				}
				.fax{
					width: 1.92rem;
					height: 0.26rem;
					margin-left: -0.05rem;
					p{
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #333333;
					}
					p:nth-child(1){
						width: 0.26rem;
						height: 0.26rem;
						background: url('../../public/smallImg/fax.png') no-repeat center center;
						background-size: 0.26rem 0.26rem;
						margin-right: 0.2rem;
					}
				}
				.cen{
					width:2.26rem ;
					height: 0.27rem;
					margin-top: 0.44rem;
					align-items: center;
					margin-bottom: 0.46rem;
					margin-left: 0.12rem;
					img{
						width: 0.27rem;
						height: 0.27rem;
					}
					img:nth-child(2){
						width: 0.12rem;
						height: 0.22rem;
					}
					img:nth-child(3){
						width: 0.24rem;
						height: 0.20rem;
					}
					img:nth-child(4){
						width: 0.22rem;
						height: 0.22rem;
					}
				}
				
			}
			.map{
				width:6.71rem;
				height:3.33rem;
				margin-top: 0.7rem;
			}
			.qr_box{
				// width: 3.56rem;
				position: absolute;
				top: 4.5rem;
				right: 3.34rem;
				.qr{
					width: 1.64rem;
					height: 1.61rem;
					background-color: #fff;
					img{
						width: 1.64rem;
						height: 1.61rem;
					}
					span{
						margin-top: 0.13rem;
						font-size: 0.14rem;
						font-family: Arial;
						font-weight: 400;
						color: #666666;
					}
				}
				
			}
			.btm{
				width: 5.2rem;
				// height:5rem;
				flex-direction: column;
				.list{
					width: 5.2rem;
					height:1.7rem;
					margin-top: 0.46rem;
					// margin-right: 0.39rem;
					border-bottom:1px solid #CCCCCC;
					.city_address{
						width: 5.2rem;
						.city{
							width: 5.2rem;
							font-size: 0.18rem;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #910000;
							margin-bottom: 0.2rem;
							line-height: 0.26rem;
						}
						.city1{
							width: 5.2rem;
							font-size: 0.18rem;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #333333;
							margin-bottom: 0.2rem;
							line-height: 0.26rem;
						}
						.address{
							width: 5.2rem;
							p:nth-child(2){
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #666666;
								line-height: 0.24rem;
								margin-left: 0.14rem;
							}
						}
					}
					
					.line{
						width: 0.01rem;
						height: 1.14rem;
						background: #F5F5F5;
						border: 0.01rem solid #CCCCCC;
						margin-left: 0.39rem;
					}
				}
				.list:nth-child(2){
					height: 2.2rem;
					margin-top: 0.8rem;
					.address{
						margin-top: 0.9rem;
					}
				}
				.list:last-child{
					border: none;
				}
			}
		}
		.wrap{
			width: 15.38rem;
			margin-top: 6.38rem;
			margin-bottom: 1.51rem;
			
			.left{
				width: 7.83rem;
				height: 5.02rem;
				background: url('../../public/images/map.png') no-repeat center center;
				background-size:7.83rem 5.02rem;
				margin-top: 0.31rem;
				margin-right: 0.47rem;
			}
			.right{
				width: 7.1rem;
				.title{
					font-size: 0.48rem;
					font-family: Arial;
					font-weight: bold;
					color: #1A1A1A;
				}
				.form{
					width: 7.1rem;
					margin-top: 0.49rem;
					.frame_box{
						width: 7.1rem;
						margin-bottom: 0.19rem;
						.info{
							width: 3.4rem;
							height: 0.56rem;
							border: 0.01rem solid #CCCCCC;
							position: relative;
							span{
								font-size: 0.18rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #910000;
								margin-left:0.2rem ;
								margin-top: 0.23rem;
							}
							input{
								width: 2.8rem;
								margin-left: 0.21rem;
								height: 0.56rem;
								border:none;
								font-size: 0.18rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #333;
								line-height: 0.56rem;
							}
							#nameTip3{
								position: absolute;
								left: 0.18rem;
								bottom: -0.19rem;
								color: #910000;
								font-size: 0.16rem;
								text-align: left;
							}
							#productTip3{
								position: absolute;
								left: 0.18rem;
								bottom: -0.19rem;
								color: #910000;
								font-size: 0.16rem;
								text-align: left;
							}
							#emailTip1{
								position: absolute;
								left: 0.18rem;
								bottom: -0.19rem;
								color: #910000;
								font-size: 0.16rem;
								text-align: left;
							}
							#mobileTip3{
								position: absolute;
								left: 0.18rem;
								bottom: -0.19rem;
								color: #910000;
								font-size: 0.16rem;
								text-align: left;
							}
						}
					}
					.address{
						width: 7.09rem;
						height: 0.56rem;
						border: 0.01rem solid #CCCCCC;
						margin-bottom: 0.19rem;
						input{
							width: 6.4rem;
							margin-left: 0.48rem;
							height: 0.56rem;
							border:none;
							font-size: 0.18rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #333;
							line-height: 0.56rem;
						}
					}
					input::-webkit-input-placeholder{
						color: #CCCCCC;
					}
					input::-moz-placeholder{
						color: #CCCCCC;
					}
					input:-moz-placeholder{
						color: #CCCCCC;
					}
					input:-ms-input-placeholder{
						color: #CCCCCC;
					}
					.message{
						width: 7.1rem;
						height: 1.3rem;
						border: 0.01rem solid #CCCCCC;
						position: relative;
						span{
							margin-top: 0.22rem;
							margin-left: 0.18rem;
							font-size: 0.18rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #910000;
						}
						textarea{
							width: 6.4rem;
							margin-left: 0.21rem;
							margin-top: 0.15rem;
							resize:none;
							border:none;
							height: 1rem;
							font-size: 0.18rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #333;
						}
						#messageTip3{
							position: absolute;
							left: 0.18rem;
							bottom: -0.19rem;
							color: #910000;
							font-size: 0.16rem;
							text-align: left;
						}
						textarea::-webkit-input-placeholder{
							color: #CCCCCC;
						}
						textarea::-moz-placeholder{
							color: #CCCCCC;
						}
						textarea:-moz-placeholder{
							color: #CCCCCC;
						}
						textarea:-ms-input-placeholder{
							color: #CCCCCC;
						}
					}
				}
				.btn{
					width: 1.69rem;
					height: 0.61rem;
					background: linear-gradient(266deg, #910000, #E51A1A);
					border-radius: 0.1rem;
					font-size: 0.24rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
					line-height: 0.61rem;
					margin-top: 0.58rem;
				}
				.btn:hover{
					cursor: pointer;
				}
			}
		}
	}
	
	
	// 移动端 样式
		@media (max-width:1024px){
			.contact{
				width:100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				.banner{
					width: 100%;
					height:10.85rem;
					background:url('../../public/images/banner9.png')no-repeat center center;
					background-size: 100% 10.85rem;
					.title{
						font-size: 0.64rem;
						font-family: Arial;
						font-weight: bold;
						color: #FFFFFF;
						margin-top: 2.31rem;
						margin-left: 0.82rem;
					}
					.text{
						margin-top: 0.54rem;
						width: 3.43rem;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 0.3rem;
						margin-left: 0.82rem;
					}
				}
				.map_box{
					width: 5.8rem;
					height: 9.2rem;
					background-color: #fff;
					box-shadow: 0px 0px 0.08rem 0px rgba(10,10,10,0.15);
					position: absolute;
					top:6.8rem;
					.top{
						width: 100%;
						height: 9.18rem;
					}
					.left{
						width: 3.58rem;
						height: 6.18rem;
						// background-color: red;
						margin-right: 1.82rem;
						margin-left: 0.4rem;
						.title{
							font-size: 0.36rem;
							font-family: Arial;
							font-weight: bold;
							color: #333333;
							margin-top: 0.91rem;
							margin-left: 0.12rem;
						}
						.phone_fax{
							position: absolute;
							top: 1.2rem;
						}
						.phone{
							width: 1.92rem;
							height: 0.26rem;
							// margin-top: 0.66rem;
							margin-bottom: 0.12rem;
							margin-left: -0.05rem;
							p{
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #333333;
							}
							p:nth-child(1){
								width: 0.26rem;
								height: 0.26rem;
								background: url('../../public/smallImg/phone.png') no-repeat center center;
								background-size: 0.26rem 0.26rem;
								margin-right: 0.2rem;
							}
						}
						.fax{
							width: 1.92rem;
							height: 0.26rem;
							margin-left: -0.05rem;
							p{
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #333333;
							}
							p:nth-child(1){
								width: 0.26rem;
								height: 0.26rem;
								background: url('../../public/smallImg/fax.png') no-repeat center center;
								background-size: 0.26rem 0.26rem;
								margin-right: 0.2rem;
							}
						}
						.cen{
							width:2.26rem ;
							height: 0.27rem;
							margin-top: 0.44rem;
							align-items: center;
							margin-bottom: 0.46rem;
							margin-left: 0.12rem;
							img{
								width: 0.27rem;
								height: 0.27rem;
							}
							img:nth-child(2){
								width: 0.12rem;
								height: 0.22rem;
							}
							img:nth-child(3){
								width: 0.24rem;
								height: 0.20rem;
							}
							img:nth-child(4){
								width: 0.22rem;
								height: 0.22rem;
							}
						}
						
					}
					.map{
						width:6.71rem;
						height:3.33rem;
						margin-top: 0.7rem;
						display: none;
					}
					.qr_box{
						// width: 3.56rem;
						position: absolute;
						top: 7rem;
						right: 2.1rem;
						.qr{
							width: 1.64rem;
							height: 1.61rem;
							background-color: #fff;
							img{
								width: 1.64rem;
								height: 1.61rem;
							}
							span{
								margin-top: 0.13rem;
								font-size: 0.14rem;
								font-family: Arial;
								font-weight: 400;
								color: #666666;
							}
						}
						
					}
					.btm{
						width: 5.2rem;
						// height:5rem;
						flex-direction: column;
						.list{
							width: 5.2rem;
							height:1.7rem;
							margin-top: 0.46rem;
							// margin-right: 0.39rem;
							border-bottom:1px solid #CCCCCC;
							.city_address{
								width: 5.2rem;
								.city{
									width: 5.2rem;
									font-size: 0.18rem;
									font-family: Microsoft YaHei;
									font-weight: bold;
									color: #910000;
									margin-bottom: 0.2rem;
									line-height: 0.26rem;
								}
								.city1{
									width: 5.2rem;
									font-size: 0.18rem;
									font-family: Microsoft YaHei;
									font-weight: bold;
									color: #333333;
									margin-bottom: 0.2rem;
									line-height: 0.26rem;
								}
								.address{
									width: 5.2rem;
									p:nth-child(2){
										font-size: 0.16rem;
										font-family: Arial;
										font-weight: 400;
										color: #666666;
										line-height: 0.24rem;
										margin-left: 0.14rem;
									}
								}
							}
							
							.line{
								width: 0.01rem;
								height: 1.14rem;
								background: #F5F5F5;
								border: 0.01rem solid #CCCCCC;
								margin-left: 0.39rem;
							}
						}
						.list:nth-child(2){
							height: 2.2rem;
							margin-top: 0.8rem;
							.address{
								margin-top: 0.9rem;
							}
						}
						.list:last-child{
							border: none;
						}
					}
				}
				// .map_box{
				// 	width: 5.81rem;
				// 	height:100%;
				// 	background-color: #fff;
				// 	box-shadow: 0px 0px 0rem 0px rgba(10,10,10,0.15);
				// 	position: relative;
				// 	top:1rem;
				// 	.top{
				// 		width: 5.85rem;
				// 		height: 6.18rem;
				// 	}
				// 	.left{
				// 		// width: 3.58rem;
				// 		height: 6.18rem;
				// 		// background-color: red;
				// 		margin-right: 0rem;
				// 		margin-left: 0rem;
				// 		.title{
				// 			font-size: 0.36rem;
				// 			font-family: Arial;
				// 			font-weight: bold;
				// 			color: #333333;
				// 			margin-top: 0rem;
				// 			margin-left: 0.12rem;
				// 		}
				// 		.phone{
				// 			width: 5.82rem;
				// 			height: 0.26rem;
				// 			margin-top: 0.54rem;
				// 			margin-bottom: 0.17rem;
				// 			margin-left: 0.12rem;
				// 			justify-content: left;
				// 			p{
				// 				font-size: 0.24rem;
				// 				font-family: Arial;
				// 				font-weight: 400;
				// 				color: #333333;
				// 			}
				// 			p:nth-child(1){
				// 				width: 0.26rem;
				// 				height: 0.26rem;
				// 				background: url('../../public/smallImg/phone.png') no-repeat center center;
				// 				background-size: 0.26rem 0.26rem;
				// 				margin-right: 0.2rem;
				// 			}
				// 		}
				// 		.fax{
				// 			width: 5.82rem;
				// 			height: 0.26rem;
				// 			margin-left: 0.12rem;
				// 			justify-content: left;
				// 			p{
				// 				font-size: 0.24rem;
				// 				font-family: Arial;
				// 				font-weight: 400;
				// 				color: #333333;
				// 			}
				// 			p:nth-child(1){
				// 				width: 0.26rem;
				// 				height: 0.26rem;
				// 				background: url('../../public/smallImg/fax.png') no-repeat center center;
				// 				background-size: 0.26rem 0.26rem;
				// 				margin-right: 0.2rem;
				// 			}
				// 		}
				// 		.cen{
				// 			width:2.26rem ;
				// 			height: 0.27rem;
				// 			margin-top: 0.42rem;
				// 			align-items: center;
				// 			margin-bottom: 0.46rem;
				// 			margin-left: 0.12rem;
				// 			img{
				// 				width: 0.27rem;
				// 				height: 0.27rem;
				// 			}
				// 			img:nth-child(2){
				// 				width: 0.12rem;
				// 				height: 0.22rem;
				// 			}
				// 			img:nth-child(3){
				// 				width: 0.24rem;
				// 				height: 0.20rem;
				// 			}
				// 			img:nth-child(4){
				// 				width: 0.22rem;
				// 				height: 0.22rem;
				// 			}
				// 		}
				// 		.qr_box{
				// 			width: 5.85rem;
				// 			.qr{
				// 				width: 2.73rem;
				// 				height: 2.71rem;
				// 				background-color: #fff;
				// 				img{
				// 					width: 2.73rem;
				// 					height: 2.71rem;
				// 				}
				// 				span{
				// 					margin-top: 0.13rem;
				// 					font-size: 0.24rem;
				// 					font-family: Arial;
				// 					font-weight: 400;
				// 					color: #666666;
				// 				}
				// 			}
							
				// 		}
				// 	}
				// 	.map{
				// 		width:0rem;
				// 		height:0rem;
				// 		margin-top: 0rem;
				// 	}
				// 	.btm{
				// 		width: 5.81rem;
				// 		height:2rem;
				// 		flex-wrap: wrap;
				// 		.list{
				// 			width: 5.81rem;
				// 			margin-right: 0rem;
				// 			.city_address{
				// 				width: 5.81rem;
				// 				padding-bottom: 0.6rem;
				// 				border-bottom: 0.01rem solid #CCCCCC;
				// 				.city{
				// 					width: 5.17rem;
				// 					font-size: 0.24rem;
				// 					font-family: Microsoft YaHei;
				// 					font-weight: bold;
				// 					color: #910000;
				// 					margin-bottom: 0.2rem;
				// 					line-height: 0.26rem;
				// 				}
				// 				.city1{
				// 					width: 5.17rem;
				// 					font-size: 0.24rem;
				// 					font-family: Microsoft YaHei;
				// 					font-weight: bold;
				// 					color: #333333;
				// 					margin-bottom: 0.2rem;
				// 					line-height: 0.26rem;
				// 				}
				// 				.address{
				// 					width: 5.81rem;
				// 					p:nth-child(2){
				// 						font-size: 0.24rem;
				// 						font-family: Arial;
				// 						font-weight: 400;
				// 						color: #666666;
				// 						line-height: 0.24rem;
				// 						margin-left: 0.14rem;
				// 					}
				// 				}
				// 			}
							
				// 			.line{
				// 				width: 0.01rem;
				// 				height: 1.14rem;
				// 				background: #F5F5F5;
				// 				border: 0.01rem solid #CCCCCC;
				// 				margin-left: 0.39rem;
				// 				display: none;
				// 			}
				// 		}
				// 		.list:nth-child(2){
				// 			margin-top: 0.59rem;
				// 			margin-bottom: 0.59rem;
				// 		}
				// 	}
				// }
				.wrap{
					width: 5.8rem;
					margin-top: 3.38rem;
					margin-bottom: 1.51rem;
					
					.left{
						width: 7.83rem;
						height: 5.02rem;
						background: url('../../public/images/map.png') no-repeat center center;
						background-size:7.83rem 5.02rem;
						margin-top: 0.31rem;
						margin-right: 0.47rem;
						display: none;
					}
					.right{
						width: 5.8rem;
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-top: 1.31rem;
						.title{
							font-size: 0.47rem;
							font-family: Arial;
							font-weight: bold;
							color: #1A1A1A;
						}
						.form{
							width: 5.8rem;
							margin-top: 0.49rem;
							.frame_box{
								width: 5.8rem;
								margin-bottom: 0rem;
								flex-wrap: wrap;
								.info{
									width: 5.8rem;
									height: 0.56rem;
									border: 0.01rem solid #CCCCCC;
									margin-bottom: 0.29rem;
									
									span{
										font-size: 0.18rem;
										font-family: Microsoft YaHei;
										font-weight: 400;
										color: #910000;
										margin-left:0.2rem ;
										margin-top: 0.23rem;
									}
									input{
										width: 4.4rem;
										margin-left: 0.21rem;
										height: 0.56rem;
										border:none;
										font-size: 0.18rem;
										font-family: Microsoft YaHei;
										font-weight: 400;
										color: #333;
										line-height: 0.56rem;
									}
								}
							}
							.address{
								width: 5.8rem;
								height: 0.56rem;
								border: 0.01rem solid #CCCCCC;
								margin-bottom: 0.19rem;
								input{
									width: 4.4rem;
									margin-left: 0.48rem;
									height: 0.56rem;
									border:none;
									font-size: 0.18rem;
									font-family: Microsoft YaHei;
									font-weight: 400;
									color: #333;
									line-height: 0.56rem;
								}
							}
							input::-webkit-input-placeholder{
								color: #CCCCCC;
							}
							input::-moz-placeholder{
								color: #CCCCCC;
							}
							input:-moz-placeholder{
								color: #CCCCCC;
							}
							input:-ms-input-placeholder{
								color: #CCCCCC;
							}
							.message{
								width: 5.8rem;
								height: 1.3rem;
								border: 0.01rem solid #CCCCCC;
								span{
									margin-top: 0.22rem;
									margin-left: 0.18rem;
									font-size: 0.18rem;
									font-family: Microsoft YaHei;
									font-weight: 400;
									color: #910000;
								}
								textarea{
									width: 4.8rem;
									margin-left: 0.21rem;
									margin-top: 0.15rem;
									resize:none;
									border:none;
									height: 1rem;
									font-size: 0.18rem;
									font-family: Microsoft YaHei;
									font-weight: 400;
									color: #333;
								}
								textarea::-webkit-input-placeholder{
									color: #CCCCCC;
								}
								textarea::-moz-placeholder{
									color: #CCCCCC;
								}
								textarea:-moz-placeholder{
									color: #CCCCCC;
								}
								textarea:-ms-input-placeholder{
									color: #CCCCCC;
								}
							}
						}
						.btn{
							width: 1.69rem;
							height: 0.61rem;
							background: linear-gradient(266deg, #910000, #E51A1A);
							border-radius: 0.1rem;
							font-size: 0.24rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							text-align: center;
							line-height: 0.61rem;
							margin-top: 0.58rem;
						}
					}
				}
			}
			
			
			
		}
</style>