<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features :pplicableIofo='pplicableIofo'  :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<product-appearance :appearanceList='appearanceList'></product-appearance>
	<!-- 选择插座 -->
	<select-socket></select-socket>
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<support-term :termList='termList'></support-term>
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import selectSocket from '@/components/socket/selectSocket.vue'									//选择插座组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'24V-OPS',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			selectSocket,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/images/12V-OPS.png'),
					title:'24V-OPS Series High Frequency Pure Sine Wave Inverter',
					info:'High frequency pure sine wave output, high power density, reliable performance. 200% surge capacity.',
					id:'6'				//换背景图必传
				},
				productInfo:[
					{
						title:'Operating voltage',
						list:[
							{
								info:" single phase 100-110-120V/ 220-230-240Vac",
								icon:'',
							}
						]
					},
					{
						title:'Operating frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							}
						]
					},
					{
						title:'Range of power',
						list:[
							{
								info:"600W",
								icon:'',
							},{
								info:"1000W",
								icon:'',
							},
							{
								info:"1500W",
								icon:'',
							},
							{
								info:"2000W",
								icon:'',
							},
							{
								info: "3000W",
								icon:'',
							},
							{
								info: "4000W",
								icon:'',
							}
							
						]
					},
				],
				pplicableIcons:[
					// {
					// 	icon:require('../../public/images/net.png'),
					// 	text:'Support solar charging',
					// },
					{
						icon:require('../../public/images/line.png'),
						text:'Real pure sine wave output to protect sensitive loads',
					},
					
					{
						icon:require('../../public/images/line2.png'),
						text:'THD<3% under standard load',
					},
					
				],
				pplicableIofo:[
					{
						title:'',
						list:[
							{
								pic:require('../../public/smallImg/led.png'),
								text:'LED'
							},
							{
								pic:require('../../public/smallImg/fridge.png'),
								text:'Fridge'
							},
							{
								pic:require('../../public/smallImg/tv.png'),
								text:'TV'
							},
							{
								pic:require('../../public/smallImg/computer.png'),
								text:'computer'
							},
							{
								pic:require('../../public/smallImg/loudspeaker.png'),
								text:'Loudspeaker'
							}
						]
					}
				],
				appearanceList:[
					{
						pic:require('../../public/images/12V-OPS1.png'),
						text:'24V-OPS 600W'
					},
					{
						pic:require('../../public/images/12V-OPS2.png'),
						text:'24V-OPS 600W'
					},
					{
						pic:require('../../public/images/12V-OPS3.png'),
						text:'24V-OPS 1000W / 1500W'
					},
					{
						pic:require('../../public/images/12V-OPS4.png'),
						text:'24V-OPS 1000W / 1500W'
					},
					{
						pic:require('../../public/images/12V-OPS5.png'),
						text:'24V-OPS 2000W'
					},
					{
						pic:require('../../public/images/12V-OPS6.png'),
						text:'24V-OPS 2000W'
					},
					{
						pic:require('../../public/images/12V-OPS8.png'),
						text:'24V-OPS 3000W / 4000W'
					},
					{
						pic:require('../../public/images/12V-OPS7.png'),
						text:'24V-OPS 3000W / 4000W'
					}
				],
				priceInfo:[						//技术参数信息
					{
						name:'Inverter Output',
						list:[
							{
								parameterName:'Model',
								parameterList:['OPS 600W','OPS 1000W','OPS 1500W','OPS 2000W','OPS 3000W','OPS 4000W']
							},
							{
								parameterName:'AC Output Operating Voltage Options',
								parameterList:['100-110-120Vac/ 220-230-240Vac']
							},
							{
								parameterName:'Continuous Output Power',
								parameterList:['600W ','1000W','1500W','2000W','3000W','4000W']
							},
							{
								parameterName:'Surge Rating (20s)',
								parameterList:['1200W','2000W','3000W','4000W','6000W','8000W']
							},
							{
								parameterName:'Output Waveform',
								parameterList:['Pure sine wave']
							},
							{
								parameterName:'Output Frequency',
								parameterList:['50/60Hz±0.1%']
							},
							{
								parameterName:'Output Current (220/230/240)',
								parameterList:['2.73A / 2.61 A /2.50A ','4.55A /4.35A /4.17A','6.81A /6.52A /6.25A','9.10A /8.70A /8.34A','13.65A /13.05A /12.51 A','18.18A /1739A /16.67A']
							},
							{
								parameterName:'Output Current (110/115/120)',
								parameterList:['5.45A /5.22A /5A','9.09A /8.70A /8.33A','13.63A Z13.04A /12.50A','18.18A /17.39A /16.67A']
							},
							{
								parameterName:'Peak Output Current (220/230/240)',
								parameterList:['5.46A /5.22A /5.00A' , '9.10A /8.70A /8.34A' , '13.62A /13.04A /12.50A' , '18.20A /17.40A /16.68A' , '273A / 26.1 A /25.02A' , '36.36A /34.78A /33.34A']
							},
							{
								parameterName:'Peak Output Current (110/115/120)',
								parameterList:['10.92A /10.44A /10A' , '182A /17.4A /16.68A' , '27.3A /26.1A /25A' , '36.36A /34.78A /33.34A']
							},
							{
								parameterName:'Stand-by Current Draw',
								parameterList:['<6W','<10W']
							},
							{
								parameterName:'Nominal Efficiency',
								parameterList:['>88% (typical), 90% (peak)']
							},
							{
								parameterName:'Output Voltage Regulation',
								parameterList:['±5% RMS']
							},
							{
								parameterName:'Typical transfer Time',
								parameterList:['10ms ( Max )']
							},
							{
								parameterName:'THD',
								parameterList:['<15%']
							}
							
						]
					},
					{
						name:'DC Input',
						list:[
							{
								parameterName:'Nominal Input Voltage',
								parameterList:['240Vdc']
							},
							{
								parameterName:'Operating range',
								parameterList:['20Vdc~30.2Vdc']
							},
							{
								parameterName:'Operating range',
								parameterList:['23.5Vdc~29.6Vdc']
							}
						]
					},
					{
						name:'Control',
						list:[
							{
								parameterName:'Protection',
								parameterList:['Overload, Short circuits, Reverse polarity. Over/ under input voltage, Over temperature, High output voltage, Low output voltage, Unit internal failure. Unit in-parallel failure']
							},
							{
								parameterName:'Startup time',
								parameterList:['< 5 Seconds']
							},
							{
								parameterName:'Power Saving Recovery Time',
								parameterList:['5 Seconds']
							}
						]
					},
					{
						name:'Mechanical Specifications',
						list:[
							{
								parameterName:'Mounting',
								parameterList:['Wall mount']
							},
							{
								parameterName:"Dimensions (mm / inch)",
								parameterList:['275*160*70 / 10.8*6.3*2.8' , '371*200*95 / 14.6*7.9*3.7' , '428*285*108 / 16.9*11.2*4.3' , '428*285*121 / 16.9*11.2*4.8']
							},
							{
								parameterName:'Net Weight (kg)',
								parameterList:['2.5','4','4.5','8','8.8']
							}
						]
					}
				],
				termList:[						//支持项数据
					{
						title:'Gel USA',
						fast:'14.0',
						float:'13.7'
					},
					{
						title:'AGM 1',
						fast:'14.1',
						float:'13.4'
					},
					{
						title:'Lithium',
						fast:'13.8',
						float:'13.6'
					},
					{
						title:'Sealed Lead Acid',
						fast:'14.4',
						float:'13.6'
					},
					{
						title:'Gel EURO',
						fast:'14.4',
						float:'13.8'
					},
					{
						title:'Open Lead Acid',
						fast:'14.0',
						float:'13.8'
					},
					{
						title:'LifePO4',
						fast:'15.5 (4 Hours then Off)',
						float:''
					},
					{
						title:'De-sulphation',
						fast:'13.6',
						float:'13.5'
					}
				],
				otherList:[
					{
						img:require('../../public/otherImg/24V-HP.png'),
						url:'/24V-HP'
					},
					{
						img:require('../../public/otherImg/24V-APC-MINI.png'),
						url:'/24V-APCMini'
					},
					{
						img:require('../../public/otherImg/24V-AP.png'),
						url:'/24V-AP'
					}
				]
			}
		},
		mounted() {
			// console.log(this.pplicableIcons)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
