<template>
	<div class='productCenter flex_box_column_cen'>
		<!-- 产品 -->
		<div class="wrap flex_box_bet">
			<div class="wrap_left" ref="basicInformation">
				<div class="box" v-for="(item,index) in productList" :key="index" >
					<div class="title flex_box_align_cen">
						<div :class="item.id==1?'left':item.id==2?'left1':item.id==3?'left2':item.id==4?'left3':item.id==5?'left4':''">
							
						</div>
						<div :ref="'item'+index" class="text flex_box_column">
							<span>product</span>
							<p>{{item.title}}</p>
						</div>
					</div>
					<ul>
						<li class="flex_box_column_cen" v-for="(itemson,indexson) in item.list" :key="indexson">
							<div class="pic">
								<img :src="itemson.pic" alt="">
							</div>
							<div class="text flex_box_column">
								{{itemson.name}}
							</div>
							<div class="btn_box">
								<div class="btn" @click="go(itemson.url)">
									view more
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="right_box flex_box_column">
				<div class="nav" v-for="(item,index) in productList" :key="index" @click="onTouch(index)">{{item.title}}</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import WOW from 'wowjs/dist/wow'
	export default{
		props: {
			productList:{
				type: Array,
				default(){
					return []
				}
			}
		},
		data() {
			return{
				i:0,
			}
		},
		created() {
			
		},
		mounted() {
			var type = this.$route.params.id
			if(type){
				this.onTouch(type)
			}
			
			this.$nextTick(()=>{
				var wow = new WOW.WOW({
					boxClass:'wow',
					animateClass:'animated',
					offset:0,
					mobile:true,
					live :false
				})
				wow.init()
			})
		},
		methods:{
			go(url){
				this.$router.push(url)
				document.documentElement.scrollTop = 0
			},
			onTouch(_index){
				this.i = _index
				this.$refs[`item${_index}`][0].scrollIntoView(
					{
						behavior: "smooth",  // 平滑过渡
						block: "start" 		 // 上边框与视窗顶部平齐。默认值
					}
				);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.productCenter{
		width: 100%;
		.wrap{
			width: 16.36rem;
			// height: 5rem;
			// height: 100%;
			margin-top: 1.85rem;
			position: relative;
			.wrap_left{
				width: 11.78rem;
				height:11.78rem;
				overflow: scroll;
				
				.box{
					// width: 16.36rem;
					width: 11.78rem;
					margin-left: 0.02rem;
					.title{
						width: 100%;
						background-color: #fff;
						margin-bottom: 0.53rem;
						.left{
							width: 0.36rem;
							height: 0.39rem;
							background: url('../../../public/smallImg/battery.png') no-repeat center center;
							background-size:0.36rem 0.39rem;
							margin-left: 0.2rem
						}
						.left1{
							width: 0.4rem;
							height: 0.39rem;
							background: url('../../../public/smallImg/battery1.png') no-repeat center center;
							background-size:0.4rem 0.39rem;
							margin-left: 0.2rem
						}
						.left2{
							width: 0.4rem;
							height: 0.39rem;
							background: url('../../../public/smallImg/battery2.png') no-repeat center center;
							background-size:0.4rem 0.39rem;
							margin-left: 0.2rem
						}
						.left3{
							width: 0.4rem;
							height: 0.39rem;
							background: url('../../../public/smallImg/battery3.png') no-repeat center center;
							background-size:0.4rem 0.39rem;
							margin-left: 0.2rem
						}
						.left4{
							width: 0.35rem;
							height: 0.34rem;
							background: url('../../../public/smallImg/application.png') no-repeat center center;
							background-size:0.35rem 0.34rem;
							margin-left: 0.2rem
						}
						.text{
							font-size: 0.16rem;
							height: 0.55rem;
							font-family: Arial;
							font-weight: 400;
							color: #666666;
							justify-content: space-between;
							margin-left: 0.16rem;
							
							span{
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #666666;
								margin-left: 0.07rem;
							}
							p{
								font-size: 0.3rem;
								font-family: Arial;
								font-weight: bold;
								color: #4D4D4D;
							}
						}
					}
					
					ul{
						// width: 16.56rem;
						width: 11.78rem;
						display: flex;
						// justify-content: space-between;
						flex-wrap: wrap;
						li{
							width: 3.9rem;
							height: 5.6rem;
							box-shadow: 0px 0px 0px 0px rgba(4,0,0,0.2);
							transition: box-shadow 0.3s;
							margin-bottom: 0.4rem;
							.pic{
								width: 3.5rem;
								height: 3.5rem;
								background: #F4F4F4;
								margin-top: 0.2rem;
								position: relative;
								img{
									width: 3.5rem;
									position: absolute;
									bottom: 0;
								}
							}
							.text{
								width: 3.35rem;
								height: 0.47rem;
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: 400;
								color: #808080;
								line-height: 0.3rem;
								margin-top: 0.31rem;
								text-align: left;
							}
							.btn_box{
								width: 3.4rem;
								.btn{
									width: 1.6rem;
									height: 0.44rem;
									border: 0.01rem solid #C0C2C4;
									border-radius: 0.08rem;
									font-size: 0.2rem;
									font-family: Arial;
									font-weight: bold;
									color: #808080;
									text-align: center;
									line-height: 0.44rem;
									margin-top: 0.3rem;
								}
								.btn:hover{
									cursor:pointer;
								}
							}
							
						}
						li:hover{
							box-shadow: 0px 2px 9px 1px rgba(4,0,0,0.2);
							.btn{
								background: linear-gradient(160deg, #E10019 0%, #910000 100%);
								color: #FFFFFF;
							}
						}
					}
				}
			}
			
			.right_box{
				width: 4.36rem;
				height: 100%;
				// background-color: red;
				border-left: 0.01rem solid #CCCCCC;
				position: absolute;
				right: 0;
				top: 0;
				.nav{
					margin-left: 0.62rem;
					font-size: 0.24rem;
					font-family: Arial;
					font-weight: bold;
					color: #666666;
					margin-top: 0.91rem;
				}
				.nav:hover{
					margin-left: 0.62rem;
					font-size: 0.24rem;
					font-family: Arial;
					font-weight: bold;
					color:  #910000;
					margin-top: 0.91rem;
					cursor:pointer;
				}
				// .nav1{
				// 	margin-left: 0.62rem;
				// 	font-size: 0.24rem;
				// 	font-family: Arial;
				// 	font-weight: bold;
				// 	color: #910000;
				// 	margin-top: 0.91rem;
				// }
			}
		}
		
	}
	// 移动端 样式
	@media (max-width:1024px){
		.productCenter{
			width: 100%;
			.wrap{
				width: 5.84rem;
				// height: 5rem;
				margin-top: 1.85rem;
				.wrap_left{
					width: 5.84rem;
					height: 100%;
					overflow: visible;
					.box{
						width: 5.84rem;
						.title{
							width: 100%;
							background-color: #fff;
							margin-bottom: 0.53rem;
							.left{
								width: 0.36rem;
								height: 0.39rem;
								background: url('../../../public/smallImg/battery.png') no-repeat center center;
								background-size:0.36rem 0.39rem;
								margin-left: 0.2rem
							}
							.left1{
								width: 0.4rem;
								height: 0.39rem;
								background: url('../../../public/smallImg/box.png') no-repeat center center;
								background-size:0.4rem 0.39rem;
								margin-left: 0.2rem
							}
							.text{
								font-size: 0.16rem;
								height: 0.55rem;
								font-family: Arial;
								font-weight: 400;
								color: #666666;
								justify-content: space-between;
								margin-left: 0.16rem;
								
								span{
									font-size: 0.16rem;
									font-family: Arial;
									font-weight: 400;
									color: #666666;
									margin-left: 0.07rem;
								}
								p{
									font-size: 0.3rem;
									font-family: Arial;
									font-weight: bold;
									color: #4D4D4D;
								}
							}
						}
						
						ul{
							width: 5.84rem;
							display: flex;
							// justify-content: space-between;
							flex-wrap: wrap;
							li{
								width: 2.72rem;
								height: 4.6rem;
								box-shadow: 0px 0px 0px 0px rgba(4,0,0,0.2);
								transition: box-shadow 0.3s;
								margin-bottom: 0.4rem;
								margin-left: 0.2rem;
								.pic{
									width: 2.72rem;
									height: 2.72rem;
									background: #F4F4F4;
									margin-top: 0.2rem;
									position: relative;
									img{
										width: 2.72rem;
										// height: 2.72rem;
										position: absolute;
										bottom: 0;
									}
								}
								.text{
									width: 2.72rem;
									height: 0.47rem;
									font-size: 0.18rem;
									font-family: Arial;
									font-weight: 400;
									color: #808080;
									line-height: 0.3rem;
									margin-top: 0.31rem;
									text-align: left;
								}
								.btn_box{
									width: 2.72rem;
									.btn{
										width: 1.6rem;
										height: 0.44rem;
										border: 0rem solid #C0C2C4;
										border-radius: 0.08rem;
										font-size: 0.2rem;
										font-family: Arial;
										font-weight: bold;
										color: #808080;
										text-align: center;
										line-height: 0.44rem;
										margin-top: 0.3rem;
										background: linear-gradient(160deg, #E10019 0%, #910000 100%);
										color: #FFFFFF;
									}
								}
								
							}
							li:hover{
								box-shadow: 0px 0px 0px 0px rgba(4,0,0,0.2);
								.btn{
									background: linear-gradient(160deg, #E10019 0%, #910000 100%);
									color: #FFFFFF;
								}
							}
						}
					}
				}
				
				.right_box{
					display: none;
				}
			}
		}
	}
</style>