<template>
	<!-- 技术参数信息 -->
	<div class="technicalParameters flex_box_column_cen">
		<div class="technical_wrap">
			<div class="title wow fadeInUp">
				Technical Parameters
			</div>
			<div class="list_box flex_box_column_cen">
				<div class="box" v-for="(item,index) in priceInfo" :key="index">
					<div class="box_tit">
						{{item.name}}
					</div>
					<ul class="list">
						<li class=" flex_box_bet" v-for="(itemson,indexson) in item.list" :key="indexson">
							<div class="name">
								{{itemson.parameterName}}
							</div>
							<div class="price_box " :class="itemson.parameterList.length>1?'flex_box_bet':'flex_box_cen_align_cen'"
							:style="itemson.parameterList.length==2?'justify-content:flex-start;':''">
								<div class="price " v-for="(k,i) in itemson.parameterList" :key="i"
								:class="itemson.parameterList.length==1?'price1':''"
								:style="itemson.parameterList.length==2?'width:100%; margin-right:0.2rem;':''">
									<span :class="itemson.parameterList.length==2?'text2':''">{{k}}</span>
								</div>
							</div>
							
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from 'wowjs/dist/wow'
	export default{
		props: {
			priceInfo: {
				type: Array,
				default(){
					return []
				}
			}
			
		},
		data() {
			return{
				
			}
		},
		mounted() {
			this.$nextTick(()=>{
				var wow = new WOW.WOW({
					boxClass:'wow',
					animateClass:'animated',
					offset:0,
					mobile:true,
					live :false
				})
				wow.init()
			})
		},
	}
</script>

<style lang="scss" scoped>
	.technicalParameters{
		width: 100%;
		.technical_wrap{
			width: 15.37rem;
			
			
			.title{
				width: 100%;
				font-size: 0.36rem;
				font-family: Arial;
				font-weight: bold;
				color: #5C6079;
				margin-top: 1.02rem;
			}
			.list_box{
				width: 100%;
				margin-top: 0.65rem;
				.box{
					
					width: 14.21rem;
					.box_tit{
						height: 0.59rem;
						width: 13.81rem;
						padding-left: 0.4rem;
						font-size: 0.24rem;
						font-family: Arial;
						font-weight: bold;
						color: #333333;
						line-height: 0.59rem;
						border-bottom: 0.01rem solid #CCCCCC;
					}
					.list{
						width: 14.21rem;
						li{
							width: 13.81rem;
							height: 0.59rem;
							padding-left: 0.4rem;
							// padding-right: 0.51rem;
							border-bottom: 0.01rem solid #CCCCCC;
							font-size: 0.16rem;
							font-family: Arial;
							font-weight: 400;
							color: #4D4D4D;
							line-height: 0.59rem;
							text-align: center;
							display: flex;
							
							.name{
								// width: 4.6rem;
								width: 3.5rem;
								text-align: left;
							}
							.price_box{
								
								align-items: center;
								
								width: 10.3rem;
								height: 0.59rem;
								.price{
									// min-width: 1.03rem;
									min-width: 0.03rem;
									// min-width: 1.144rem;
									text-align: left;
								}
								.price1{
									text-align: center;
									line-height: 0.25rem;
								}
							}
						}
					}
				}
			}
		}
	}
	
	// 移动端 样式
		@media (max-width:1024px){
			.technicalParameters{
				width: 100%;
				.technical_wrap{
					width: 5.71rem;
					
					
					.title{
						width: 100%;
						font-size: 0.36rem;
						font-family: Arial;
						font-weight: bold;
						color: #5C6079;
						margin-top: 1.02rem;
					}
					.list_box{
						width: 100%;
						margin-top: 0.65rem;
						.box{
							
							width: 5.71rem;
							.box_tit{
								height: 0.59rem;
								width: 5.71rem;
								padding-left: 0rem;
								font-size: 0.24rem;
								font-family: Arial;
								font-weight: bold;
								color: #333333;
								line-height: 0.59rem;
								border-bottom: 0.01rem solid #CCCCCC;
							}
							.list{
								width: 5.71rem;
								li{
									width: 5.71rem;
									// height: 0.59rem;
									height: 100%;
									padding-left: 0rem;
									border-bottom: 0.01rem solid #CCCCCC;
									font-size: 0.16rem;
									font-family: Arial;
									font-weight: 400;
									color: #4D4D4D;
									text-align: left;
									display: flex;
									
									
									.name{
										display: flex;
										align-items: center;
										line-height: 0.17rem;
										width: 2.07rem;
										margin-right: 0.09rem;
									}
									.price_box{
										align-items: center;
										flex-wrap: wrap;
										justify-content: flex-start;
										width: 100%;
										min-height: 0.75rem;
										text-align: center;
										.price{
											min-width: 0.8rem;
											min-height: 0.12rem;
											display: flex;
											align-items: center;
											text-align: center;
											margin-right: 0.15rem;
											.text2{
												height: 0.12rem;
												width:100%;
												display: flex;
												align-items: center;
												justify-content: center;
											}
											span{
												min-height: 0.12rem;
												line-height: 0.19rem;
												text-align: left;
												// width:2.56rem;
												// display: flex;
												// text-align: left;
												// align-items: flex-start;
												// justify-content: center;
											}
										}
										.price1{
											display: flex;
											justify-content: center;
											align-items: center;
											width: 100%;
											text-align: center;
											line-height: 0.25rem;
											min-width: 1.35rem;
											
											span{
												// height: 0.12rem;
												width:2.56rem;
												display: flex;
												text-align: left;
												align-items: flex-start;
												justify-content: center;
											}
										}
										
									}
								}
							}
						}
					}
				}
			}
		}
</style>