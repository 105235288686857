<template>
	<div class="product flex_box_column_cen">
		<div class="product_wrap">
			<div class="product_wrap_top flex_box_around">
				<div class="product_pic wow fadeInLeft">
					<img :src="productIntroduce.pic">
				</div>
				<div class="product_name">
					<div class="title  wow fadeInUp">
						{{productIntroduce.title}}
					</div>
					<div class="info wow fadeInUp">
						{{productIntroduce.info}}
					</div>
					<div class="btn wow fadeInLeft">
						READ MORE
					</div>
				</div>
			</div>
			<div class="product_wrap_cen">
				<!-- 4条数据 -->
				<ul class="flex_box_bet" v-if="productInfo.length<=4">
					<li class="li1 flex_box_column_cen wow fadeInRight " v-for="(item,index) in productInfo" :key="index">
						<div class="top"></div>
						<div class="title wow fadeInUp">{{item.title}}</div>
						<div class="small_box" v-if="item.list.length<=7">
							<div class="num flex_box_column_cen wow fadeInUp" v-for="(itemson,indexson) in item.list" :key='indexson'>
								<p class="flex_box_column_align_cen wow fadeInUp">
									<img :src="itemson.icon" alt="" v-if="itemson.icon != ''">
									{{itemson.info}}
								</p> 
							</div>
						</div>
						<div class="small_box2" v-else>
							<div>
								<div class="num1 wow fadeInUp" v-for="(itemson,indexson) in item.list.slice(0,7)" :key='indexson'>
									<p class="flex_box_column_align_cen wow fadeInUp">
										<img :src="itemson.icon" alt="" v-if="itemson.icon != ''">
										{{itemson.info}}
									</p> 
								</div>
							</div>
							<div>
								<div class="num1 wow fadeInUp" v-for="(itemson,indexson) in item.list.slice(7)" :key='indexson'>
									<p class="flex_box_column_align_cen wow fadeInUp">
										<img :src="itemson.icon" alt="" v-if="itemson.icon != ''">
										{{itemson.info}}
									</p> 
								</div>
							</div>
						</div>
					</li>
				</ul>
				<!-- 多余4条数据 -->
				<ul class="flex_box_bet" v-else>
					<li class="li2 flex_box_column_cen wow fadeInRight " v-for="(item,index) in productInfo" :key="index">
						<div class="top"></div>
						<div class="title wow fadeInUp">{{item.title}}</div>
						<div class="small_box" v-if="item.list.length<=7">
							<div class="num flex_box_column_cen wow fadeInUp" v-for="(itemson,indexson) in item.list" :key='indexson'>
								<p class="flex_box_column_align_cen wow fadeInUp">
									<img :src="itemson.icon" alt="" v-if="itemson.icon != ''">
									{{itemson.info}}
								</p> 
							</div>
						</div>
						<div class="small_box2" v-else>
							<div>
								<div class="num1 wow fadeInUp" v-for="(itemson,indexson) in item.list.slice(0,7)" :key='indexson'>
									<p class="flex_box_column_align_cen wow fadeInUp">
										<img :src="itemson.icon" alt="" v-if="itemson.icon != ''">
										{{itemson.info}}
									</p> 
								</div>
							</div>
							<div>
								<div class="num1 wow fadeInUp" v-for="(itemson,indexson) in item.list.slice(7)" :key='indexson'>
									<p class="flex_box_column_align_cen wow fadeInUp">
										<img :src="itemson.icon" alt="" v-if="itemson.icon != ''">
										{{itemson.info}}
									</p> 
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<!-- 产品背景 -->
			<div class="product_wrap_btm" v-if='!productIntroduce.id'>
				<div class="bg1 flex_box_column">
					<div class="bg1_top wow fadeInUp">PRODUCT FEATURES</div>
					<div class="bg1_cen wow fadeInUp">Extended surge rating</div>
					<div class="bg1_btm wow fadeInUp">It can hold 300% overload within 20s, </br>
						providing necessary current for starting electrical</br>
						appliances with high requirements
					</div>
				</div>
			</div>
			<!-- 产品背景1 -->
			<div class="product_wrap_btm1" v-if='productIntroduce.id == 1'>
				<div class="bg1 flex_box_column">
					<div class="bg1_top wow fadeInUp">PRODUCT FEATURES</div>
					<div class="bg1_cen wow fadeInUp">Extended surge rating</div>
					<div class="bg1_btm wow fadeInUp">It can hold 300% overload within 20s, </br>
						providing necessary current for starting electrical</br>
						appliances with high requirements
					</div>
				</div>
				<div class="bg1 bg2 flex_box_column">
					<div class="bg1_top wow fadeInUp">PRODUCT</div>
					<div class="bg1_cen wow fadeInUp">Remote monitoring by APP</div>
					<div class="bg1_btm wow fadeInUp">
						Through the expansion of WIFI module to remotely</br>
						 check the system running status.
					</div>
					<div class="btn wow fadeInUp">
						<div class="btn_left">WIFI-APP</div>
						<div class="btn_right"></div>
					</div>
				</div>
			</div>
			<!-- 产品背景2 -->
			<div class="product_wrap_btm2" v-if='productIntroduce.id == 2'>
				<div class="bg1 flex_box_column">
					<div class="bg1_top wow fadeInUp">PRODUCT FEATURES</div>
					<div class="bg1_cen wow fadeInUp">Reservation Enable</div>
					<div class="bg1_btm wow fadeInUp" style="margin-left: 0.65rem;">
						can set power consumption strategy according to the local grid tariffs between the peak time and the valley time
					</div>
					<div class="bg_pic"></div>
				</div>
				<div class="bg1 bg2 flex_box_column">
					<div class="bg1_top wow fadeInUp">PRODUCCT FEATURES</div>
					<div class="bg1_cen wow fadeInUp">Flexible Application</div>
					<div class="bg1_btm wow fadeInUp flex_box" style="margin-top: 0.34rem;">
						<div class='bg1_btm_left'>
							<div class="bg1_btm_tit">• Backup Power Mode</div>
							<div class="bg1_btm_txt">Provides protection for critical loads in the event of a grid fault (switching time 6-8ms)</div>
							<img src="../../../public/images/use-pic1.png">
						</div>
						
						<div class='bg1_btm_left'>
							<div class="bg1_btm_tit">• Solar generation Mode</div>
							<div class="bg1_btm_txt">With MPPT and solar panels, solar energy can be used to power loads and battery packs.</div>
							<img src="../../../public/images/use-pic2.png">
						</div>
					</div>
					
				</div>
			</div>
			<!-- 产品背景3 -->
			<div class="product_wrap_btm3" v-if='productIntroduce.id == 3'>
				<div class="bg1 flex_box_column">
					<div class="bg1_top wow fadeInUp">PRODUCT FEATURES</div>
					<div class="bg1_cen wow fadeInUp">Built-in MPPT</div>
					<div class="bg1_btm wow fadeInUp">Support solar charging</div>
				</div>
				<div class="bg1 bg3 flex_box_column" :class="productIntroduce.id==3?'bg3':'bg2'">
					<div class="bg1_top wow fadeInUp">PRODUCT</div>
					<div class="bg1_cen wow fadeInUp">{{productIntroduce.id==3?'Support a variety of batteries':'Extended surge rating'}}</div>
					<div class="bg1_btm wow fadeInUp" v-if="productIntroduce.id==3">
						House battery types supported: Flooded / AGM / Gel Battery
					</div>
					<div class="bg1_btm wow fadeInUp" v-else>
						It can hold 200% overload within 20s, </br>
						providing necessary current for starting electrical</br>
						appliances with high requirements
					</div>
				</div>
			</div>
			<!-- 产品背景4 -->
			<div class="product_wrap_btm4" v-if='productIntroduce.id == 4'>
				<div class="bg1 flex_box_column">
					<div class="bg1_top wow fadeInUp">PRODUCT FEATURES</div>
					<div class="bg1_cen wow fadeInUp">String technology</div>
					<div class="bg1_btm wow fadeInUp">String technology AC coupling for improved battery safety and performance</div>
				</div>
			</div>
			<!-- 产品背景5 -->
			<div class="product_wrap_btm5" v-if='productIntroduce.id == 5'>
				<div class="bg1 bg2 bg5 flex_box_column">
					<div class="bg1_top wow fadeInUp">PRODUCCT FEATURES</div>
					<div class="bg1_cen wow fadeInUp">Hybrid Power Supply</div>
					<div class="bg1_btm wow fadeInUp flex_box" style="margin-top: 0.34rem;">
						<div class='bg1_btm_left'>
							<div class="bg1_btm_tit"></div>
							<div class="bg1_btm_txt">Provides protection for critical loads in the event of a grid fault (switching time 6-8ms)</div>
							<img src="../../../public/48V-UPV/hps.png">
						</div>
					</div>
				</div>
				<div class="bg1 bg2 bg5 flex_box_column">
					<div class="bg1_top wow fadeInUp">PRODUCCT FEATURES</div>
					<div class="bg1_cen wow fadeInUp">Reservation Enable</div>
					<div class="bg1_btm wow fadeInUp flex_box" style="margin-top: 0.34rem;">
						<div class='bg1_btm_left'>
							<div class="bg1_btm_tit"></div>
							<div class="bg1_btm_txt">With MPPT and solar panels, solar energy can be used to power loads and battery packs.</div>
							<img src="../../../public/48V-UPV/re.png">
						</div>
					</div>
				</div>
			</div>
			<!-- 产品背景6 -->
			<div class="product_wrap_btm3" v-if='productIntroduce.id == 6'>
				
				<div class="bg1 bg3 flex_box_column" :class="productIntroduce.id==3?'bg3':'bg2'">
					<div class="bg1_top wow fadeInUp">PRODUCT</div>
					<div class="bg1_cen wow fadeInUp">{{productIntroduce.id==3?'Support a variety of batteries':'Extended surge rating'}}</div>
					<div class="bg1_btm wow fadeInUp" v-if="productIntroduce.id==3">
						House battery types supported: Flooded / AGM / Gel Battery
					</div>
					<div class="bg1_btm wow fadeInUp" v-else>
						It can hold 200% overload within 20s, </br>
						providing necessary current for starting electrical</br>
						appliances with high requirements
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props: {
			productIntroduce: {
				type: Object,
				default(){}
			},
			productInfo:{
				type: Array,
				default(){
					return []
				}
			}
		},
		data() {
			return{
				
			}
		},
		created() {
			// console.log(this.productInfo)
		},
		mounted() {
			
			this.$nextTick(()=>{
				var wow = new WOW.WOW({
					boxClass:'wow',
					animateClass:'animated',
					offset:0,
					mobile:true,
					live :false
				})
				wow.init()
			})
		},
		// watch: {
		// 	productIntroduce:{
		// 		immediate:true,
		// 		handler(newValue,oldValue){
		// 			console.log(newValue,oldValue)
		// 		}
		// 	}
		// },
		mounted() {
			// console.log(this.productInfo)
		},
	}
</script>

<style lang="scss" scoped>
	.product{
		width: 100%;
		.product_wrap{
			width: 15.36rem;
			
			.product_wrap_top{
				height: 5.53rem;
				margin-top: 1.36rem;
				.product_pic{
					// width: 4.33rem;
					max-width: 7rem;
					max-height:7rem;
					img{
						max-width: 7rem;
						max-height:7rem;
					}
				}
				.product_name{
					width: 56%;
					.title{
						font-size: 0.36rem;
						font-family: Arial;
						font-weight: bold;
						color: #333333;
						margin-top: 0.68rem;
						margin-bottom: 0.47rem;
					}
					.info{
						width: 80%;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #333333;
						line-height: 0.3rem;
					}
					.btn{
						width: 1.73rem;
						height: 0.54rem;
						background: #5C6079;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 0.54rem;
						text-align: center;
						margin-top: 0.77rem;
					}
				}
			}
			.product_wrap_cen{
				height: 4.6rem;
				margin-top: 1.3rem;
				ul{
					li{
						// width: 2.9rem;
						height: 4.6rem;
						background: #FFFFFF;
						border: 0.01rem solid #FFFFFF;
						box-shadow: 0rem 0.01rem 0.1rem 0rem rgba(0,0,0,0.08);
						.top{
							width: 100%;
							height: 0.06rem;
							background-color:#5C6079 ;
						}
						.title{
							width: 93%;
							text-align: center;
							padding-top: 0.45rem;
							padding-bottom: 0.25rem;
							
							font-size: 0.24rem;
							font-family: Arial;
							font-weight: bold;
							color: #5C6079;
						}
						// .num{
						// 	width: 93%;
						// 	padding-top: 0.1rem;
						// 	padding-bottom: 0.1rem;
						// 	border-top: 0.01rem solid #E0E0E0;
							
						// 	p{
						// 		width: 80%;
						// 		text-align: center;
						// 		font-size: 0.18rem;
						// 		color: #333333;
						// 		line-height: 0.3rem;
						// 	}
						// }
						
						.small_box{
							// width: 93%;
							width: 78%;
							.num{
								width: 100%;
								padding-top: 0.1rem;
								padding-bottom: 0.1rem;
								border-top: 0.01rem solid #E0E0E0;
								
								p{
									width: 80%;
									text-align: center;
									font-size: 0.18rem;
									color: #333333;
									line-height: 0.3rem;
								}
							}
						}
						.small_box2{
							width: 85%;
							display: flex;
							justify-content: space-between;
							.num1{
								width: 1.2rem;
								border-top: 0.01rem solid #E0E0E0;
								padding-top: 0.1rem;
								padding-bottom: 0.1rem;
								// padding-left: 0.35rem;
								// padding-right: 0.35rem;
								p{
									// width: 80%;
									text-align: center;
									font-size: 0.18rem;
									color: #333333;
									line-height: 0.3rem;
								}
							}
						}
						
					}
					.li1{
						width: 3.6rem;
					}
					.li2{
						width: 2.9rem;
					}
					.li1:nth-child(1){
						.num{
							width: 93%;
							padding-top: 0.25rem;
							padding-bottom: 0.25rem;
							border-top: 0.01rem solid #E0E0E0;
							
							p{
								width: 80%;
								text-align: center;
								font-size: 0.18rem;
								color: #333333;
								line-height: 0.3rem;
							}
						}
					}
					.li1:nth-child(2){
						.num{
							width: 93%;
							padding-top: 0.25rem;
							padding-bottom: 0.25rem;
							border-top: 0.01rem solid #E0E0E0;
							
							p{
								width: 80%;
								text-align: center;
								font-size: 0.18rem;
								line-height: 0.3rem;
								font-family: Arial;
								font-weight: bold;
								color: #5C6079;
							}
						}
					}
					.li1:nth-child(3){
						.num{
							p{
								flex-direction: row;
								width: 84%;
								justify-content: flex-start;
								
								img{
									width: 0.28rem;
									height: 0.2rem;
									margin-right: 0.17rem;
								}
							}
						}
					}
					.li1:nth-child(1){
						.num{
							width: 93%;
							padding-top: 0.25rem;
							padding-bottom: 0.25rem;
							border-top: 0.01rem solid #E0E0E0;
							
							p{
								width: 80%;
								text-align: center;
								font-size: 0.18rem;
								color: #333333;
								line-height: 0.3rem;
							}
						}
					}
					.li2:nth-child(3){
						.num{
							padding-top: 0.25rem;
							padding-bottom: 0.25rem;
	
							p{
								width: 80%;
								text-align: center;
								font-size: 0.18rem;
								line-height: 0.3rem;
								font-family: Arial;
								font-weight: bold;
								color: #5C6079;
							}
						}
					}
					.li2:nth-child(4){
						.num{
							p{
								flex-direction: row;
								width: 88%;
								justify-content: flex-start;
								
								img{
									width: 0.28rem;
									height: 0.2rem;
									margin-right: 0.17rem;
								}
							}
						}
					}
				}
			}
			.product_wrap_btm{
				width: 100%;
				margin-top: 0.56rem;
				
				.bg1{
					height: 5.7rem;
					background: url('../../../public/images/bg1.png') no-repeat center center; 
					background-size:15.36rem 5.7rem ;
					.bg1_top{
						margin-top: 1.74rem;
						margin-left: 6%;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: bold;
						color: #F5F5F5;
					}
					.bg1_cen{
						margin-top: 0.52rem;
						margin-left: 6%;
						font-size: 0.3rem;
						font-family: Arial;
						font-weight: bold;
						color: #F5F5F5;
					}
					.bg1_btm{
						line-height: 0.3rem;
						margin-top: 0.31rem;
						margin-left: 6%;
						font-size: 0.18rem;
						color: #F5F5F5;
					}
				}
				.bg3{
					background: url('../../../public/images/bg2.png') no-repeat center center; 
					background-size:15.36rem 5.7rem ;
					margin-top: 0.34rem;
					.bg1_top{
						color: #666666;
						margin-left: 6.5%;
					}
					.bg1_cen{
						color: #666666;
					}
					.bg1_btm{
						color: #666666;
					}
					.btn{
						margin-left: 6%;
						width: 1.82rem;
						height: 0.45rem;
						margin-top: 0.62rem;
						border: 0.01rem solid #2F3146;
						display: flex;
						.btn_left{
							width: 1.38rem;
							height: 0.45rem;
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 0.45rem;
							text-align: center;
							background-color: #2F3146;
						}
						.btn_right{
							width:0.44rem ;
							height: 0.45rem;
							background: url('../../../public/smallImg/arrow.png') no-repeat center center;
							background-size:0.15rem 0.15rem;
						}
					}
				}
			}
			.product_wrap_btm1{
				width: 100%;
				margin-top: 0.56rem;
				
				.bg1{
					height: 5.7rem;
					background: url('../../../public/images/bg1.png') no-repeat center center; 
					background-size:15.36rem 5.7rem ;
					.bg1_top{
						margin-top: 1.74rem;
						margin-left: 6%;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: bold;
						color: #F5F5F5;
					}
					.bg1_cen{
						margin-top: 0.52rem;
						margin-left: 6%;
						font-size: 0.3rem;
						font-family: Arial;
						font-weight: bold;
						color: #F5F5F5;
					}
					.bg1_btm{
						line-height: 0.3rem;
						margin-top: 0.31rem;
						margin-left: 6%;
						font-size: 0.18rem;
						color: #F5F5F5;
					}
				}
				.bg2{
					background: url('../../../public/images/bg2.png') no-repeat center center; 
					background-size:15.36rem 5.7rem ;
					margin-top: 0.34rem;
					.bg1_top{
						color: #666666;
						margin-left: 6.5%;
					}
					.bg1_cen{
						color: #666666;
					}
					.bg1_btm{
						color: #666666;
					}
					.btn{
						margin-left: 6%;
						width: 1.82rem;
						height: 0.45rem;
						margin-top: 0.62rem;
						border: 0.01rem solid #2F3146;
						display: flex;
						.btn_left{
							width: 1.38rem;
							height: 0.45rem;
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 0.45rem;
							text-align: center;
							background-color: #2F3146;
						}
						.btn_right{
							width:0.44rem ;
							height: 0.45rem;
							background: url('../../../public/smallImg/arrow.png') no-repeat center center;
							background-size:0.15rem 0.15rem;
						}
					}
				}
			}
			.product_wrap_btm2{
				width: 100%;
				margin-top: 0.56rem;
				
				.bg1{
					height: 6.14rem;
					background:#F5F5F5 ; 
					background-size:15.36rem 5.7rem ;
					margin-bottom:0.46rem;
					.bg1_top{
						margin-top: 0.72rem;
						margin-left: 0.65rem;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: bold;
						color: #999999;
					}
					.bg1_cen{
						margin-top: 0.35rem;
						margin-left: 0.65rem;
						font-size: 0.3rem;
						font-family: Arial;
						font-weight: bold;
						color: #333333;
					}
					.bg1_btm{
						line-height: 0.3rem;
						margin-top: 0.53rem;
						// margin-left: 6%;
						font-size: 0.18rem;
						color:#666666;
						.bg1_btm_left{
							width: 5.75rem;
							margin-left:0.93rem ;
							.bg1_btm_tit{
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: bold;
								color: #333333;
							}
							.bg1_btm_txt{
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: 400;
								color: #666666;
								line-height: 0.3rem;
								margin-left: 0.04rem;
							}
							img{
								width: 4.67rem;
								height: 2.57rem;
								margin-left: 0.29rem;
								margin-top: 0.4rem;
							}
						}
						
					}
					.bg_pic{
						width: 100%;
						height: 2.73rem;
						margin-top: 0.64rem;
						background: url('../../../public/images/use-pic.png') no-repeat center center; 
						background-size: 11.41rem 2.73rem;
					}
				}
				.bg3{
					background: url('../../../public/images/bg4.png') no-repeat center center; 
					background-size:15.36rem 5.7rem ;
					margin-top: 0.34rem;
					.bg1_top{
						color:#F5F5F5;
						margin-left: 6.5%;
					}
					.bg1_cen{
						color:#F5F5F5;
					}
					.bg1_btm{
						color: #F5F5F5;
					}
					.btn{
						margin-left: 6%;
						width: 1.82rem;
						height: 0.45rem;
						margin-top: 0.62rem;
						border: 0.01rem solid #2F3146;
						display: flex;
						.btn_left{
							width: 1.38rem;
							height: 0.45rem;
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 0.45rem;
							text-align: center;
							background-color: #2F3146;
						}
						.btn_right{
							width:0.44rem ;
							height: 0.45rem;
							background: url('../../../public/smallImg/arrow.png') no-repeat center center;
							background-size:0.15rem 0.15rem;
						}
						
					}
				}
				.bg3{
					background: url('../../../public/images/bg5.png') no-repeat center center;
					background-size:15.36rem 5.7rem ;
				}
			}
			.product_wrap_btm3{
				width: 100%;
				margin-top: 0.56rem;
				
				.bg1{
					height: 5.7rem;
					background: url('../../../public/images/bg3.png') no-repeat center center; 
					background-size:15.36rem 5.7rem ;
					.bg1_top{
						margin-top: 1.74rem;
						margin-left: 6%;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: bold;
						color: #666666;
					}
					.bg1_cen{
						margin-top: 0.52rem;
						margin-left: 6%;
						font-size: 0.3rem;
						font-family: Arial;
						font-weight: bold;
						color: #666666;
					}
					.bg1_btm{
						line-height: 0.3rem;
						margin-top: 0.31rem;
						margin-left: 6%;
						font-size: 0.18rem;
						color:#666666;
					}
				}
				.bg3{
					background: url('../../../public/images/bg4.png') no-repeat center center; 
					background-size:15.36rem 5.7rem ;
					margin-top: 0.34rem;
					.bg1_top{
						color:#F5F5F5;
						margin-left: 6.5%;
					}
					.bg1_cen{
						color:#F5F5F5;
					}
					.bg1_btm{
						color: #F5F5F5;
					}
					.btn{
						margin-left: 6%;
						width: 1.82rem;
						height: 0.45rem;
						margin-top: 0.62rem;
						border: 0.01rem solid #2F3146;
						display: flex;
						.btn_left{
							width: 1.38rem;
							height: 0.45rem;
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 0.45rem;
							text-align: center;
							background-color: #2F3146;
						}
						.btn_right{
							width:0.44rem ;
							height: 0.45rem;
							background: url('../../../public/smallImg/arrow.png') no-repeat center center;
							background-size:0.15rem 0.15rem;
						}
						
					}
				}
				.bg3{
					background: url('../../../public/images/bg5.png') no-repeat center center;
					background-size:15.36rem 5.7rem ;
				}
			}
			.product_wrap_btm4{
				width: 100%;
				margin-top: 0.56rem;
				
				.bg1{
					height: 5.7rem;
					background: url('../../../public/images/bg6.png') no-repeat center center; 
					background-size:15.36rem 5.7rem ;
					.bg1_top{
						margin-top: 1.74rem;
						margin-left: 6%;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: bold;
						color: #999999;
					}
					.bg1_cen{
						margin-top: 0.52rem;
						margin-left: 6%;
						font-size: 0.3rem;
						font-family: Arial;
						font-weight: bold;
						color: #4D4D4D;
					}
					.bg1_btm{
						width: 4.6rem;
						line-height: 0.3rem;
						margin-top: 0.31rem;
						margin-left: 6%;
						font-size: 0.18rem;
						color:#666666;
					}
				}
				
			}
			.product_wrap_btm5{
				width: 100%;
				margin-top: 0.56rem;
				display: flex;
				
				.bg1{
					height: 6.14rem;
					background:#F5F5F5 ; 
					background-size:15.36rem 5.7rem ;
					padding-bottom: 0.55rem;
					.bg1_top{
						margin-top: 0.72rem;
						margin-left: 0.65rem;
						font-size: 0.16rem;
						font-family: Arial;
						font-weight: bold;
						color: #999999;
					}
					.bg1_cen{
						margin-top: 0.35rem;
						margin-left: 0.65rem;
						font-size: 0.3rem;
						font-family: Arial;
						font-weight: bold;
						color: #333333;
					}
					.bg1_btm{
						line-height: 0.3rem;
						margin-top: 0.53rem;
						// margin-left: 6%;
						font-size: 0.18rem;
						color:#666666;
						.bg1_btm_left{
							width: 5.75rem;
							margin-left:0.93rem ;
							.bg1_btm_tit{
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: bold;
								color: #333333;
							}
							.bg1_btm_txt{
								font-size: 0.18rem;
								font-family: Arial;
								font-weight: 400;
								color: #666666;
								line-height: 0.3rem;
								margin-left: -0.27rem;
							}
							img{
								margin-left: 0.29rem;
								margin-top: 0.4rem;
							}
						}
						
					}
					.bg_pic{
						width: 100%;
						height: 2.73rem;
						margin-top: 0.64rem;
						background: url('../../../public/images/use-pic.png') no-repeat center center; 
						background-size: 11.41rem 2.73rem;
					}
				}
				.bg3{
					background: url('../../../public/images/bg4.png') no-repeat center center; 
					background-size:15.36rem 5.7rem ;
					margin-top: 0.34rem;
					.bg1_top{
						color:#F5F5F5;
						margin-left: 6.5%;
					}
					.bg1_cen{
						color:#F5F5F5;
					}
					.bg1_btm{
						color: #F5F5F5;
					}
					.btn{
						margin-left: 6%;
						width: 1.82rem;
						height: 0.45rem;
						margin-top: 0.62rem;
						border: 0.01rem solid #2F3146;
						display: flex;
						.btn_left{
							width: 1.38rem;
							height: 0.45rem;
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 0.45rem;
							text-align: center;
							background-color: #2F3146;
						}
						.btn_right{
							width:0.44rem ;
							height: 0.45rem;
							background: url('../../../public/smallImg/arrow.png') no-repeat center center;
							background-size:0.15rem 0.15rem;
						}
						
					}
				}
				.bg3{
					background: url('../../../public/images/bg5.png') no-repeat center center;
					background-size:15.36rem 5.7rem ;
					
				}
				.bg5{
					width: 7.53rem;
					height: 5.7rem;
					background: #F5F5F5;
					margin-right: 0.3rem;
				}
				.bg5:last-child{
					margin-right: 0rem;
				}
			}
		}
	}
	
	// 移动端 样式
		@media (max-width:1024px){
			.product{
				width: 100%;
				.product_wrap{
					width: 5.85rem;
					
					.product_wrap_top{
						width: 5.85rem;
						height: 100%;
						margin-top: 0.13rem;
						flex-direction: column;
						.product_pic{
							width: 5.85rem;
							// height: 5.5rem;
							max-height:5.5rem;
							
							img{
								width: 5.85rem;
								// height: 5.5rem;
								max-height:5.5rem;
							}
						}
						.product_name{
							width: 5.8rem;
							.title{
								font-size: 0.31rem;
								line-height: 0.41rem;
								font-family: Arial;
								font-weight: bold;
								color: #333333;
								margin-top: 0.38rem;
								margin-bottom: 0.38rem;
							}
							.info{
								width: 5.85rem;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #333333;
								line-height: 0.3rem;
							}
							.btn{
								width: 1.73rem;
								height: 0.54rem;
								background: #5C6079;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 0.54rem;
								text-align: center;
								margin-top: 0.5rem;
							}
						}
					}
					.product_wrap_cen{
						height: 100%;
						margin-top: 0.24rem;
						ul{
							width: 100%;
							flex-wrap: wrap;
							li{
								width: 2.84rem;
								height: 4.6rem;
								background: #FFFFFF;
								border: 0.01rem solid #FFFFFF;
								box-shadow: 0rem 0.01rem 0.1rem 0rem rgba(0,0,0,0.08);
								margin-top: 0.31rem;
								.top{
									width: 100%;
									height: 0.06rem;
									background-color:#5C6079 ;
								}
								.title{
									width: 2.6rem;
									text-align: center;
									padding-top: 0.4rem;
									padding-bottom: 0.25rem;
									
									font-size: 0.24rem;
									font-family: Arial;
									font-weight: bold;
									color: #5C6079;
								}
								// .num{
								// 	width: 93%;
								// 	padding-top: 0.1rem;
								// 	padding-bottom: 0.1rem;
								// 	border-top: 0.01rem solid #E0E0E0;
									
								// 	p{
								// 		width: 80%;
								// 		text-align: center;
								// 		font-size: 0.18rem;
								// 		color: #333333;
								// 		line-height: 0.3rem;
								// 	}
								// }
								
								.small_box{
									width: 2.6rem;
									.num{
										width: 100%;
										padding-top: 0.1rem;
										padding-bottom: 0.1rem;
										border-top: 0.01rem solid #E0E0E0;
										
										p{
											width: 80%;
											text-align: center;
											font-size: 0.18rem;
											color: #333333;
											line-height: 0.3rem;
										}
									}
								}
								.small_box2{
									width: 85%;
									display: flex;
									justify-content: space-between;
									.num1{
										width: 1.2rem;
										border-top: 0.01rem solid #E0E0E0;
										padding-top: 0.1rem;
										padding-bottom: 0.1rem;
										// padding-left: 0.35rem;
										// padding-right: 0.35rem;
										p{
											// width: 80%;
											text-align: center;
											font-size: 0.18rem;
											color: #333333;
											line-height: 0.3rem;
										}
									}
								}
								
							}
							.li1{
								width: 2.84rem;
							}
							.li2{
								width: 2.84rem;
							}
							.li1:nth-child(1){
								.num{
									width: 2.6rem;
									padding-top: 0.25rem;
									padding-bottom: 0.25rem;
									border-top: 0.01rem solid #E0E0E0;
									
									p{
										width: 80%;
										text-align: center;
										font-size: 0.18rem;
										color: #333333;
										line-height: 0.3rem;
									}
								}
							}
							.li1:nth-child(2){
								.num{
									width: 2.6rem;
									padding-top: 0.25rem;
									padding-bottom: 0.25rem;
									border-top: 0.01rem solid #E0E0E0;
									
									p{
										width: 2.6rem;
										text-align: center;
										font-size: 0.18rem;
										line-height: 0.3rem;
										font-family: Arial;
										font-weight: bold;
										color: #5C6079;
									}
								}
							}
							.li1:nth-child(3){
								.num{
									p{
										flex-direction: row;
										width: 2.6rem;
										padding-left: 0.2rem;
										justify-content: flex-start;
										
										img{
											width: 0.28rem;
											height: 0.2rem;
											margin-right: 0.17rem;
										}
									}
								}
							}
							.li1:nth-child(1){
								.num{
									width: 93%;
									padding-top: 0.25rem;
									padding-bottom: 0.25rem;
									border-top: 0.01rem solid #E0E0E0;
									
									p{
										width: 80%;
										text-align: center;
										font-size: 0.18rem;
										color: #333333;
										line-height: 0.3rem;
									}
								}
							}
							.li2:nth-child(3){
								.num{
									padding-top: 0.25rem;
									padding-bottom: 0.25rem;
			
									p{
										width: 80%;
										text-align: center;
										font-size: 0.18rem;
										line-height: 0.3rem;
										font-family: Arial;
										font-weight: bold;
										color: #5C6079;
									}
								}
							}
							.li2:nth-child(4){
								.num{
									p{
										flex-direction: row;
										width: 88%;
										justify-content: flex-start;
										
										img{
											width: 0.28rem;
											height: 0.2rem;
											margin-right: 0.17rem;
										}
									}
								}
							}
						}
					}
					.product_wrap_btm{
						width:5.84rem;
						margin-top: 0.56rem;
						
						.bg1{
							height: 8.7rem;
							background: url('../../../public/images/bj.png') no-repeat center center; 
							background-size:5.84rem 8.7rem ;
							.bg1_top{
								margin-top: 0.6rem;
								margin-left: 6%;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: bold;
								color: #F5F5F5;
							}
							.bg1_cen{
								margin-top: 0.52rem;
								margin-left: 6%;
								font-size: 0.3rem;
								font-family: Arial;
								font-weight: bold;
								color: #F5F5F5;
							}
							.bg1_btm{
								line-height: 0.31rem;
								margin-top: 0.31rem;
								margin-left: 6%;
								font-size: 0.18rem;
								color: #F5F5F5;
							}
						}
						
					}
					.product_wrap_btm1{
						width:5.84rem;
						margin-top: 0.56rem;
						
						.bg1{
							height: 8.7rem;
							background: url('../../../public/images/bj.png') no-repeat center center; 
							background-size:5.84rem 8.7rem ;
							.bg1_top{
								margin-top: 0.6rem;
								margin-left: 6%;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: bold;
								color: #F5F5F5;
							}
							.bg1_cen{
								margin-top: 0.52rem;
								margin-left: 6%;
								font-size: 0.3rem;
								font-family: Arial;
								font-weight: bold;
								color: #F5F5F5;
							}
							.bg1_btm{
								line-height: 0.31rem;
								margin-top: 0.31rem;
								margin-left: 6%;
								font-size: 0.18rem;
								color: #F5F5F5;
							}
						}
						.bg2{
							background: url('../../../public/images/bj1.png') no-repeat center center; 
							background-size:5.84rem 8.7rem ;
							margin-top: 0.34rem;
							.bg1_top{
								color: #666666;
								margin-left: 6.5%;
							}
							.bg1_cen{
								color: #666666;
							}
							.bg1_btm{
								color: #666666;
							}
							.btn{
								margin-left: 6%;
								width: 1.82rem;
								height: 0.45rem;
								margin-top: 0.62rem;
								border: 0.01rem solid #2F3146;
								.btn_left{
									width: 1.38rem;
									height: 0.45rem;
									font-size: 0.18rem;
									font-family: Arial;
									font-weight: 400;
									color: #FFFFFF;
									line-height: 0.45rem;
									text-align: center;
									background-color: #2F3146;
								}
							}
						}
					}
					.product_wrap_btm2{
						width: 100%;
						margin-top: 0.56rem;
						
						.bg1{
							height: 6.14rem;
							background:#F5F5F5 ; 
							background-size:15.36rem 5.7rem ;
							margin-bottom:0.46rem;
							.bg1_top{
								margin-top: 0.72rem;
								margin-left: 0.65rem;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: bold;
								color: #999999;
							}
							.bg1_cen{
								margin-top: 0.35rem;
								margin-left: 0.65rem;
								font-size: 0.3rem;
								font-family: Arial;
								font-weight: bold;
								color: #333333;
							}
							.bg1_btm{
								line-height: 0.3rem;
								margin-top: 0.53rem;
								// margin-left: 6%;
								font-size: 0.18rem;
								color:#666666;
								display: flex;
								flex-wrap: wrap;
								.bg1_btm_left{
									width: 5.75rem;
									margin-left:0.93rem ;
								
									.bg1_btm_tit{
										font-size: 0.18rem;
										font-family: Arial;
										font-weight: bold;
										color: #333333;
									}
									.bg1_btm_txt{
										font-size: 0.18rem;
										font-family: Arial;
										font-weight: 400;
										color: #666666;
										line-height: 0.3rem;
										margin-left: 0.04rem;
									}
									img{
										width: 3.67rem;
										height: 2.57rem;
										margin-left: 0rem;
										margin-top: 0.4rem;
									}
								}
								
							}
							.bg_pic{
								width: 100%;
								height: 2.73rem;
								margin-top: 0.64rem;
								background: url('../../../public/images/use-pic.png') no-repeat center center; 
								background-size: 5.7rem 1.8rem;
							}
						}
						.bg1:nth-child(2){
							height: 9.8rem;
						}
						.bg3{
							background: url('../../../public/images/bg4.png') no-repeat center center; 
							background-size:15.36rem 5.7rem ;
							margin-top: 0.34rem;
							.bg1_top{
								color:#F5F5F5;
								margin-left: 6.5%;
							}
							.bg1_cen{
								color:#F5F5F5;
							}
							.bg1_btm{
								color: #F5F5F5;
							}
							.btn{
								margin-left: 6%;
								width: 1.82rem;
								height: 0.45rem;
								margin-top: 0.62rem;
								border: 0.01rem solid #2F3146;
								display: flex;
								.btn_left{
									width: 1.38rem;
									height: 0.45rem;
									font-size: 0.18rem;
									font-family: Arial;
									font-weight: 400;
									color: #FFFFFF;
									line-height: 0.45rem;
									text-align: center;
									background-color: #2F3146;
								}
								.btn_right{
									width:0.44rem ;
									height: 0.45rem;
									background: url('../../../public/smallImg/arrow.png') no-repeat center center;
									background-size:0.15rem 0.15rem;
								}
								
							}
						}
						.bg3{
							background: url('../../../public/images/bg5.png') no-repeat center center;
							background-size:15.36rem 5.7rem ;
						}
					}
					.product_wrap_btm3{
						width:5.84rem;
						margin-top: 0.56rem;
						
						.bg1{
							height: 8.7rem;
							background: url('../../../public/images/bj2.png') no-repeat center center; 
							background-size:5.84rem 8.7rem ;
							.bg1_top{
								margin-top: 0.6rem;
								margin-left: 6%;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: bold;
								color: #666666;
							}
							.bg1_cen{
								margin-top: 0.52rem;
								margin-left: 6%;
								font-size: 0.3rem;
								font-family: Arial;
								font-weight: bold;
								color: #666666;
							}
							.bg1_btm{
								line-height: 0.3rem;
								margin-top: 0.31rem;
								margin-left: 6%;
								font-size: 0.18rem;
								color:#666666;
							}
						}
						.bg3{
							background: url('../../../public/images/bg4.png') no-repeat center center; 
							background-size:5.84rem 8.7rem ;
							margin-top: 0.34rem;
							.bg1_top{
								color:#F5F5F5;
								margin-left: 6.5%;
							}
							.bg1_cen{
								color:#F5F5F5;
							}
							.bg1_btm{
								color: #F5F5F5;
							}
							.btn{
								margin-left: 6%;
								width: 1.82rem;
								height: 0.45rem;
								margin-top: 0.62rem;
								border: 0.01rem solid #2F3146;
								.btn_left{
									width: 1.38rem;
									height: 0.45rem;
									font-size: 0.18rem;
									font-family: Arial;
									font-weight: 400;
									color: #FFFFFF;
									line-height: 0.45rem;
									text-align: center;
									background-color: #2F3146;
								}
							}
						}
						.bg3{
							background: url('../../../public/images/bj3.png') no-repeat center center;
							background-size:5.84rem  8.7rem;
						}
					}
					.product_wrap_btm4{
						width: 100%;
						margin-top: 0.56rem;
						
						.bg1{
							height: 8.7rem;
							background: url('../../../public/images/bj4.png') no-repeat center center; 
							background-size:5.84rem 8.7rem ;
							.bg1_top{
								margin-top: 0.6rem;
								margin-left: 6%;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: bold;
								color: #999999;
							}
							.bg1_cen{
								margin-top: 0.52rem;
								margin-left: 6%;
								font-size: 0.3rem;
								font-family: Arial;
								font-weight: bold;
								color: #4D4D4D;
							}
							.bg1_btm{
								width: 4.6rem;
								line-height: 0.3rem;
								margin-top: 0.31rem;
								margin-left: 6%;
								font-size: 0.18rem;
								color:#666666;
							}
						}
						
					}
					.product_wrap_btm5{
						width: 100%;
						margin-top: 0.56rem;
						display: flex;
						flex-direction: column;
						
						.bg1{
							height: 6.14rem;
							background:#F5F5F5 ; 
							background-size:15.36rem 5.7rem ;
							padding-bottom: 0.55rem;
							.bg1_top{
								margin-top: 0.72rem;
								margin-left: 0.2rem;
								font-size: 0.16rem;
								font-family: Arial;
								font-weight: bold;
								color: #999999;
							}
							.bg1_cen{
								margin-top: 0.35rem;
								margin-left: 0.2rem;
								font-size: 0.3rem;
								font-family: Arial;
								font-weight: bold;
								color: #333333;
							}
							.bg1_btm{
								line-height: 0.3rem;
								margin-top: 0.53rem;
								// margin-left: 6%;
								font-size: 0.18rem;
								color:#666666;
								.bg1_btm_left{
									width: 5.75rem;
									margin-left:0.5rem ;
									.bg1_btm_tit{
										font-size: 0.18rem;
										font-family: Arial;
										font-weight: bold;
										color: #333333;
									}
									.bg1_btm_txt{
										font-size: 0.18rem;
										font-family: Arial;
										font-weight: 400;
										color: #666666;
										line-height: 0.3rem;
										margin-left: -0.27rem;
									}
									img{
										margin-left: 0rem;
										margin-top: 0.4rem;
									}
								}
								
							}
							.bg_pic{
								width: 100%;
								height: 2.73rem;
								margin-top: 0.64rem;
								background: url('../../../public/images/use-pic.png') no-repeat center center; 
								background-size: 11.41rem 2.73rem;
							}
						}
						.bg3{
							background: url('../../../public/images/bg4.png') no-repeat center center; 
							background-size:15.36rem 5.7rem ;
							margin-top: 0.34rem;
							.bg1_top{
								color:#F5F5F5;
								margin-left: 6.5%;
							}
							.bg1_cen{
								color:#F5F5F5;
							}
							.bg1_btm{
								color: #F5F5F5;
							}
							.btn{
								margin-left: 6%;
								width: 1.82rem;
								height: 0.45rem;
								margin-top: 0.62rem;
								border: 0.01rem solid #2F3146;
								display: flex;
								.btn_left{
									width: 1.38rem;
									height: 0.45rem;
									font-size: 0.18rem;
									font-family: Arial;
									font-weight: 400;
									color: #FFFFFF;
									line-height: 0.45rem;
									text-align: center;
									background-color: #2F3146;
								}
								.btn_right{
									width:0.44rem ;
									height: 0.45rem;
									background: url('../../../public/smallImg/arrow.png') no-repeat center center;
									background-size:0.15rem 0.15rem;
								}
								
							}
						}
						.bg3{
							background: url('../../../public/images/bg5.png') no-repeat center center;
							background-size:15.36rem 5.7rem ;
							
						}
						.bg5{
							width: 5.82rem;
							height: 5.7rem;
							background: #F5F5F5;
							margin-bottom: 0.2rem;
						}
						.bg5:last-child{
							margin-right: 0rem;
						}
					}
				}
			}
		}
</style>