<template>
	<div class='selectSocket flex_box_cen'>
		<div class='wrap flex_box_column_cen'>
			<div class='title'>
				Optional output socket
			</div>
			<div class="product_list">
				<div class="swiper-container1">
				  <div class="swiper-wrapper">
				    <div class="swiper-slide wow fadeInRight ">
						<div class="pic flex_box_column_cen">
							<img src="../../../public/productImg/gfci.png" alt="">
							<div class="text">GFCI</div>
						</div>
					</div>
				    <div class="swiper-slide wow fadeInRight">
				    	<div class="pic flex_box_column_cen">
							<img src="../../../public/productImg/australia.png" alt="">
							<div class="text">Australia</div>
						</div>
				    </div>
				    <div class="swiper-slide wow fadeInRight">
				    	<div class="pic flex_box_column_cen">
				    		<img src="../../../public/productImg/iec.png" alt="">
							<div class="text">IEC(2Position)</div>
				    	</div>
				    </div>
					<div class="swiper-slide wow fadeInRight">
						<div class="pic flex_box_column_cen">
							<img src="../../../public/productImg/nema.png" alt="">
							<div class="text">NEMA(2Position)</div>
						</div>
					</div>
					<div class="swiper-slide wow fadeInRight">
						<div class="pic flex_box_column_cen">
							<img src="../../../public/productImg/schuko.png" alt="">
							<div class="text">Schuko</div>
						</div>
					</div>
					<div class="swiper-slide wow fadeInRight">
						<div class="pic flex_box_column_cen">
							<img src="../../../public/productImg/universal.png" alt="">
							<div class="text">Universal</div>
						</div>
					</div>
				  </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from 'wowjs/dist/wow'
	export default{
		data() {
			return{
				
			}
		},
		mounted() {
			const mySwiper1 = new Swiper('.swiper-container1', {
				// autoplay: 1000,//可选选项，自动滑动
				slidesPerView : 6,
				spaceBetween : 20,
				centeredSlides:false,
			})
			this.$nextTick(()=>{
				var wow = new WOW.WOW({
					boxClass:'wow',
					animateClass:'animated',
					offset:0,
					mobile:true,
					live :false
				})
				wow.init()
			})
		},
		
		// methods{
			
		// }
	}
</script>

<style lang="scss" scoped>
	.selectSocket{
		width:100%;
		margin-top: 0.86rem;
		.wrap{
			width: 15.36rem;
			.title{
				width: 15.36rem;
				font-size: 0.36rem;
				font-family: Arial;
				font-weight: bold;
				color: #5C6079;
			}
			.product_list{
				width: 13.91rem;
				margin-top: 0.52rem;
				
				.pic{
					width: 1.81rem;
					img{
						max-width: 1.81rem;
						height: 1.46rem;
					}
					.text{
						font-size: 0.18rem;
						font-family: Arial;
						font-weight: 400;
						color: #5C6079;
						margin-top: 0.48rem;
					}
				}
			}
		}
	}
</style>