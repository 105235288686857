<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- <diy-option></diy-option> -->
	<!-- 底部 -->
	<new-footer style='margin-top:1.43rem;'></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product2/product.vue'										//产品介绍组件
	import newFooter from '@/components/footer/footer.vue'											//网页底部
	// import diyOption from '@/components/diyOption'													//DIY系统方案 组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'CAN USB Adaptor',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			newFooter,
			// diyOption,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/CAN-USB/product.png'),
					title:'CAN USB Adaptor',
					info: `
						CAN bus can be easily connected to the computer to achieve data 
						interaction between the computer and the inverter to facilitate the debugging of equipment 
					`
				},
				productInfo:[
					{
						title:'Compatible with',
						list:[
							{
								info:"HP Series",
								icon:'',
							},{
								info:'APP Series',
								icon:'',
							},{
								info:'APC Series',
								icon:'',
							},{
								info:'APS Series',
								icon:'',
							},{
								info:'TPP Series',
								icon:'',
							},{
								info:'PSW7 Series ',
								icon:'',
							},{
								info:'HPV Series ',
								icon:'',
							},{
								info:'APV Series',
								icon:'',
							}
						]
					}
				
				],
				
			}
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
