<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features  :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<!-- <product-appearance :appearanceList='appearanceList'></product-appearance> -->
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<!-- <support-term :termList='termList' :sid='sid' :tobel_bom='tobel_bom'></support-term> -->
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	// import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'productInfo',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			// productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/1000V-UP-S/product.png'),
					title:'10000V-UP-S Three Phase Power Conversion System',
					info: `
						UP-S series power conversion system mainly consists of AC distribution, 
						transformer, energy storage AC/DC converter module, monitoring system, 
						etc. With modular design, high integration, easy and flexible installation,
						modular splicing according to the actual application scenario, with
						constant voltage, constant current and constant power control, it can 
						be applied to industrial and commercial energy storage systems, with 
						bi-directional (rectifier and inverter) as the basic feature, supporting 
						grid-connected and off-grid operation, and with reactive power compensation 
						and harmonic compensation functions. It can meet the application requirements 
						of different application scenarios
					`,
					id:'4'
				},
				productInfo:[
					{
						title:'Operating Voltage',
						list:[
							{
								info:"DC 600~900V",
								icon:'',
							},
							{
								info:" AC 400V （3P4W）",
								icon:'',
							}
						]
					},
					{
						title:'Operating Frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application Scenario',
						list:[
							{
								info:"Factory",
								icon:require('../../public/images/scale.png'),
							},
							{
								info:"Commercial building",
								icon:require('../../public/images/as2.png'),
							},
							{
								info:"Energy storage power station",
								icon:require('../../public/images/as3.png'),
							}
						]
					},
					{
						title:'Range Of Power',
						list:[
							{
								info:"62.5KW",
								icon:'',
							},
							{
								info:"125KW",
								icon:'',
							},
							{
								info:"250KW",
								icon:'',
							},
							{
								info:"375KW",
								icon:'',
							},
							{
								info:"500KW",
								icon:'',
							},
							{
								info:"630KW",
								icon:'',
							}
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/pf5.png'),
						text:'Efficiency up to 97.3%',
					},
					{
						icon:require('../../public/images/pf7.png'),
						text:'Multiple protection for safer use',
					},
					{
						icon:require('../../public/images/pf8.png'),
						text:'Modular design, easy to maintain and expand',
					},
					{
						icon:require('../../public/images/pf9.png'),
						text:'Bidirectional power conversion, four-quadrant operation',
					},
					{
						icon:require('../../public/images/pf10.png'),
						text:'Seamless switching between on-grid and off-grid (optional STS)',
					},
					
					{
						icon:require('../../public/images/pf11.png'),
						text:'Off-grid parallelism, black start function',
					}
				],
				
				priceInfo:[						//技术参数信息
					{
						name:'',
						list:[
							{
								parameterName:'Model',
								parameterList:['62.5KW','125KW','250KW','375KW','500KW','630KW']
							}
						]
					},
					{
						name:'Utility-interactive Mode',
						list:[
							{
								parameterName:'Battery voltage range',
								parameterList:['600~900V']
							},
							{
								parameterName:'DC max current',
								parameterList:['110A','220A','440A','660A','880A','1100A']
							},
							{
								parameterName:'Max. Charge/Discharge  Current',
								parameterList:['100A']
							},
							{
								parameterName:'AC voltage',
								parameterList:['400V±15%']
							},
							{
								parameterName:'Max. AC current',
								parameterList:['100A','200A','400A','600A','800A','1000A']
							},
							{
								parameterName:'Nominal power',
								parameterList:['62.5KW','125KW','250KW','375KW','500KW','630KW']
							},
							{
								parameterName:'AC frequency',
								parameterList:['50Hz/60Hz±2.5Hz']
							},
							{
								parameterName:'THDi',
								parameterList:[' ≤3%']
							},
							{
								parameterName:'AC PF',
								parameterList:['-1~+1']
							}
						]
					},
					{
						name:'Stand-alone Mode',
						list:[
							{
								parameterName:'Battery voltage range ',
								parameterList:['600~900V']
							},
							{
								parameterName:'DC Max Current',
								parameterList:['110A','220A','440A','660A','880A','1100A']
							},
							{
								parameterName:'AC output voltage',
								parameterList:['400V±10%(±10% configurable) ']
							},
							{
								parameterName:'DC Max Current',
								parameterList:['110A','220A','440A','660A','880A','1100A']
							},
							{
								parameterName:'AC voltage range',
								parameterList:[' 400V±15%','480V±15% ']
							},
							{
								parameterName:'Max. AC output current',
								parameterList:['100A','200A','400A','600A','800A','1000A']
							},
							{
								parameterName:'Nominal AC output power',
								parameterList:['62.5KW','125KW','250KW','375KW','500KW','630KW']
							},
							{
								parameterName:'AC max power',
								parameterList:['68.75KW',' 137.5KW',' 275KW',' 412.5KW',' 550KW',' 693KW ']
							},
							{
								parameterName:'Output THDu',
								parameterList:['< 3 % (Linear load)']
							},
							{
								parameterName:'AC frequency',
								parameterList:[' 50Hz/60Hz±0.2% ']
							},
							{
								parameterName:'AC PF',
								parameterList:['-1~+1']
							}
						]
					},
					{
						name:'Other',
						list:[
							{
								parameterName:'Peak efficiency',
								parameterList:['97.30% ']
							},
							{
								parameterName:'Protection',
								parameterList:['Overtemperature protection, AC over/under voltage protection, Over/under frequency protection,Emergency power off, AC phase reverse, Fan/relay failure, Over/under load protection, Ground faultcircuit Interrupter, Anti-islanding']
							},
							{
								parameterName:'AC connection',
								parameterList:['3P4W']
							},
							{
								parameterName:'Display',
								parameterList:['7 "color touch screen']
							},
							{
								parameterName:'Communication',
								parameterList:['RS485/CAN/ModBusTCP/IP/CAN/LAN']
							},
							{
								parameterName:'Isolation ',
								parameterList:['Built-in Transformer','Transformer']
							},
							{
								parameterName:'Overload Capability',
								parameterList:['110%：10min ; 120%：1min']
							}
						]
					},
					{
						name:'Physical ',
						list:[
							{
								parameterName:'Cooling',
								parameterList:['Forced air cooling']
							},
							{
								parameterName:"Noise",
								parameterList:['≤70dB']
							},
							{
								parameterName:'Enclosure',
								parameterList:['IP20/IP54']
							},
							{
								parameterName:'Max elevation',
								parameterList:['3000m/10000feet (>2000m/6500feet derating)']
							},
							{
								parameterName:'Operating ambient temperature',
								parameterList:['﹣20℃～50℃(＞45℃derating) ']
							},
							{
								parameterName:'Humidity',
								parameterList:['0~95% (No condensing)']
							},
							{
								parameterName:'Size (W×H×D)',
								parameterList:['850*2400*1600mm','1400*2400*1600mm']
							},
							{
								parameterName:'Weight',
								parameterList:['/','/','/','/','/','/']
							},
						]
					}
				],
				otherList:[
					{
						img:require('../../public/otherImg/1000V-UPV-S.png'),
						url:'/1000V-UPV-S'
					},
					{
						img:require('../../public/otherImg/1000V-UP-M.png'),
						url:'/1000V-UP-M'
					}
				]
			}
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
