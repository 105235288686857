<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features :pplicableIofo='pplicableIofo'  :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<product-appearance :appearanceList='appearanceList'></product-appearance>
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<support-term :termList='termList'></support-term>
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'24V-SMP',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/images/24V-SMP.png'),
					title:'24V-SMP Series High Frequency Solar Inverte',
					info:'High frequency pure sine wave output, built-in MPPT solar controller, high PV input range 120~450Vdc. Support lithium battery operation. Built in WIFI module, which enable users to  check data and control equipment in APP.',
					id:3,				//换背景图必传
				},
				productInfo:[
					{
						title:'Operating voltage',
						list:[
							{
								info:"single phase 230Vac",
								icon:'',
							}
						]
					},
					{
						title:'Operating frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							}
						]
					},
					{
						title:'Range of power',
						list:[
							{
								info:"SMP3KVA -24",
								icon:'',
							},
							{
								info:"SMP3KW-24",
								icon:'',
							}
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/net.png'),
						text:'Support solar charging',
					},
					{
						icon:require('../../public/images/technology.png'),
						text:'Three stage charging technology',
					},
					
					{
						icon:require('../../public/images/wide.png'),
						text:'Wide input voltage',
					},
					
				],
				pplicableIofo:[
					{
						title:'SMP3KVA -24 SMP3KW-24',
						list:[
							{
								pic:require('../../public/smallImg/led.png'),
								text:'LED'
							},
							{
								pic:require('../../public/smallImg/fridge.png'),
								text:'Fridge'
							},
							{
								pic:require('../../public/smallImg/tv.png'),
								text:'TV'
							},
							{
								pic:require('../../public/smallImg/computer.png'),
								text:'computer'
							},
							{
								pic:require('../../public/smallImg/loudspeaker.png'),
								text:'Loudspeaker'
							},
							{
								pic:require('../../public/smallImg/coffee.png'),
								text:'Coffee machine'
							},
							{
								pic:require('../../public/smallImg/air.png'),
								text:'Air conditioner '
							},
							{
								pic:require('../../public/smallImg/washing.png'),
								text:'Washing machine'
							},
						]
					}
				],
				appearanceList:[
					{
						pic:require('../../public/images/12V-SMP1.png'),
						text:'SMP 1.5KVA-12'
					},
					{
						pic:require('../../public/images/12V-SMP2.png'),
						text:'SMP 1.5KVA-12'
					},
				],
				priceInfo:[						//技术参数信息
					{
						name:'Inverter Output',
						list:[
							{
								parameterName:'Model',
								parameterList:['SMP3KVA -24','SMP3KW-24']
							},
							{
								parameterName:'Continuous Output Power',
								parameterList:['2.4KW','3KW']
							},
							{
								parameterName:'Surge Rating (5s)',
								parameterList:['4.8KW','6KW']
							},
							{
								parameterName:'Nominal AC Input Voltage/Waveform',
								parameterList:['230Vac/Sinusoidal (utility or generator)']
							},
							{
								parameterName:'AC Input Range',
								parameterList:['90~280Vac(Appliances) 170Vac~280Vac(UPS)']
							},
							{
								parameterName:'AC Input Frequency Range',
								parameterList:['40~65±1Hz; (>42 or <63±1Hz comeback)']
							},
							{
								parameterName:'Output Voltage/Frequency',
								parameterList:['230Vac±5%/50Hz']
							},
							{
								parameterName:'Output Frequency',
								parameterList:['50Hz']
							},
							{
								parameterName:'Peak Efficiency',
								parameterList:['92%']
							},
							{
								parameterName:'Line Mode Effic',
								parameterList:['>95% ( Rated R load, battery full charged )']
							},
							{
								parameterName:'Typical transfer Time',
								parameterList:['10ms typical (UPS) / 20ms typical (Appliances)']
							},
						]
					},
					{
						name:'DC Input',
						list:[
							{
								parameterName:'Nominal Input Voltage',
								parameterList:['24.0Vdc']
							},
							{
								parameterName:'Cold Start Voltage',
								parameterList:['23.0Vdc']
							},
							{
								parameterName:'High DC Cut-off Voltage',
								parameterList:['31.0Vdc']
							},
							{
								parameterName:'Idle Consumption-Search Mode',
								parameterList:['<30W']
							}
						]
					},
					{
						name:'Charger',
						list:[
							{
								parameterName:'Output Voltage',
								parameterList:['Flooded Battery :29.2Vdc','Flooded Battery :14.6Vdc']
							},
							{
								parameterName:'Floating Charging Voltage',
								parameterList:['27Vdc']
							},
							{
								parameterName:'Charging Algorithm',
								parameterList:['3-Step']
							},
							{
								parameterName:'Max AC Charge Current',
								parameterList:['30A','80A']
							}
						]
					},
					{
						name:'Bypass & Protection',
						list:[
							{
								parameterName:'Max Input AC Voltage',
								parameterList:['300Vac']
							},
							{
								parameterName:"Overload Protection",
								parameterList:['5s@≥150% load; 10s@110%~150% load']
							},
							{
								parameterName:'Output Short Circuit Protection',
								parameterList:['Circuit Breaker']
							}
						]
					},
					{
						name:'MPPT Solar Charging & Invert Mode',
						list:[
							{
								parameterName:'Max. PV Array Power',
								parameterList:['3000W','4000W']
							},
							{
								parameterName:"PV Array MPPT Voltage Range",
								parameterList:['60~300Vdc','120~450Vdc']
							},
							{
								parameterName:'Max. PV Open Circuit Array Voltage',
								parameterList:['350Vdc','495Vdc']
							},
							{
								parameterName:'Max Charging Current',
								parameterList:['60Amp','80Amp']
							}
						]
					},
					{
						name:'Mechanical Specifications',
						list:[
							{
								parameterName:'Operating Temperature Range',
								parameterList:['-10°C to 50°C']
							},
							{
								parameterName:"Storage temperature",
								parameterList:['-15°C~ 60°C']
							},
							{
								parameterName:'Humidity',
								parameterList:['5% to 95% Relative Humidity (Non-condensing)']
							},
							{
								parameterName:'Enclosure',
								parameterList:['IP21']
							},
							{
								parameterName:'Dimensions (mm / inch)',
								parameterList:['378*323*115 / 12.7*14.9*4.6']
							},
							{
								parameterName:'Net Weight (kg)',
								parameterList:['7.2']
							}
						]
					}
				],
				termList:[						//支持项数据
					{
						title:'Gel USA',
						fast:'14.0',
						float:'13.7'
					},
					{
						title:'AGM 1',
						fast:'14.1',
						float:'13.4'
					},
					{
						title:'Lithium',
						fast:'13.8',
						float:'13.6'
					},
					{
						title:'Sealed Lead Acid',
						fast:'14.4',
						float:'13.6'
					},
					{
						title:'Gel EURO',
						fast:'14.4',
						float:'13.8'
					},
					{
						title:'Open Lead Acid',
						fast:'14.0',
						float:'13.8'
					},
					{
						title:'LifePO4',
						fast:'15.5 (4 Hours then Off)',
						float:''
					},
					{
						title:'De-sulphation',
						fast:'13.6',
						float:'13.5'
					}
				],
				otherList:[
					{
						img:require('../../public/otherImg/24V-HPV.png'),
						url:'/24V-HPV'
					}
				]
			}
		},
		mounted() {
			// console.log(this.pplicableIcons)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
