<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features :pplicableIofo='pplicableIofo' :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<product-appearance :appearanceList='appearanceList'></product-appearance>
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<support-term :termList='termList' :sid='sid' :tobel_bom='tobel_bom'></support-term>
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'productInfo',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/images/48v-up.png'),
					title:'48V-UP Bi-directional Power Inverter/Charger',
					info:'UP series is a bidirectional power inverter/charger with DC-AC inverter and AC-DC battery charger functions to offer uninterrupted and stable power to loads. UP inverter can minimize electricity bill and optimize the usage of battery power through its builtin EMS system. Its comprehensive HMI offers user configurable and easy accessible operation to preset some basic data and working mode based on different applications.UP series is a bidirectional power inverter/charger with DC-AC inverter and AC-DC battery charger functions to offer uninterrupted and stable power to loads. UP inverter can minimize electricity bill and optimize the usage of battery power through its builtin EMS system. Its comprehensive HMI offers user configurable and easy accessible operation to preset some basic data and working mode based on different applications.',
					id:'2'
				},
				productInfo:[
					{
						title:'Operating Voltage',
						list:[
							{
								info:" single phase 230V ",
								icon:'',
							}
						]
					},
					{
						title:'Operating Frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application Scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							}
						]
					},
					{
						title:'Range Of Power',
						list:[
							{
								info:"5.0KW",
								icon:'',
							}
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/shield.png'),
						text:'Multiple protection for safer use',
					},
					{
						icon:require('../../public/images/line.png'),
						text:'Real pure sine wave output to protect sensitive loads',
					},
					{
						icon:require('../../public/images/charge.png'),
						text:'Battery charging voltage and charging current programmable',
					},
					{
						icon:require('../../public/images/pf4.png'),
						text:'Switch time <8 m(to off grid mode)',
					},
					{
						icon:require('../../public/images/pf5.png'),
						text:'Max efficiency 95%',
					},
					{
						icon:require('../../public/images/pf6.png'),
						text:'Friendly HMI allows user configuration ',
					}
				],
				pplicableIofo:[
					{
						title:'UPV 5KW',
						list:[
							{
								pic:require('../../public/smallImg/fridge.png'),
								text:'Fridge'
							},
							{
								pic:require('../../public/smallImg/tv.png'),
								text:'TV'
							},
							{
								pic:require('../../public/smallImg/computer.png'),
								text:'computer'
							},
							{
								pic:require('../../public/smallImg/loudspeaker.png'),
								text:'Loudspeaker'
							},
							{
								pic:require('../../public/smallImg/coffee.png'),
								text:'Coffee machine'
							},
							{
								pic:require('../../public/smallImg/air.png'),
								text:'Air conditioner '
							},
							{
								pic:require('../../public/smallImg/washing.png'),
								text:'Washing machine'
							},
							{
								pic:require('../../public/smallImg/microwave.png'),
								text:'Microwave Oven'
							},
							{
								pic:require('../../public/smallImg/heater.png'),
								text:'heater'
							}
						]
					}
				],
				appearanceList:[
					{
						pic:require('../../public/images/48-up1.png'),
						text:'48V-UP  5.0KW'
					},
					{
						pic:require('../../public/images/48-up2.png'),
						text:'48V-UP  5.0KW'
					}
				],
				priceInfo:[						//技术参数信息
					{
						name:'',
						list:[
							{
								parameterName:'Model',
								parameterList:['UPV 5048E']
							}
						]
					},
					{
						name:'Battery',
						list:[
							{
								parameterName:'Battery Type',
								parameterList:['Lead~acid or Lithium~ion']
							},
							{
								parameterName:'Battery Voltage Range',
								parameterList:['40~60V']
							},
							{
								parameterName:'Max. Charge/Discharge  Current',
								parameterList:['100A']
							},
							{
								parameterName:'Charging Curve',
								parameterList:['3 Stages']
							},
							{
								parameterName:'Charging Voltage',
								parameterList:['Depends On Battery Type ']
							}
						]
					},
					{
						name:'Output AC (Back Up) ',
						list:[
							{
								parameterName:'Rated Output Power',
								parameterList:['5000W']
							},
							{
								parameterName:'Max. AC Output Power',
								parameterList:['5500W']
							},
							{
								parameterName:'Back Up Switch Time',
								parameterList:['＜8ms']
							},
							{
								parameterName:'Rated output voltage',
								parameterList:['230V  (Single Phase) ']
							},
							{
								parameterName:'Rated frequency',
								parameterList:['50Hz ']
							},
							{
								parameterName:'Rated output current',
								parameterList:['22.7A']
							},
							{
								parameterName:'Input Voltage Waveform',
								parameterList:['Sine Wave']
							},
							{
								parameterName:'THDv (@linear load)',
								parameterList:['2%']
							},
							{
								parameterName:'No load loss.',
								parameterList:['＜50W ']
							}
						]
					},
					{
						name:'Output AC(Grid side) ',
						list:[
							{
								parameterName:'Rated Output Power',
								parameterList:['5000W ']
							},
							{
								parameterName:'Max. AC Output Power',
								parameterList:['5500W']
							},
							{
								parameterName:'Rated output voltage',
								parameterList:['230V（177~267V/90~267V） (Single Phase)']
							},
							{
								parameterName:'Rated grid frequency',
								parameterList:['50Hz/60Hz（47Hz~55Hz/57Hz ~65Hz）']
							},
							{
								parameterName:'Rated output current',
								parameterList:['22.7A ']
							},
							{
								parameterName:'Power Factor',
								parameterList:['＞0.95 ']
							},
							{
								parameterName:'THDi',
								parameterList:['＜5% ']
							}
						]
					},
					{
						name:'Efficiency',
						list:[
							{
								parameterName:'Max. efficiency',
								parameterList:['95%']
							}
						]
					},
					{
						name:'Protection ',
						list:[
							{
								parameterName:'Anti islanding Protection ',
								parameterList:['Integrated']
							},
							{
								parameterName:"PV String Input Reverse Polarity Protection",
								parameterList:['Integrated']
							},
							{
								parameterName:'Output Over Current Protection ',
								parameterList:['Integrated']
							},
							{
								parameterName:'Output Over Voltage Protection',
								parameterList:['Integrated']
							},
							{
								parameterName:'Overtemperature protection ',
								parameterList:['Integrated']
							},
							{
								parameterName:'Surge protection',
								parameterList:['Integrated']
							}
						]
					},
					{
						name:'General Data ',
						list:[
							{
								parameterName:'Display',
								parameterList:['LED+LCD ']
							},
							{
								parameterName:"Communication",
								parameterList:['RS485/CAN ']
							},
							{
								parameterName:'Dimensions (W*H*D)',
								parameterList:['420*460*186mm ']
							},
							{
								parameterName:'Weight',
								parameterList:['16kg ']
							},
							{
								parameterName:'Installation Style',
								parameterList:['Rack/Wall Mounted ']
							},
							{
								parameterName:'Topology',
								parameterList:['Transformer Isolation ']
							},
							{
								parameterName:'Operating Temperature Range',
								parameterList:['﹣20~60℃ （Derating Treatment ls Required IfThe Radiator ls Above 80℃） ']
							},
							{
								parameterName:'Humidity',
								parameterList:['0%~95% Relative Humidity (No Condensation ']
							},
							{
								parameterName:'Cooling',
								parameterList:['Intelligent Air Cooling  ']
							},
							{
								parameterName:'Protection Degree',
								parameterList:['IP20  ']
							},
							{
								parameterName:'Max. operation altitude',
								parameterList:['2000m （>2000m Derating)  ']
							},
							{
								parameterName:'Warranty',
								parameterList:['1 Years ']
							},
						]
					}
				],
				sid:'2',
				termList:[						//支持项数据
					{
						title:'Gel USA',
						fast:'56Vdc',
						float:'54.8Vdc'
					},
					{
						title:'AGM 1',
						fast:'56.4Vdc',
						float:'53.6Vdc'
					},
					{
						title:'LiFePO4_LF14',
						fast:'57.6Vdc',
						float:'54.4Vdc'
					},
					{
						title:'MnNiCo_N14',
						fast:'54.8Vdc',
						float:'54.8Vdc'
					},
					{
						title:'Custom',
						fast:'Set The Information According To The Specification Of The Batte',
						float:''
					}
					
				],
				tobel_bom:1,
				otherList:[
					{
						img:require('../../public/otherImg/48V-UPV.png'),
						url:'/48V-UPV'
					}
				]
			}
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
