<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features :pplicableIofo='pplicableIofo' :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<product-appearance :appearanceList='appearanceList'></product-appearance>
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<support-term :termList='termList' :tobel_bom='tobel_bom'></support-term>
	<!-- 其他产品 -->
	<other-products></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'24V-APS',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/images/12V-APC.png'),
					title:'24V-APS Series Low Frequency Pure Sine Wave Inverter/Charger+AVR ',
					info:'Pure sine wave output. The conventional conversion time is 8ms.It has the function of voltage regulator, which can be used for voltage regulator output of AC. It can hold 300% overload within 20s, providing necessary current for starting electrical appliances with high requirements. APS series can realize the function of starting/stopping the generator, monitoring the equipment operation status and battery temperature etc. by mobile through additional communication modules.',
				},
				productInfo:[
					{
						title:'Operating voltage',
						list:[
							{
								info:"  single phase 100-110-120V/  220-230-240V  ",
								icon:'',
							},
							{
								info:" split phase 120V/240V （Models above 3KW）",
								icon:'',
							}
						]
					},
					{
						title:'Operating frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							},{
								info:"Recreation vehicles",
								icon:require('../../public/images/cart.png'),
							},
							{
								info:"Heavy duty truck",
								icon:require('../../public/images/bigCart.png'),
							},
							{
								info: "Yacht",
								icon:require('../../public/images/ship.png'),
							},
							{
								info:"Engineering vehicle",
								icon:require('../../public/images/evCart.png'),
							}
						]
					},
					{
						title:'Range of power',
						list:[
							{
								info:"1.0KW",
								icon:'',
							},{
								info:"1.5KW",
								icon:'',
							},
							{
								info:"2.0KW",
								icon:'',
							},
							{
								info: "3.0KW",
								icon:'',
							},
							{
								info:"4.0KW",
								icon:'',
							},
							{
								info:"5.0KW",
								icon:'',
							},
							{
								info:"6.0KW",
								icon:'',
							}
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/shield.png'),
						text:'Multiple protection for safer use',
					},
					{
						icon:require('../../public/images/line.png'),
						text:'Real pure sine wave output to protect sensitive loads',
					},
					{
						icon:require('../../public/images/engine.png'),
						text:'Generator automatic start/stop function',
					},
					{
						icon:require('../../public/images/temperature.png'),
						text:'Battery temperature monitoring function',
					},
					{
						icon:require('../../public/images/charge.png'),
						text:'Adaptable to the charging voltage of different types of batteries',
					},
					{
						icon:require('../../public/images/technology.png'),
						text:'Four stage charging technology',
					},
					{
						icon:require('../../public/images/ac.png'),
						text:'AC voltage stabilization',
					}
					
				],
				pplicableIofo:[
					{
						title:'APS 1KW  1.5KW',
						list:[
							{
								pic:require('../../public/smallImg/led.png'),
								text:'LED'
							},
							{
								pic:require('../../public/smallImg/fridge.png'),
								text:'Fridge'
							},
							{
								pic:require('../../public/smallImg/tv.png'),
								text:'TV'
							},
							{
								pic:require('../../public/smallImg/computer.png'),
								text:'computer'
							},
							{
								pic:require('../../public/smallImg/loudspeaker.png'),
								text:'Loudspeaker'
							}
						]
					},
					{
						title:'APS 2-6KW',
						list:[
							{
								pic:require('../../public/smallImg/fridge.png'),
								text:'Fridge'
							},
							{
								pic:require('../../public/smallImg/tv.png'),
								text:'TV'
							},
							{
								pic:require('../../public/smallImg/loudspeaker.png'),
								text:'Loudspeaker'
							},
							{
								pic:require('../../public/smallImg/coffee.png'),
								text:'Coffee machine'
							},
							{
								pic:require('../../public/smallImg/air.png'),
								text:'Air conditioner '
							},
							{
								pic:require('../../public/smallImg/washing.png'),
								text:'Washing machine'
							},
							{
								pic:require('../../public/smallImg/microwave.png'),
								text:'Microwave Oven'
							},
							{
								pic:require('../../public/smallImg/heater.png'),
								text:'heater'
							}
						]
					},
				],
				appearanceList:[
					{
						pic:require('../../public/images/12V-APC1.png'),
						text:'24V-APS 1000W/1500W'
					},
					{
						pic:require('../../public/images/12V-APC2.png'),
						text:'24V-APS 1000W/1500W'
					},
					{
						pic:require('../../public/images/12V-APC3.png'),
						text:'24V-APS 2000W/3000W'
					},
					{
						pic:require('../../public/images/12V-APC4.png'),
						text:'24V-APS 2000W/3000W'
					},
					{
						pic:require('../../public/images/12V-APC5.png'),
						text:'24V-APS 4000W/5000W/6000W'
					},
					{
						pic:require('../../public/images/12V-APC6.png'),
						text:'24V-APS 4000W/5000W/6000W'
					}
				],
				priceInfo:[						//技术参数信息
					{
						name:'Inverter Output',
						list:[
							{
								parameterName:'Model',
								parameterList:['APS 1000W','APS 1500W','APS 2000W','APS 3000W','APS 4000W','APS 5000W','APS 6000W']
							},
							{
								parameterName:'AC Output Operating Voltage Options',
								parameterList:['100-110-120Vac / 220-230-240Vac']
							},
							{
								parameterName:'Continuous Output Power',
								parameterList:['1000W','1500W','2000W','3000W','4000W','5000W','6000W']
							},
							{
								parameterName:'Surge Rating (20s)',
								parameterList:['3000W','4500W','6000W','9000W','12000W','15000W','18000W']
							},
							{
								parameterName:'Output Frequency',
								parameterList:['50Hz ± 0.3Hz / 60Hz ± 0.3Hz']
							},
							{
								parameterName:'Nominal Efficiency',
								parameterList:['88% (Peak)']
							},
							{
								parameterName:'Line Mode Efficiency',
								parameterList:['>95%']
							},
							{
								parameterName:'Power Factor',
								parameterList:['0.9-1.0']
							},
							{
								parameterName:'Output Voltage Regulation',
								parameterList:['±10% RMS']
							},
							{
								parameterName:'Typical transfer Time',
								parameterList:['10ms ( Max )']
							},
							{
								parameterName:'THD',
								parameterList:['<10%']
							}
							
						]
					},
					{
						name:'DC Input',
						list:[
							{
								parameterName:'Nominal Input Voltage',
								parameterList:['24.0Vdc']
							},
							{
								parameterName:'Minimum Start Voltage',
								parameterList:['20.0Vdc']
							},
							{
								parameterName:'Low Battery Voltage Recover',
								parameterList:['26.0Vdc']
							},
							{
								parameterName:'Idle Consumption-Search Mode',
								parameterList:['< 25W when Power Saver On']
							},
						]
					},
					{
						name:'Charger',
						list:[
							{
								parameterName:'Output Voltage',
								parameterList:['Depends on battery type']
							},
							{
								parameterName:'Max Charge Current',
								parameterList:['25A','25A','35A','50A','65A','80A','90A']
							}
						]
					},
					{
						name:'AVR Function',
						list:[
							{
								parameterName:'Input AC Voltage Range',
								parameterList:['78-144V±4% /  150-276V±4%']
							},
							{
								parameterName:'Stabilized Output Voltage rms',
								parameterList:['120VAC ±10% RMS / 230VAC ±10% RMS']
							}
						]
					},
					{
						name:'Bypass & Protection',
						list:[
							{
								parameterName:'Max Input AC Voltage',
								parameterList:['150Vac For 120Vac LV Mode； 270Vac For 230Vac HV Mode']
							},
							{
								parameterName:'Nominal Input Frequency',
								parameterList:['50Hz or 60Hz ( Auto detect )']
							},
							{
								parameterName:'Over load protection (SMPS Ioad)',
								parameterList:['Circuit Breaker']
							},
							{
								parameterName:'Output Short Circuit Protection',
								parameterList:['Circuit Breaker']
							},
							{
								parameterName:'Bypass Breaker Rating',
								parameterList:['10A','15A','20A','30A','30A','40A','40A']
							},
							{
								parameterName:'Bypass Without Battery Connected',
								parameterList:['Yes']
							},
							{
								parameterName:'Max Bypass Current',
								parameterList:['30Amp','40Amp']
							}
						]
					},
					{
						name:'Mechanical Specifications',
						list:[
							{
								parameterName:'Mounting',
								parameterList:['Wall Mount']
							},
							{
								parameterName:"Dimensions (mm / inch)",
								parameterList:['416*222*190 / 16.4*8.7*7.5','476*222*190 / 18.7*8.7*7.5','625*222*190 / 24.6*8.7*7.5']
							},
							{
								parameterName:'Net Weight (kg)',
								parameterList:['14','16','20','24','35','45','45']
							},
							{
								parameterName:'Display',
								parameterList:['Status LEDs / Status LEDs+LCD']
							},
							
						]
					}
				],
				termList:[						//支持项数据
					{
						title:'Gel USA',
						fast:'14.0',
						float:'13.7'
					},
					{
						title:'AGM 1',
						fast:'14.1',
						float:'13.4'
					},
					{
						title:'Lithium',
						fast:'13.8',
						float:'13.6'
					},
					{
						title:'Sealed Lead Acid',
						fast:'14.4',
						float:'13.6'
					},
					{
						title:'Gel EURO',
						fast:'14.4',
						float:'13.8'
					},
					{
						title:'Open Lead Acid',
						fast:'14.0',
						float:'13.8'
					},
					{
						title:'LifePO4',
						fast:'15.5 (4 Hours then Off)',
						float:''
					},
					{
						title:'De-sulphation',
						fast:'13.6',
						float:'13.5'
					}
				],
				tobel_bom:'For 12Vdc Mode Series("2 for 24Vdc Mode/4 for 48Vdc Mode/8 for 96Vdc Mode)',
			}
			
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
