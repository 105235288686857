<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 表格 -->
	<yiy-table></yiy-table>
	<!-- 底部 -->
	<new-footer style='margin-top:1.43rem;'></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product2/product.vue'										//产品介绍组件
	import newFooter from '@/components/footer/footer.vue'											//网页底部
	import yiyTable from '@/components/table'														//表格 组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'SNMP Card',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			newFooter,
			yiyTable,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/SNMP/product.png'),
					title:'SNMP Card',
					info: `
						The SNMP card can communicate with the inverter, collect all 
						kinds of data from the inverter and transfer this information 
						to the computer so that the administrator can monitor the operation 
						of the equipment, perform analysis and statistics of the equipment's 
						operating data and understand the usage of the inverter.
					`
				},
				productInfo:[
					
					{
						title:'Compatible with',
						list:[
							{
								info:"HP Series",
								icon:'',
							},{
								info:'APP Series',
								icon:'',
							},{
								info:'APC Series',
								icon:'',
							},{
								info:'APS Series',
								icon:'',
							},{
								info:'TPP Series',
								icon:'',
							},{
								info:'PSW7 Series ',
								icon:'',
							},{
								info:'HPV Series ',
								icon:'',
							},{
								info:'APV Series',
								icon:'',
							}
						]
					}
				
				],
				
			}
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
