<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<diy-option></diy-option>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product2/product.vue'										//产品介绍组件
	import newFooter from '@/components/footer/footer.vue'											//网页底部
	import diyOption from '@/components/diyOption'													//侧边栏浮窗
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'APP-WIFI Module',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			newFooter,
			diyOption,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/APP-WIFI/product.png'),
					title:'APP-WIFI Module',
					info: `
						WiFi module connects the distribution network to the local network 
						(wireless hot spot) through mobile app. If the distribution network 
						connection is finished, WiFi module is automatically connected to the 
						Cloud server. App connects the system to the server by adding devices 
						(WiFi module MAC address). The system uploads the running data to the 
						server through WiFi module every 10 minutes. The mobile app receives 
						the data about the system on the server and show it on the interface.  
					`
				},
				productInfo:[
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							},{
								info:"Recreation vehicles",
								icon:require('../../public/images/cart.png'),
							},
							{
								info:"Heavy duty truck",
								icon:require('../../public/images/bigCart.png'),
							},
							{
								info: "Yacht",
								icon:require('../../public/images/ship.png'),
							},
							{
								info:"Engineering vehicle",
								icon:require('../../public/images/evCart.png'),
							}
						]
					},
					{
						title:'Compatible with',
						list:[
							{
								info:"HP Series ",
								icon:'',
							},{
								info:'HPV Series',
								icon:'',
							}
						]
					}
				
				],
				
			}
		},
		mounted() {
			// console.log(this.productIntroduce)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
