<template>
	<div class="footer">
		<div class="ball"></div>
		<div class="footer_top flex_box_cen">
			<div class="box flex_box">
				<div class="logo"></div>
				<ul class="list flex_box_bet">
					<li @click="go1('/InverterCharger')">
						<p> INVERTER CHARGER</p>
						<div class="line"></div>
					</li>
					<li @click="go1('/SolarInverter')">
						<p>SOLAR INVERTER</p>
						<div class="line"></div>
					</li>
					<li @click="go1('/HybirdInverter')">
						<p>HYBIRD INERTER</p>
						<div class="line"></div>
					</li>
					<li @click="go1('/PowerConversionSystem')">
						<p> POWER CONVERSION SYSTEM</p>
						<div class="line"></div>
					</li>
					<li @click="go1('/Others')">
						<p> OTHERS</p>
						<div class="line"></div>
					</li>
					
					<li @click="go1('/contact')">
						<p> CONTACTS</p>
						<div class="line"></div>
					</li>
				</ul>
			</div>
		</div>
		<div class="footer_btm flex_box_cen">
			<div class="box flex_box">
				<div class="btm_left ">
					<!-- <div class="text">Copyright © 2021 YIYEN ELECTRIC TECHNOLOGY CO..LTD  </div> -->
					<div class="iocns flex_box">
						<div class="iocn"></div>
						<div class="iocn"></div>
						<div class="iocn"></div>
					</div>
				</div>
				<ul class="list flex_box_wrap">
					<li>Group Website</li>
					<!-- 跳转逆变器 -->
					<li @click="go('www.yiypower.com')">Inverter Website</li>
					<!-- 跳转储能 -->
					<li @click="go('www.yiybess.com')">Energy Storage Website</li>
					<li>Voltage Regulator Website</li>
					<!-- 跳转电能 -->
					<li @click="go('www.yiyelec.com')">Power Quality Website</li>
				</ul>
			</div>
			
		</div>
	</div>
</template>

<script>
export default{
	data() {
		return{
			
		}
	},
	mounted() {
		
	},
	methods:{
		go(res){
			let url = res
			let path = window.location.protocol +  "//" + url
			window.location.href = path
		},
		go1(url){
			this.$router.push(url)
			document.documentElement.scrollTop = 0
		}
	},
	
}
</script>

<style lang="scss" scoped>
	.footer{
		width: 100%;
		height:5.58rem;
		background-color:#1F2034 ;
		position: relative;
		.ball{
			width: 3.58rem;
			height: 3.56rem;
			background: url('../../../public/images/ball.png') no-repeat center center;
			background-size:3.58rem 3.56rem;
			position: absolute;
			left: 5.74rem;
			top: 1rem;
		}
		.footer_top{
			width: 100%;
			height: 2.77rem;
			border-bottom:0.01rem solid #E6E6E6 ;
			
			.box{
				width: 15.37rem;
				// background-color: blue;
				.logo{
					width: 2.5rem;
					height: 0.48rem;
					margin-top: 1.93rem;
					background: url('../../../public/images/logo2.png') no-repeat center center;
					background-size:2.5rem 0.48rem;
				}
			}
			.list{
				width: 10.82rem;
				color: #fff;
				list-style-type: none;
				margin-top: 2.17rem;
				margin-left: 2.1rem;
				z-index: 11111;
				li{
					height: 0.6rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					font-size: 0.18rem;
					font-family: Arial;
					font-weight: 400;
					color: #FFFFFF;
					p{
						// padding-right: 0.24rem;
					}
					.line{
						width: 0px;
						height: 0.04rem;
						transition: width 0.3s;
						background: #E6E6E6;
					}
				}
				li:hover{
					.line{
						width: 100%;
					}
				}
			}
			.list:hover{
				cursor: pointer;
			}
		}
		.footer_btm{
			width: 100%;
			.box{
				width: 15.37rem;
				.btm_left{
					margin-top: 0.64rem;
					font-size: 0.16rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 0.24rem;
					.iocns{
						margin-top: 0.36rem;
						.iocn{
							width: 0.49rem;
							height: 0.49rem;
							border: 0.01rem solid #E6E6E6;
							border-radius: 50%;
							margin-right: 0.2rem;
							background: url('../../../public/smallImg/fb2.png') no-repeat center center;
							background-size: 0.11rem 0.23rem;
						}
						.iocn:nth-child(2){
							background: url('../../../public/smallImg/tw2.png') no-repeat center center;
							background-size: 0.23rem 0.19rem;
						}
						.iocn:nth-child(3){
							background: url('../../../public/smallImg/in2.png') no-repeat center center;
							background-size: 0.22rem 0.22rem;
						}
					}
					.text{
						width: 2.7rem;
					}
				}
				.list{
					width: 6rem;
					margin-left: 5.62rem;
					margin-top: 0.7rem;
					li{
						width:3rem;
						font-size: 0.18rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 0.48rem;
					}
					li:hover{
						cursor: pointer;
					}
				}
			}
			
		}
	}
	
	// 移动端样式
	@media (max-width:1024px){
		.footer{
			width: 100%;
			height:7.52rem;
			background-color:#1F2034 ;
			position: relative;
			
			.ball{
				width: 2.98rem;
				height: 3.56rem;
				background: url('../../../public/images/ball.png') no-repeat center center;
				background-size:3.58rem 3.56rem;
				position: absolute;
				left:4.52rem;
				top: 1rem;
			}
			.footer_top{
				width: 100%;
				height: 2.98rem;
				border-bottom:none;
				
				.box{
					width: 5.8rem;
					flex-direction: column;
					border-bottom:0.01rem solid #E6E6E6 ;
					// background-color: blue;
					.logo{
						width: 2.5rem;
						height: 0.48rem;
						margin-top: 0.5rem;
						background:url('../../../public/images/logo2.png') no-repeat center center;
						background-size:2.5rem 0.48rem;
						
					}
					.list{
						width: 5.8rem;
						height: 1.49rem;
						color: #fff;
						list-style-type: none;
						margin-top: 0.51rem;
						margin-left: 0rem;
						display: flex;
						flex-wrap: wrap;
						z-index: 11111;
						li{
							min-width: 2rem;
							height:0.43rem;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							font-size: 0.18rem;
							font-family: Arial;
							font-weight: 400;
							color: #FFFFFF;
							p{
								// padding-right: 0.24rem;
							}
							.line{
								width: 0px;
								height: 0.04rem;
								transition: width 0.3s;
								background: #E6E6E6;
							}
						}
						li:hover{
							.line{
								width: 100%;
							}
						}
					}
				}
				// .list{
				// 	width: 9.82rem;
				// 	color: #fff;
				// 	list-style-type: none;
				// 	margin-top: 2.17rem;
				// 	margin-left: 3rem;
				// 	li{
				// 		height: 0.6rem;
				// 		display: flex;
				// 		flex-direction: column;
				// 		justify-content: space-between;
				// 		font-size: 0.18rem;
				// 		font-family: Arial;
				// 		font-weight: 400;
				// 		color: #FFFFFF;
				// 		p{
				// 			// padding-right: 0.24rem;
				// 		}
				// 		.line{
				// 			width: 0px;
				// 			height: 0.04rem;
				// 			transition: width 0.3s;
				// 			background: #E6E6E6;
				// 		}
				// 	}
				// 	li:hover{
				// 		.line{
				// 			width: 100%;
				// 		}
				// 	}
				// }
			}
			.footer_btm{
				width: 100%;
				.box{
					width: 5.8rem;
					
					// flex-direction: column;
					flex-direction:column-reverse;
					.btm_left{
						margin-top: 0rem;
						font-size: 0.16rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 0.24rem;
						display: flex;
						flex-direction:column-reverse;
						.iocns{
							margin-top: 0.47rem;
							width: 5.8rem;
							border-bottom:0.01rem solid #E6E6E6 ;
							padding-bottom: 0.5rem;
							.iocn{
								width: 0.49rem;
								height: 0.49rem;
								border: 0.01rem solid #E6E6E6;
								border-radius: 50%;
								margin-right: 0.2rem;
								background: url('../../../public/smallImg/fb2.png') no-repeat center center;
								background-size: 0.11rem 0.23rem;
							}
							.iocn:nth-child(2){
								background: url('../../../public/smallImg/tw2.png') no-repeat center center;
								background-size: 0.23rem 0.19rem;
							}
							.iocn:nth-child(3){
								background: url('../../../public/smallImg/in2.png') no-repeat center center;
								background-size: 0.22rem 0.22rem;
							}
						}
						.text{
							width: 5.8rem;
							margin-top: 0.49rem;
						}
					}
					.list{
						width: 5.8rem;
						margin-left: 0rem;
						margin-top: 0.5rem;
						z-index: 11111;
						li{
							
							font-size: 0.18rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 0.48rem;
						}
						
						
					}
					
				}
				
			}
		}
	}
</style>