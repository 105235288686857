<template>
	<div class='inverter_charger flex_box_column_cen'>
		<!-- 头部导航 -->
		<yiy-header></yiy-header>
		<!-- banner图 -->
		<div class="banner">
			<div class="text_box">
				<div class="title">PRODUCT CENTER</div>
				<div class="text">
					Inverter Charger</br>
					Solar Inverter</br>
					Hybird Inverter</br>
					Power Conversion System</br>
					Others</br>
					Contacts
				</div>
			</div>
		</div>
		<!-- 产品 -->
		<product-center :productList='productList'></product-center>
		<!-- 侧边栏 -->
		<right-frame></right-frame>
		<!-- 网页底部 -->
		<new-footer></new-footer>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import 'swiper/dist/css/swiper.min.css'
	import WOW from 'wowjs/dist/wow'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import newFooter from '@/components/footer/footer.vue'											//头部组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	import productCenter from '@/components/productCenter/productCenter.vue'						//产品中心
export default {
	metaInfo:{
		title:'PRODUCT CENTER',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
	components:{
		yiyHeader,
		newFooter,
		rightFrame,
		productCenter
	},
	data() {
		return{
			productList:[
				{
					id:1,
					title:'12VDC Inverter Charger',
					list:[
						{
							pic:require('../../public/productImg/12v_hp.png'),
							url:'/12V-HP',
							name:'12V-HP Series Low Frequency Pure Sine Wave Inverter/Charger'
						},
						{
							pic:require('../../public/productImg/12v_apc_mini.png'),
							url:'/12V-APCMini',
							name:'12V-APC mini Series Low Frequency Pure Sine Wave Inverter/Charger'
						},
						{
							pic:require('../../public/productImg/12v_apc.png'),
							url:'/12V-AP',
							name:'12V-AP Series Low Frequency Pure Sine Wave Inverter/Charger'
						},
						{
							pic:require('../../public/productImg/ops.png'),
							url:'/12V-OPS',
							name:'12V-OPS Series High Frequency Pure Sine Wave Inverter'
						}
						
					]
				},
				{
					id:1,
					title:'24VDC Inverter Charger',
					list:[
						{
							pic:require('../../public/productImg/24v_hp.png'),
							url:'/24V-HP',
							name:'24V-HP Series Low Frequency Pure Sine Wave Inverter/Charger'
						},
						{
							pic:require('../../public/productImg/12v_apc_mini.png'),
							url:'/24V-APCMini',
							name:'24V-APC mini Series Low Frequency Pure Sine Wave Inverter/Charger'
						},
						{
							pic:require('../../public/productImg/12v_apc.png'),
							url:'/24V-AP',
							name:'24V-AP Series Low Frequency Pure Sine Wave Inverter/Charger'
						},
						{
							pic:require('../../public/productImg/ops.png'),
							url:'/24V-OPS',
							name:'24V-OPS Series High Frequency Pure Sine Wave Inverter'
						}
						
					]
				},
				{
					id:1,
					title:'48VDC  Inverter Charger',
					list:[
						{
							pic:require('../../public/productImg/48v_hp.png'),
							url:'/48V-HP',
							name:'48V-HP Series Low Frequency Pure Sine '
						},
						{
							pic:require('../../public/productImg/12v_apc.png'),
							url:'/48V-AP',
							name:'48V-AP Series Low Frequency Pure Sine Wave Inverter/Charger'
						},
						{
							pic:require('../../public/productImg/48v_tpp.png'),
							url:'/48V-TPP',
							name:'48V-TPP Series Low Frequency Three-phase Pure Sine Wave Inverter/Charger'
						}
					]
				},
				{
					id:1,
					title:'96VDC Inverter Charger',
					list:[
						{
							pic:require('../../public/productImg/96v_hp.png'),
							url:'/96V-HP',
							name:'96V-HP Series Low Frequency Pure Sine Wave Inverter/Charger'
						}
					]
				},
			]
		}
	}
}
</script>

<style lang="scss" scoped>
	.inverter_charger{
		width:100%;
		.banner{
			width: 100%;
			height: 8.6rem;
			background:url('../../public/images/banner6.png')no-repeat center center;
			background-size:100% 8.6rem;
			.text_box{
				margin-top: 3.01rem;
				margin-left: 1.99rem;
				.title{
					font-size: 0.64rem;
					font-family: Arial;
					font-weight: bold;
					color: #FFFFFF;
					margin-bottom: 0.65rem;
				}
				.text{
					width: 2.32rem;
					height: 1.37rem;
					font-size: 0.18rem;
					font-family: Arial;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 0.3rem;
				}
			}
		}
	}
	// 移动端 样式
	@media (max-width:1024px){
		.inverter_charger{
			width:100%;
			.banner{
				width: 100%;
				height: 10.85rem;
				background:url('../../public/images/banner7.png')no-repeat center center;
				background-size:100% 10.85rem;
				.text_box{
					margin-top: 2.33rem;
					margin-left: 0.82rem;
					.title{
						font-size: 0.64rem;
						font-family: Arial;
						font-weight: bold;
						color: #FFFFFF;
						margin-bottom: 0.65rem;
					}
					.text{
						width: 1.32rem;
						height: 1.37rem;
						font-size: 0.18rem;
						font-family: Arial;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 0.3rem;
					}
				}
			}
		}
	}
</style>