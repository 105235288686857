<template>
  <div class="productInfo">
	  <!-- 头部 -->
	<yiy-header></yiy-header>
	<!-- 产品介绍 -->
	<yiy-product :productIntroduce='productIntroduce' :productInfo='productInfo'></yiy-product >
	<!-- 产品特点 -->
	<product-features :pplicableIofo='pplicableIofo'  :pplicableIcons='pplicableIcons'></product-features>
	<!-- 产品外观 -->
	<product-appearance :appearanceList='appearanceList'></product-appearance>
	<!-- 技术参数 -->
	<technical-parameters :priceInfo='priceInfo'></technical-parameters>
	<!-- 支持项 -->
	<support-term :termList='termList'></support-term>
	<!-- 其他产品 -->
	<other-products :otherList='otherList'></other-products>
	<!-- 底部 -->
	<new-footer></new-footer>
	<right-frame></right-frame>
  </div>
</template>

<script>
	import Swiper from 'swiper'
	import yiyHeader from '@/components/header/header.vue'											//头部组件
	import yiyProduct from '@/components/product/product.vue'										//产品介绍组件
	import productFeatures from '@/components/productFeatures/productFeatures.vue'					//产品特点组件
	import productAppearance from '@/components/productAppearance/productAppearance.vue'			//产品外观组件 
	import technicalParameters from '@/components/technicalParameters/technicalParameters.vue'		//技术参数组件
	import supportTerm from '@/components/supportTerm/supportTerm.vue'								//支持项组件
	import otherProducts from '@/components/otherProducts/otherProducts.vue'						//其他产品组件
	import newFooter from '@/components/footer/footer.vue'											//其他产品组件
	import rightFrame from '@/components/rightFrame/rightFrame.vue'									//侧边栏浮窗
	
	import 'swiper/dist/css/swiper.min.css'
	export default {
	metaInfo:{
		title:'60A-MPPT',
		meta:[{
			name:'keyWords',
			content:'关键词1,关键词2'
		}]
	},
		components:{
			yiyHeader,
			yiyProduct,
			productFeatures,
			productAppearance,
			technicalParameters,
			supportTerm,
			otherProducts,
			newFooter,
			rightFrame
		},
		data() {
			return{
				productIntroduce:{
					pic:require('../../public/images/40A-MPPT.png'),
					title:'60A-MPPT Solar Charge & Discharge Controller',
					info:'The maximum power point tracking technology increases the efficiency by 25%~30%. The overall efficiency can reach 98%. Optimize battery performance by three stage charging. The maximum charging current can reach 60A. Battery temperature sensor (BTS) automatically provides temperature compensation, supports multiple batteries, and automatically detects battery voltage.',
					// id:'3'				//换背景图必传
				},
				productInfo:[
					{
						title:'Operating voltage',
						list:[
							{
								info:"single phase 100-110-120V/ 220-230-240V",
								icon:'',
							},
							{
								info:"Split phase 120V/230V（Models above 3KW）",
								icon:'',
							}
						]
					},
					{
						title:'Operating frequency',
						list:[
							{
								info:" 50Hz  ",
								icon:'',
							},{
								info:' 60Hz',
								icon:'',
							}
						]
					},
					{
						title:'Application scenario',
						list:[
							{
								info:"Household",
								icon:require('../../public/images/electrical.png'),
							},{
								info:"Recreation vehicles",
								icon:require('../../public/images/cart.png'),
							},
							{
								info:"Heavy duty truck",
								icon:require('../../public/images/bigCart.png'),
							},
							{
								info: "Yacht",
								icon:require('../../public/images/ship.png'),
							},
							{
								info:"Engineering vehicle",
								icon:require('../../public/images/evCart.png'),
							}
						]
					},
					{
						title:'Range of power',
						list:[
							{
								info:"1.0KW",
								icon:'',
							},{
								info:"1.5KW",
								icon:'',
							},
							{
								info:"2.0KW",
								icon:'',
							},
							{
								info: "3.0KW",
								icon:'',
							},
							{
								info:"4.0KW",
								icon:'',
							},
							{
								info:"5.0KW",
								icon:'',
							},
							{
								info:"6.0KW",
								icon:'',
							}
						]
					},
				],
				pplicableIcons:[
					{
						icon:require('../../public/images/shield.png'),
						text:'Multiple protection for safer use',
					},
					{
						icon:require('../../public/images/line.png'),
						text:'Real pure sine wave output to protect sensitive loads',
					},
					{
						icon:require('../../public/images/engine.png'),
						text:'Generator automatic start/stop function',
					},
					{
						icon:require('../../public/images/temperature.png'),
						text:'Battery temperature monitoring function',
					},
					{
						icon:require('../../public/images/charge.png'),
						text:'Adaptable to the charging voltage of different types of batteries',
					}
					
				],
				pplicableIofo:[
					{
						title:'APV 1KW  1.5KW',
						list:[
							{
								pic:require('../../public/smallImg/led.png'),
								text:'LED'
							},
							{
								pic:require('../../public/smallImg/fridge.png'),
								text:'Fridge'
							},
							{
								pic:require('../../public/smallImg/tv.png'),
								text:'TV'
							},
							{
								pic:require('../../public/smallImg/computer.png'),
								text:'computer'
							},
							{
								pic:require('../../public/smallImg/loudspeaker.png'),
								text:'Loudspeaker'
							}
						]
					},
					{
						title:'APV 2-6KW',
						list:[
							{
								pic:require('../../public/smallImg/fridge.png'),
								text:'Fridge'
							},
							{
								pic:require('../../public/smallImg/tv.png'),
								text:'TV'
							},
							{
								pic:require('../../public/smallImg/loudspeaker.png'),
								text:'Loudspeaker'
							},
							{
								pic:require('../../public/smallImg/coffee.png'),
								text:'Coffee machine'
							},
							{
								pic:require('../../public/smallImg/air.png'),
								text:'Air conditioner '
							},
							{
								pic:require('../../public/smallImg/washing.png'),
								text:'Washing machine'
							},
							{
								pic:require('../../public/smallImg/microwave.png'),
								text:'Microwave Oven'
							},
							{
								pic:require('../../public/smallImg/heater.png'),
								text:'heater'
							}
						]
					},
				],
				appearanceList:[
					{
						pic:require('../../public/images/mppt1.png'),
						text:'60A-MPPT'
					},
					{
						pic:require('../../public/images/mppt2.png'),
						text:'60A-MPPT'
					},
				],
				priceInfo:[						//技术参数信息
					{
						name:'Inverter Output',
						list:[
							{
								parameterName:'Model',
								parameterList:['3KW']
							},
							{
								parameterName:'Nominal System Voltage',
								parameterList:['12,24, or 48 VDC(Auto detection)']
							},
							{
								parameterName:'Maximum Battery Current',
								parameterList:['60 Amps']
							},
							{
								parameterName:'Maximum Solar Input Voltage',
								parameterList:['145Vdc']
							},
							{
								parameterName:'PV Array MPPT Voltage Range',
								parameterList:['(Bat.Voltage+5)~115Vdc']
							},
							{
								parameterName:'Maximum Input Power',
								parameterList:['12 Volt--800 Watts','24 Volt--1600 Watts','48 Volt--3200 Watts']
							},
							{
								parameterName:'Transient Surge Protection',
								parameterList:['4500 Watts / port']
							},
							{
								parameterName:'Temperature compensation coefficient',
								parameterList:['Volt-5 mV/°C/ cell(25°℃ ref.)']
							},
							{
								parameterName:'Temperature compensation',
								parameterList:['0℃ to+50℃  ']
							},
							{
								parameterName:'Charging stages  ',
								parameterList:['Bulk,Absorption,Float ']
							},
							{
								parameterName:'Charging Set points',
								parameterList:['Absorption Stage','Float Stage']
							},
							{
								parameterName:'Flooded Battery ',
								parameterList:['14.6/29.2/58.4Vdc','13.5/27/54Vdc']
							},
							{
								parameterName:'AGM/Gel Battery(Default) ',
								parameterList:['14.1/28.2/56.4Vdc','13.5/27/54Vdc']
							},
							{
								parameterName:'Over-charging voltage',
								parameterList:['15Vdc/30Vdc/60Vdc']
							},
							{
								parameterName:'Overcharging comeback voltage',
								parameterList:['14.5Vdc/ 29Vdc/ 58Vdc']
							},
							{
								parameterName:'Battery defect voltage',
								parameterList:['8.5Vdc/17Vdc/34Vdc']
							},
							{
								parameterName:'Battery defect comeback voltage',
								parameterList:['9Vdc/ 18Vdc/36Vdc']
							},
						]
					},
					{
						name:'Mechanical Specifications',
						list:[
							{
								parameterName:'Product size(W*H*D mm)',
								parameterList:['322*173*118']
							},
							{
								parameterName:"Product weight (Kg)",
								parameterList:['4.8']
							},
							{
								parameterName:'Enclosure',
								parameterList:['IP31 (indoor & vented)']
							},
						]
					}
				],
				termList:[						//支持项数据
					{
						title:'Gel USA',
						fast:'14.0',
						float:'13.7'
					},
					{
						title:'AGM 1',
						fast:'14.1',
						float:'13.4'
					},
					{
						title:'Lithium',
						fast:'13.8',
						float:'13.6'
					},
					{
						title:'Sealed Lead Acid',
						fast:'14.4',
						float:'13.6'
					},
					{
						title:'Gel EURO',
						fast:'14.4',
						float:'13.8'
					},
					{
						title:'Open Lead Acid',
						fast:'14.0',
						float:'13.8'
					},
					{
						title:'LifePO4',
						fast:'15.5 (4 Hours then Off)',
						float:''
					},
					{
						title:'De-sulphation',
						fast:'13.6',
						float:'13.5'
					}
				],
				otherList:[
					{
						img:require('../../public/otherImg/40A-MPPT.png'),
						url:'/40A-MPPT'
					},
				]
			}
		},
		mounted() {
			// console.log(this.pplicableIcons)
		}
	}
</script>
<style lang="scss" scoped>
	
</style>
