<template>
	<div class="leftFrame">
		<div class="wrap">
			<div class="box" @click="apparent = !apparent"></div>
			<div class="box" @click="go('/screen-page')"></div>
			<div class="box" @click="top">TOP</div>
		</div>
		<div class="bullet_box animated " :class="apparent?'fadeInRight':'adeOutRight'" v-show="apparent">
			<div class="right " >
				<div class="title  ">Send Us A Message</div>
				<div class="form">
					<div class="name_phone ">
						<div class="input_box">
							<input type="text" placeholder="Name" v-model="name">
							<div id='name3' class='animated ' :class="state?'fadeInDown':''"></div>
						</div>
						<div class="input_box">
							<input type="text" placeholder="Phone" v-model="phone">
							<div id='mobile3' class='animated ' :class="state?'fadeInDown':''"></div>
						</div>
					</div>
					<div class="name_phone ">
						<div class="input_box">
							<input type="text" placeholder="Products" v-model="products">
							<div id='product3' class='animated ' :class="state?'fadeInDown':''"></div>
						</div>
						<div class="input_box">
							<input type="text" placeholder="E-mail" v-model="email">
							<div id='email1' class='animated ' :class="state?'fadeInDown':''"></div>
						</div>
					</div>
					<input class="ipt" type="text" placeholder="Address" v-model="address">
					<div class="input_box">
						<textarea placeholder="Message" v-model="message"></textarea>
						<div id='message3' class='animated' :class="state?'fadeInDown':''"></div>
					</div>
				</div>
				<div class="btn" @click="add()" v-show="!change">
					<span>Submit</span>
				</div>
				<div class="btn1" v-show="change">
					<span>Success</span>
				</div>
			</div>
			<div class="del" @click="apparent=false">X</div>
		</div>
	</div>
</template>

<script>
	// import WOW from 'wowjs/dist/wow'
	import axios from 'axios';
	export default{
		data() {
			return{
				change:false,
				state:false,
				apparent:false,
				name:'',						//名字
				phone:'',						//电话
				products:'',					//产品
				email:'',						//邮件
				address:'',						//地址
				message:'',						//详情
				website:'',						//当前页面
				ip:'',							// 获取ip
				country:'',
			}
		},
		created() {
		
			// 获取ip
			fetch('https://api.ipify.org?format=json')
			  .then(response => response.json())
			  .then(data => {
				this.ip = data.ip
			    // console.log(data.ip);
			  });
		},
		mounted() {
			
			// this.$nextTick(()=>{
			// 	const wow = new WOW.WOW({
			// 		boxClass:'wow',
			// 		animateClass:'animated',
			// 		offset:0,
			// 		mobile:true,
			// 		live :false
			// 	})
			// 	wow.init()
			// })
		},
		methods:{
			add(){
				this.change = true
				let ok = true
				// name
				if (this.name == '') {
					document.getElementById('name3').innerText = "*required";
					this.state = true;
					ok = false
				} else {
					
					setTimeout(() => {
						document.getElementById('name3').innerText = "";
					}, 300)
				}
				// phone
				if (this.phone == '') {
					document.getElementById('mobile3').innerText = "*required";
					this.state = true;
					ok = false
				} else {
					
					setTimeout(() => {
						document.getElementById('mobile3').innerText = "";
					}, 300)
				}
				// product
				if (this.products == '') {
					document.getElementById('product3').innerText = "*required";
					this.state = true;
					ok = false
				} else {
					
					setTimeout(() => {
						document.getElementById('product3').innerText = "";
					}, 300)
				}
				// email
				let reg_mail =
					/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
				if (this.email == '') {
					this.state = true;
					document.getElementById('email1').innerText = "*required";
					ok = false
				} else if (!reg_mail.test(this.email)) {
					this.state = true;
					ok = false
					// 检查格式
					document.getElementById('email1').innerText = "*E-mail format is incorrect";
					console.log('请输入正确的邮箱')
				} else {
					console.log('输入正确')
					
					setTimeout(() => {
						document.getElementById('email1').innerText = "";
					}, 300)
				}
				// message
				if (this.message == '') {
					document.getElementById('message3').innerText = "*required";
					this.state = true;
					ok = false
				} else {
					setTimeout(() => {
						document.getElementById('message3').innerText = "";
					}, 300)
				}
				
				if (ok == true) {
					
					this.setData()
					// console.log(1111111111)
				}else{
					this.change = false
				}
			
			},
			setData() {
				this.website = window.location.href
				let formData = new FormData();
				formData.append("name", this.name);
				formData.append("product", this.products);
				formData.append("email", this.email);
				formData.append("mobile", this.phone);
				formData.append("address", this.address);
				formData.append("message", this.message);
				formData.append("website", this.website);
				formData.append("ip", this.ip);
				formData.append("country", this.country);
			
				// that.sendState = false;
				
				this.submitMail(formData);
			},
			submitMail(formData){
				console.log()
				axios({
					url: 'https://yiyapi.sgrcr.com/EMAILQuery',
					method: 'POST',
					data: formData,
				}).then((res) => {
					console.log(res)
					this.name = '';
					this.products = '';
					this.email = '';
					this.phone = '';
					this.address = '';
					this.message = '';
					this.website = '';
					this.ip = '';
					this.country = '';
					this.change = false
				})
			},
			top(){
				document.documentElement.scrollTop = 0
				console.log( )
				
			},
			go(url){
				this.$router.push(url)
				document.documentElement.scrollTop = 0
			}
		}
	}
</script>

<style lang="scss" scoped>
	.leftFrame{
		position: fixed;
		right: 0;
		top: 4.04rem;
		z-index: 2222222;

		.wrap{
			width:0.91rem;
			height: 2.29rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.box{
				width:0.91rem;
				height: 0.75rem;
				// background-color: #ECEEFA;
				background:#ECEEFA url('../../../public/smallImg/lx.png')no-repeat center center;
				background-size:0.3rem 0.34rem ;
			}
			.box:nth-child(2){
				background:#ECEEFA url('../../../public/smallImg/sx.png')no-repeat center center;
				background-size:0.31rem 0.28rem ;
			}
			.box:last-child{
				background-color: #2E3044;
				font-size: 0.16rem;
				color: #fff;
				line-height: 0.75rem;
				text-align: center;
			}
			.box:hover{
				 cursor: pointer;
			}
		}
		
		.bullet_box{
			width: 6rem;
			height: 8rem;
			background-color: #fff;
			position: absolute;
			left: -5rem;
			top: -3.5rem;
			box-shadow: 0px 0.01rem 0.1rem 0px rgba(0,0,0,0.08);
			
			.right{
				width: 8.64rem;
				// height: 1.35rem;
				.title{
					font-size: 0.36rem;
					font-family: Arial;
					font-weight: bold;
					color: #333333;
					margin-left: 0.36rem;
					margin-top: 0.54rem;
				}
				.form{
					width:100%;
					margin-top: 0.54rem;
					.name_phone{
						width: 100%;
						margin-bottom: 0.24rem;
						display: flex;
						
						
						.input_box{
							position: relative;
							
							input{
								width: 2.27rem;
								height: 0.56rem;
								background: #F8F8F8;
								border: 0.01rem solid #CCCCCC;
								padding-left: 0.16rem;
								font-size: 0.18rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								background-color: #F8F8F8;
								margin-left: 0.36rem;
							}
							#email1{
								position: absolute;
								left: 0.49rem;
								bottom: -0.19rem;
								color: #910000;
								font-size: 0.16rem;
								text-align: left;
							}
							#mobile3{
								position: absolute;
								left: 0.49rem;
								bottom: -0.19rem;
								color: #910000;
								font-size: 0.16rem;
								text-align: left;
							}
							#product3{
								position: absolute;
								left: 0.49rem;
								bottom: -0.19rem;
								color: #910000;
								font-size: 0.16rem;
								text-align: left;
							}
							#name3{
								position: absolute;
								left: 0.49rem;
								bottom: -0.19rem;
								color: #910000;
								font-size: 0.16rem;
								text-align: left;
							}
							
						}
						
					}
					.ipt{
						width:5.09rem;
						height: 0.56rem;
						background: #F8F8F8;
						border: 0.01rem solid #CCCCCC;
						padding-left: 0.16rem;
						font-size: 0.18rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						background-color: #F8F8F8;
						margin-left: 0.36rem;
					}
					input::-webkit-input-placeholder{
						color: #CCCCCC;
					}
					input::-moz-placeholder{
						color: #CCCCCC;
					}
					input:-moz-placeholder{
						color: #CCCCCC;
					}
					input:-ms-input-placeholder{
						color: #CCCCCC;
					}
					.input_box{
						position: relative;
						textarea{
							resize:none;
							width:5.09rem;
							margin-left: 0.36rem;
							height: 1.12rem;
							background: #F8F8F8;
							border: 0.01rem solid #CCCCCC;
							margin-top:0.24rem ;
							padding-left: 0.16rem;
							padding-top:0.23rem ;
							font-size: 0.18rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000;
						}
						#message3{
							position: absolute;
							left: 0.49rem;
							bottom: -0.19rem;
							color: #910000;
							font-size: 0.16rem;
							text-align: left;
						}
					}
					
					textarea::-webkit-input-placeholder{
						color: #CCCCCC;
					}
					textarea::-moz-placeholder{
						color: #CCCCCC;
					}
					textarea:-moz-placeholder{
						color: #CCCCCC;
					}
					textarea:-ms-input-placeholder{
						color: #CCCCCC;
					}
					
				}
				.btn{
					width: 1.32rem;
					height: 0.56rem;
					margin-top: 0.51rem;
					background:#2E3044 url('../../../public/smallImg/letter.png') no-repeat 1.22rem center;
					font-size: 0.2rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 0.56rem;
					padding-left: 0.35rem;
					margin-left: 0.36rem;
				}
				.btn:hover{
					 cursor:pointer;
				}
				.btn1{
					width: 1.32rem;
					height: 0.56rem;
					margin-top: 0.51rem;
					background:#2E3044 url('../../../public/smallImg/ass.gif') no-repeat 1.22rem center;
					background-size: 0.5rem  0.5rem;
					font-size: 0.2rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 0.56rem;
					padding-left: 0.35rem;
					margin-left: 0.36rem;
				}
			}
			.del{
				width: 0.5rem;
				height: 0.5rem;
				background: #950101;
				border-radius: 0.02px;
				line-height: 0.5rem;
				text-align: center;
				font-size: 0.4rem;
				color: #fff;
				position: absolute;
				top: 0;
				left: -0.5rem;
			}
			.del:hover{
				cursor: pointer;
			}
		}
	}
	
</style>